import _ from 'lodash';
import { createSelector } from 'reselect';

import Apps         from 'app/apps';
import mainApi      from 'app/apis/main';
import {
  Pages,
  DeviceTypes,
}                   from 'app/constants';
import AuthDuck     from 'app/ducks/auth';
import utils        from 'app/helpers/utils';
import history      from 'app/history';
import paths        from 'app/paths';
import reducerUtils from 'app/reducers/utils';
import EntitiesSlx  from 'app/selectors/entities';


/*
 *  Actions
 */

const Types = {
  SET_DISPLAY_NAME: 'ACCOUNT_SET_DISPLAY_NAME',
  CLEAR_SET_DISPLAY_NAME: 'ACCOUNT_CLEAR_SET_DISPLAY_NAME',
  SET_PREFS: 'ACCOUNT_SET_PREFS',
  CLEAR_SET_PREFS: 'ACCOUNT_CLEAR_SET_PREFS',
};

const Ax = {

  setDisplayName: (displayName) => (dispatch, getState) => {
    const promise = mainApi.usersSetDisplayName(displayName).then(({user}) => {
      dispatch(AuthDuck.Ax.setCurrentUser(user));
    });
    return dispatch({type: Types.SET_DISPLAY_NAME, promise});
  },

  clearSetDisplayName: () => {
    return {type: Types.CLEAR_SET_DISPLAY_NAME};
  },

  setPrefs: (prefs={}) => (dispatch, getState) => {
    const promise = mainApi.usersSetPrefs(prefs).then(({user}) => {
      dispatch(AuthDuck.Ax.setCurrentUser(user));
    });
    return dispatch({type: Types.SET_PREFS, promise});
  },

  clearSetPrefs: () => {
    return {type: Types.CLEAR_SET_PREFS};
  },

};



/*
 *  Reducer
 */

const initialState = {
  setDisplayNamePending: false,
  setDisplayNameFailed: false,
  setDisplayNameSuccess: false,
  setPrefsPending: false,
  setPrefsFailed: false,
  setPrefsSuccess: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.SET_DISPLAY_NAME}_PENDING`]: (state, _action) => {
    return {...state,
      setDisplayNamePending: true,
      setDisplayNameFailed: false,
      setDisplayNameSuccess: false,
    };
  },
  [`${Types.SET_DISPLAY_NAME}_RESOLVED`]: (state, action) => {
    return {...state,
      setDisplayNamePending: false,
      setDisplayNameFailed: false,
      setDisplayNameSuccess: true,
    };
  },
  [`${Types.SET_DISPLAY_NAME}_REJECTED`]: (state, {error}) => {
    return {...state,
      setDisplayNamePending: false,
      setDisplayNameFailed: true,
      setDisplayNameSuccess: false,
    };
  },
  [Types.CLEAR_SET_DISPLAY_NAME]: (state, action) => {
    return {...state,
      setDisplayNamePending: false,
      setDisplayNameFailed: false,
      setDisplayNameSuccess: false,
    };
  },

  [`${Types.SET_PREFS}_PENDING`]: (state, _action) => {
    return {...state,
      setPrefsPending: true,
      setPrefsFailed: false,
      setPrefsSuccess: false,
    };
  },
  [`${Types.SET_PREFS}_RESOLVED`]: (state, action) => {
    return {...state,
      setPrefsPending: false,
      setPrefsFailed: false,
      setPrefsSuccess: true,
    };
  },
  [`${Types.SET_PREFS}_REJECTED`]: (state, {error}) => {
    return {...state,
      setPrefsPending: false,
      setPrefsFailed: true,
      setPrefsSuccess: false,
    };
  },
  [Types.CLEAR_SET_PREFS]: (state, action) => {
    return {...state,
      setPrefsPending: false,
      setPrefsFailed: false,
      setPrefsSuccess: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selSetDisplayNamePending = state => state.account.setDisplayNamePending;
  const selSetDisplayNameFailed  = state => state.account.setDisplayNameFailed;
  const selSetDisplayNameSuccess = state => state.account.setDisplayNameSuccess;
  const selSetPrefsPending       = state => state.account.setPrefsPending;
  const selSetPrefsFailed        = state => state.account.setPrefsFailed;
  const selSetPrefsSuccess       = state => state.account.setPrefsSuccess;

  return {
    setDisplayNamePending: selSetDisplayNamePending,
    setDisplayNameFailed: selSetDisplayNameFailed,
    setDisplayNameSuccess: selSetDisplayNameSuccess,
    setPrefsPending: selSetPrefsPending,
    setPrefsFailed: selSetPrefsFailed,
    setPrefsSuccess: selSetPrefsSuccess,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
