import React from 'react';
import { connect } from 'react-redux';

import Link     from 'app/components/common/link';
import AuthDuck from 'app/ducks/auth';
import paths    from 'app/paths';

class Session extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickLogout = this.onClickLogout.bind(this);
  }

  onClickLogout() {
    this.props.logout();
  }

  renderSignedIn() {
    const { currentUser } = this.props;
    return (<>
      <pre>{JSON.stringify(currentUser, null, 4)}</pre>
      <button className="btn" onClick={this.onClickLogout}>Log Out</button>
    </>);
  }

  renderSignedOut() {
    return (<>
      <Link href={paths.login()}>Log In</Link> &bull; <Link href={paths.signup()}>Sign Up</Link>
    </>);
  }

  render() {
    const { currentUser } = this.props;
    return (
      <div>
        {currentUser ? this.renderSignedIn() : this.renderSignedOut()}
      </div>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthDuck.Slx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
  logout: () => AuthDuck.Ax.logout(),
});

export default connect(stateToProps, dispatchToProps)(Session);
