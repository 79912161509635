import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'app/components/common/icon';

class Modal extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refBox = React.createRef();

    this.onKeyupDocument = this.onKeyupDocument.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onClickClose = this.onClickClose.bind(this);
    this.onClickBg = this.onClickBg.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onKeyupDocument);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onKeyupDocument);
  }

  onClickBg(event) {
    if (!this.props.bgClose) return;
    const boxEl = this.refBox.current;
    if (boxEl && boxEl.contains(event.target)) return;
    this.props.onClose && this.props.onClose();
  }

  onClickBack() {
    this.props.onBack && this.props.onBack();
  }

  onClickClose() {
    this.props.onClose && this.props.onClose();
  }

  onKeyupDocument(event) {
    if (event.key !== 'Escape') return;
    this.props.onClose && this.props.onClose();
  }

  render() {
    const {children, className, onClose, onBack} = this.props;

    return (
      <div className={`modal ${className}`} onPointerDown={this.onClickBg}>
        <div className="modal-box" ref={this.refBox}>
          {!!onBack && (
            <button className="modal-box-back" onClick={this.onClickBack}>
              <Icon.Caret direction="left" />
            </button>
          )}
          {!!onClose && (
            <button className="modal-box-close" onClick={this.onClickClose}>
              <Icon.Remove />
            </button>
          )}
          {children}
        </div>
      </div>
    );
  }

}

Modal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onBack: PropTypes.func,
  bgClose: PropTypes.bool,
};

Modal.defaultProps = {
  className: '',
  bgClose: true,
};

export default Modal;
