import _ from 'lodash';
import { createSelector } from 'reselect';
import { Capacitor } from '@capacitor/core';

import Apps         from 'app/apps';
import mainApi      from 'app/apis/main';
import {
  Pages,
  DeviceTypes,
}                   from 'app/constants';
import emitter      from 'app/emitter';
import utils        from 'app/helpers/utils';
import history      from 'app/history';
import paths        from 'app/paths';
import reducerUtils from 'app/reducers/utils';
import EntitiesSlx  from 'app/selectors/entities';


/*
 *  Actions
 */

const Types = {
  LOGIN: 'AUTH_LOGIN',
  CLEAR_LOGIN: 'AUTH_CLEAR_LOGIN',
  LOGOUT: 'AUTH_LOGOUT',
  CLEAR_LOGOUT: 'AUTH_CLEAR_LOGOUT',
  DELETE_ACCOUNT: 'AUTH_DELETE_ACCOUNT',
  CLEAR_DELETE_ACCOUNT: 'AUTH_CLEAR_DELETE_ACCOUNT',
  GET_CURRENT_USER: 'AUTH_GET_CURRENT_USER',
  SET_CURRENT_USER: 'AUTH_SET_CURRENT_USER',
  SET_AUTH_TOKEN: 'AUTH_SET_AUTH_TOKEN',
  DEVICE_LOGIN: 'AUTH_DEVICE_LOGIN',
  WHOAMI: 'AUTH_WHOAMI',
  EV_REDEEM: 'AUTH_EV_REDEEM',
  CLEAR_EV_REDEEM: 'AUTH_CLEAR_EV_REDEEM',
  DOTSNAKE_REFRESH: 'AUTH_DOTSNAKE_REFRESH',
  EXTEND_SESSION: 'AUTH_EXTEND_SESSION',
};

const Ax = {

  // redirectAfterLogin: () => (dispatch, getState) => {
  //   process.nextTick(() => {
  //     const state = getState();
  //     const currentUser = AuthSlx.currentUser(state);
  //     if (!currentUser) return history.push(paths.home);
  //     // first check emailVerified
  //     const { emailVerified } = currentUser;
  //     if (!emailVerified) return history.push(paths.verifyEmailPage);
  //     // next look for a postLoginUrl
  //     const postLoginUrl = SessionStorageSlx.postLoginUrl(state);
  //     if (postLoginUrl) {
  //       history.push(postLoginUrl);
  //       dispatch(SessionStorageAx.setPostLoginUrl(null));
  //       return;
  //     }
  //     // otherwise go home
  //     history.push(paths.home);
  //   });
  // },

  getCurrentUser: () => {
    const promise = mainApi.authWhoami();
    return { type: Types.GET_CURRENT_USER, promise };
  },

  setCurrentUser: (currentUser=null, isMasquerading=false) => (dispatch, getState) => {
    const app = Apps.getApp();
    if (app?.storage) {
      if (currentUser) {
        app.storage.setObj('currentUser', currentUser);
      } else {
        app.storage.clear('currentUser');
      }
    }

    const prevCurrentUser = Slx.currentUser(getState());
    const result = dispatch({type: Types.SET_CURRENT_USER, currentUser, isMasquerading});
    const prevId = prevCurrentUser?.id || null;
    const newId = currentUser?.id || null;
    if (prevId !== newId) {
      emitter.currentUserChange.emit(prevCurrentUser, currentUser);
    }
    emitter.currentUserUpdate.emit(currentUser);
    return result;
  },

  setAuthToken: (authToken) => {
    const app = Apps.getApp();
    if (app?.storage) {
      if (authToken) {
        app.storage.set('authToken', authToken);
      } else {
        app.storage.clear('authToken');
      }
    }
    // return {type: Types.SET_AUTH_TOKEN, authToken};
    return {type: Types.SET_AUTH_TOKEN};
  },

  login: ({email, deviceType=DeviceTypes.BROWSER} = {}) => (dispatch, getState) => {
    const promise = mainApi.authLoginsCreate({email, deviceType});
    return dispatch({ type: Types.LOGIN, promise });
  },

  clearLogin: () => {
    return {type: Types.CLEAR_LOGIN};
  },

  evRedeem: (evId) => (dispatch, getState) => {
    const promise = mainApi.authSessionsEvRedeem(evId).then(({user, session}) => {
      dispatch(Ax.setCurrentUser(user));
      dispatch(Ax.setAuthToken(session.authToken));
    });
    return dispatch({type: Types.EV_REDEEM, promise});
  },

  clearEvRedeem: () => {
    return {type: Types.CLEAR_EV_REDEEM};
  },

  logout: () => (dispatch, getState) => {
    const promise = mainApi.authSessionsLogout();
    promise.then(() => {
      window.localStorage.clear();
      dispatch(Ax.setAuthToken(null));
      dispatch(Ax.setCurrentUser(null));
    });
    return dispatch({type: Types.LOGOUT, promise});
  },

  clearLogout: () => {
    return {type: Types.CLEAR_LOGOUT};
  },

  deleteAccount: () => (dispatch, getState) => {
    const promise = mainApi.authDeleteAccount();
    promise.then(() => {
      window.localStorage.clear();
      dispatch(Ax.setAuthToken(null));
      dispatch(Ax.setCurrentUser(null));
    });
    return dispatch({type: Types.DELETE_ACCOUNT, promise});
  },

  clearDeleteAccount: () => {
    return {type: Types.CLEAR_DELETE_ACCOUNT};
  },

  stopMasquerading: () => (dispatch, getState) => {
    const promise = mainApi.authStopMasquerading();
    promise.then(() => {
      window.location.href = paths.home;
    });
  },

  deviceLogin: () => (dispatch, getState) => {
    const promise = mainApi.authSessionsDeviceLogin().then(({user, session}) => {
      dispatch(Ax.setCurrentUser(user));
      dispatch(Ax.setAuthToken(session.authToken));
    });
    return dispatch({type: Types.DEVICE_LOGIN, promise});
  },

  whoami: () => (dispatch, getState) => {
    const promise = mainApi.authWhoami()
      .then(({user}) => {
        dispatch(Ax.setCurrentUser(user));
      })
      .catch((error) => {
        const isUnauthorized = _.get(error, 'response.status') === 401;
        if (isUnauthorized) {
          dispatch(Ax.setAuthToken(null));
          dispatch(Ax.setCurrentUser(null));
        }
        throw error;
      });
    return dispatch({type: Types.WHOAMI, promise});
  },

  extendSession: () => (dispatch, getState) => {
    const storage = Apps.getApp()?.storage;
    const storageKey = 'lastSessionExtensionAt';
    const now = (new Date()).getTime();

    const getShouldExtend = async () => {
      if (!storage) return true;
      let extendedAt = await storage.getInt(storageKey);
      if (!extendedAt) extendedAt = 0;
      return (now - extendedAt) > (1000 * 60 * 60 * 24); // more than 1 day
    };

    const promise = getShouldExtend().then((shouldExtend) => {
      if (!shouldExtend) return null;
      return mainApi.authSessionsExtend().then(({user, session}) => {
        dispatch(Ax.setCurrentUser(user));
        dispatch(Ax.setAuthToken(session.authToken));
        if (storage) {
          return storage.set(storageKey, now);
        }
      });
    });
    return dispatch({type: Types.EXTEND_SESSION, promise});
  },

  // check session status
  // perform a session extension
  // perform a device login
  dotsnakeRefresh: () => (dispatch, getState) => {
    if (Capacitor.getPlatform() !== 'ios') return;
    const app = Apps.getApp();
    let promise = dispatch(Ax.whoami())
      .then(() => {
        return dispatch(Ax.extendSession());
      })
      .catch((error) => {
        // if there's a user, something must have gone wrong; otherwise, try to do deviceLogin()
        const currentUser = Slx.currentUser(getState());
        if (currentUser) throw error;
        return dispatch(Ax.deviceLogin());
      });

    return dispatch({type: Types.DOTSNAKE_REFRESH});
  },

};



/*
 *  Reducer
 */

const initialState = {
  currentUser: null,
  // authToken: null,
  isMasquerading: false,
  loginPending: false,
  loginFailed: false,
  loginSuccess: false,
  loginValidations: null,
  logoutPending: false,
  logoutFailed: false,
  logoutSuccess: false,
  deleteAccountPending: false,
  deleteAccountFailed: false,
  deleteAccountSuccess: false,
  deviceLoginPending: false,
  deviceLoginFailed: false,
  deviceLoginSuccess: false,
  whoamiPending: false,
  whoamiFailed: false,
  whoamiSuccess: false,
  evRedeemPending: false,
  evRedeemFailType: null, // expired, already-redeemed, other
  evRedeemSuccess: false,
};

const reducer = reducerUtils.createReducer(initialState, {


  [Types.SET_CURRENT_USER]: (state, {currentUser, isMasquerading}) => {
    console.log('-----------SET_CURRENT_USER', currentUser);
    return {...state,
      currentUser,
      isMasquerading,
    };
  },

  [Types.SET_AUTH_TOKEN]: (state, {authToken}) => {
    return {...state,
      authToken,
    };
  },

  [`${Types.GET_CURRENT_USER}_RESOLVED`]: (state, {result: {currentUser}}) => {
    return {...state,
      currentUserId: currentUser ? currentUser.id : null,
    };
  },

  [`${Types.LOGIN}_PENDING`]: (state, _action) => {
    return {...state,
      loginPending: true,
      loginFailed: false,
      loginSuccess: false,
      loginValidations: null,
    };
  },
  [`${Types.LOGIN}_RESOLVED`]: (state, {result: {currentUser}}) => {
    return {...state,
      loginPending: false,
      loginFailed: false,
      loginSuccess: true,
      loginValidations: null,
    };
  },
  [`${Types.LOGIN}_REJECTED`]: (state, {error}) => {
    const validations = _.get(error, 'response.data.error.validations', null);
    return {...state,
      loginPending: false,
      loginFailed: true,
      loginSuccess: false,
      loginValidations: validations,
    };
  },
  [Types.CLEAR_LOGIN]: (state, action) => {
    return {...state,
      loginPending: false,
      loginFailed: false,
      loginSuccess: false,
      loginValidations: null,
    };
  },

  [`${Types.LOGOUT}_PENDING`]: (state, _action) => {
    return {...state,
      logoutPending: true,
      logoutFailed: false,
      logoutSuccess: false,
    };
  },
  [`${Types.LOGOUT}_RESOLVED`]: (state, action) => {
    return {...state,
      logoutPending: false,
      logoutFailed: false,
      logoutSuccess: true,
    };
  },
  [`${Types.LOGOUT}_REJECTED`]: (state, {error}) => {
    return {...state,
      logoutPending: false,
      logoutFailed: true,
      logoutSuccess: false,
    };
  },
  [Types.CLEAR_LOGOUT]: (state, action) => {
    return {...state,
      logoutPending: false,
      logoutFailed: false,
      logoutSuccess: false,
    };
  },

  [`${Types.DELETE_ACCOUNT}_PENDING`]: (state, _action) => {
    return {...state,
      deleteAccountPending: true,
      deleteAccountFailed: false,
      deleteAccountSuccess: false,
    };
  },
  [`${Types.DELETE_ACCOUNT}_RESOLVED`]: (state, action) => {
    return {...state,
      deleteAccountPending: false,
      deleteAccountFailed: false,
      deleteAccountSuccess: true,
    };
  },
  [`${Types.DELETE_ACCOUNT}_REJECTED`]: (state, {error}) => {
    return {...state,
      deleteAccountPending: false,
      deleteAccountFailed: true,
      deleteAccountSuccess: false,
    };
  },
  [Types.CLEAR_DELETE_ACCOUNT]: (state, action) => {
    return {...state,
      deleteAccountPending: false,
      deleteAccountFailed: false,
      deleteAccountSuccess: false,
    };
  },

  [`${Types.DEVICE_LOGIN}_PENDING`]: (state, _action) => {
    return {...state,
      deviceLoginPending: true,
      deviceLoginFailed: false,
      deviceLoginSuccess: false,
    };
  },
  [`${Types.DEVICE_LOGIN}_RESOLVED`]: (state, action) => {
    return {...state,
      deviceLoginPending: false,
      deviceLoginFailed: false,
      deviceLoginSuccess: true,
    };
  },
  [`${Types.DEVICE_LOGIN}_REJECTED`]: (state, {error}) => {
    return {...state,
      deviceLoginPending: false,
      deviceLoginFailed: true,
      deviceLoginSuccess: false,
    };
  },

  [`${Types.WHOAMI}_PENDING`]: (state, _action) => {
    return {...state,
      whoamiPending: true,
      whoamiFailed: false,
      whoamiSuccess: false,
    };
  },
  [`${Types.WHOAMI}_RESOLVED`]: (state, _action) => {
    return {...state,
      whoamiPending: false,
      whoamiFailed: false,
      whoamiSuccess: true,
    };
  },
  [`${Types.WHOAMI}_REJECTED`]: (state, {error}) => {
    return {...state,
      whoamiPending: false,
      whoamiFailed: true,
      whoamiSuccess: false,
    };
  },

  [`${Types.EV_REDEEM}_PENDING`]: (state, _action) => {
    return {...state,
      evRedeemPending: true,
      evRedeemFailType: null,
      evRedeemSuccess: false,
    };
  },
  [`${Types.EV_REDEEM}_RESOLVED`]: (state, _action) => {
    return {...state,
      evRedeemPending: false,
      evRedeemFailType: null,
      evRedeemSuccess: true,
    };
  },
  [`${Types.EV_REDEEM}_REJECTED`]: (state, {error}) => {
    const statusCode = _.get(error, 'response.data.error.statusCode');
    const is400 = statusCode === 400;
    const reason = _.get(error, 'response.data.error.reason') || 'other';
    return {...state,
      evRedeemPending: false,
      evRedeemFailType: is400 ? reason : 'other',
      evRedeemSuccess: false,
    };
  },
  [Types.CLEAR_EV_REDEEM]: (state, _action) => {
    return {...state,
      evRedeemPending: false,
      evRedeemFailType: null,
      evRedeemSuccess: false,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selAll                  = state => state.auth;
  const selCurrentUser          = state => state.auth.currentUser;
  // const selAuthToken            = state => state.auth.authToken;
  const selIsMasquerading       = state => state.auth.isMasquerading;
  const selLoginPending         = state => state.auth.loginPending;
  const selLoginFailed          = state => state.auth.loginFailed;
  const selLoginSuccess         = state => state.auth.loginSuccess;
  const selLoginValidations     = state => state.auth.loginValidations;
  const selLogoutPending        = state => state.auth.logoutPending;
  const selLogoutFailed         = state => state.auth.logoutFailed;
  const selLogoutSuccess        = state => state.auth.logoutSuccess;
  const selDeleteAccountPending = state => state.auth.deleteAccountPending;
  const selDeleteAccountFailed  = state => state.auth.deleteAccountFailed;
  const selDeleteAccountSuccess = state => state.auth.deleteAccountSuccess;
  const selDeviceLoginPending   = state => state.auth.deviceLoginPending;
  const selDeviceLoginFailed    = state => state.auth.deviceLoginFailed;
  const selDeviceLoginSuccess   = state => state.auth.deviceLoginSuccess;
  const selWhoamiPending        = state => state.auth.whoamiPending;
  const selWhoamiFailed         = state => state.auth.whoamiFailed;
  const selWhoamiSuccess        = state => state.auth.whoamiSuccess;
  const selEvRedeemPending      = state => state.auth.evRedeemPending;
  const selEvRedeemFailType     = state => state.auth.evRedeemFailType;
  const selEvRedeemSuccess      = state => state.auth.evRedeemSuccess;

  return {
    all: selAll,
    currentUser: selCurrentUser,
    isMasquerading: selIsMasquerading,
    loginPending: selLoginPending,
    loginFailed: selLoginFailed,
    loginSuccess: selLoginSuccess,
    loginValidations: selLoginValidations,
    logoutPending: selLogoutPending,
    logoutFailed: selLogoutFailed,
    logoutSuccess: selLogoutSuccess,
    deleteAccountPending: selDeleteAccountPending,
    deleteAccountFailed: selDeleteAccountFailed,
    deleteAccountSuccess: selDeleteAccountSuccess,
    deviceLoginPending: selDeviceLoginPending,
    deviceLoginFailed: selDeviceLoginFailed,
    deviceLoginSuccess: selDeviceLoginSuccess,
    whoamiPending: selWhoamiPending,
    whoamiFailed: selWhoamiFailed,
    whoamiSuccess: selWhoamiSuccess,
    evRedeemPending: selEvRedeemPending,
    evRedeemFailType: selEvRedeemFailType,
    evRedeemSuccess: selEvRedeemSuccess,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
