import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Cell from 'app/dotsnake/components/play/cell';
import Duck from 'app/dotsnake/ducks/game';

const {Ax, Slx} = Duck;

class Board extends React.PureComponent {

  constructor(props) {
    super(props);

    this.tickInterval;

    this.state = {
      blink: false,
    };

    this.onTick = this.onTick.bind(this);
  }

  componentDidMount() {
    this.tickInterval = setInterval(this.onTick, 200);
  }

  componentWillUnmount() {
    clearInterval(this.tickInterval);
  }

  onTick() {
    const {isSpecialEnding, isSpecial} = this.props;
    if (!isSpecialEnding || !isSpecial) return;
    this.setState((prevState) => {
      return {...prevState, blink: !prevState.blink};
    });
  }

  render() {
    const {rowCount: rows, colCount: cols, isSpecial, isSpecialEnding} = this.props;
    const {blink} = this.state;
    const specialClass = isSpecial ? 'special' : '';
    const blinkClass = (blink && isSpecialEnding) ? 'blink' : '';
    if (!rows || !cols) return null;

    const cells = [];
    _.times(rows).forEach((row) => {
      _.times(cols).forEach((col) => {
        cells.push({m: row, mr: (rows - row - 1), n: col, nr: (cols - col - 1)});
      });
    });

    return (
      <div className={`snk-game-board ${specialClass} ${blinkClass}`} data-head-m={2} data-head-n={2}>
        {cells.map((cell) => {
          const key = `${cell.m}x${cell.n}`;
          return (
            <Cell key={key} {...cell} />
          );
        })}
      </div>
    );
  }

}

const stateToProps = (state) => ({
  rowCount: Slx.rowCount(state),
  colCount: Slx.colCount(state),
  isSpecial: Slx.isSpecial(state),
  isSpecialEnding: Slx.isSpecialEnding(state),
});

export default connect(stateToProps)(Board);
