import _ from 'lodash';
import { createSelector } from 'reselect';

import MainApi      from 'app/apis/main';
import Apps         from 'app/apps';
import AuthDuck     from 'app/ducks/auth';
import CitiesDuck   from 'app/ducks/cities';
import reducerUtils from 'app/reducers/utils';

/*
 *  Actions
 */

const Types = {
  SEARCH: 'SNK_LDRBRD_SEARCH',
  SELECT_FILTER: 'SNK_LDRBRD_SELECT_FILTER',
};

const Ax = {

  search: () => (dispatch, getState) => {
    const state = getState();
    const filter = Slx.filter(state);
    const params = Slx.params(state);
    const promise = MainApi.snkScoresSearch(params);
    return dispatch({type: Types.SEARCH, filter, promise});
  },

  selectFilter: (filter) => (dispatch, getState) => {
    dispatch({type: Types.SELECT_FILTER, filter});
    dispatch(Ax.search());
  },


};



/*
 *  Reducer
 */

const initialState = {
  searchPending: false,
  searchFailed: false,
  scores: null,
  scoresFilter: null,
  selectedFilter: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [Types.SELECT_FILTER]: (state, action) => {
    return {...state,
      selectedFilter: action.filter,
    };
  },

  [`${Types.SEARCH}_PENDING`]: (state, action) => {
    return {...state,
      searchPending: true,
      searchFailed: false,
      scores: null,
      scoresFilter: action.filter,
    };
  },
  [`${Types.SEARCH}_RESOLVED`]: (state, action) => {
    if (action.filter !== state.scoresFilter) return state;
    return {...state,
      searchPending: false,
      scores: action.result.snkScores,
    };
  },
  [`${Types.SEARCH}_REJECTED`]: (state, action) => {
    return {...state,
      searchPending: false,
      searchFailed: true,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selSearchPending  = state => state.snkLeaderboard.searchPending;
  const selSearchFailed   = state => state.snkLeaderboard.searchFailed;
  const selScores         = state => state.snkLeaderboard.scores;
  const selScoresFilter   = state => state.snkLeaderboard.scoresFilter;
  const selSelectedFilter = state => state.snkLeaderboard.selectedFilter;

  const selFilters = createSelector(
    [AuthDuck.Slx.currentUser, CitiesDuck.Slx.snkCity],
    (currentUser, city) => {
      const filters = [];
      if (currentUser) filters.push('personal');
      if (city) {
        filters.push('city');
        if (city.region?.cityCount > 1) filters.push('region');
        filters.push('country');
      }
      filters.push('global');
      return filters;
    }
  );

  const selFilter = createSelector(
    [selFilters, selSelectedFilter],
    (filters, selectedFilter) => {
      if (selectedFilter && filters.includes(selectedFilter)) return selectedFilter;
      if (filters.includes('city')) return 'city';
      if (filters.includes('personal')) return 'personal';
      return 'global';
    }
  );

  const selParams = createSelector(
    [AuthDuck.Slx.currentUser, CitiesDuck.Slx.snkCity, selFilter],
    (user, city, filter) => {
      if (filter === 'personal' && user) return {userId: user.id};
      if (filter === 'city'     && city) return {cityId: city.id};
      if (filter === 'region'   && city) return {regionId: city.regionId};
      if (filter === 'country'  && city) return {countryId: city.countryId};
      return {};
    }
  );

  const selShowScores = createSelector(
    [selScores, selScoresFilter, selFilter],
    (scores, scoresFilter, filter) => {
      if (scoresFilter !== filter) return null;
      return scores;
    }
  );

  return {
    searchPending: selSearchPending,
    searchFailed: selSearchFailed,
    scores: selScores,
    showScores: selShowScores,
    selectedFilter: selSelectedFilter,
    filters: selFilters,
    filter: selFilter,
    params: selParams,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
