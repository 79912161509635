import React from 'react';

class PageHome extends React.PureComponent {

  render() {
    return (
      <div>
        <ul>
          <li><a href="https://www.linkedin.com/in/tybro0103/">LinkedIn</a></li>
          <li><a href="/resume-tyler-brown-2024.pdf">Résumé</a></li>
        </ul>
      </div>
    );
  }

}

export default PageHome;
