import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';

import Modal        from 'app/components/common/modal';
import Duck         from 'app/dotsnake/ducks/game';
import SnakeManager from 'app/dotsnake/game/manager';
import history      from 'app/history';
import paths        from 'app/paths';

const {Ax, Slx} = Duck;

class ModalPaused extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickResume = this.onClickResume.bind(this);
    this.onClickNewGame = this.onClickNewGame.bind(this);
    this.onClickMainMenu = this.onClickMainMenu.bind(this);
  }

  onClickResume() {
    SnakeManager.play();
  }

  onClickNewGame() {
    SnakeManager.newGame();
    SnakeManager.play();
  }

  onClickMainMenu() {
    history.push(paths.snkHome());
  }

  render() {
    const {isPaused, score} = this.props;
    if (!isPaused) return null;

    return (
      <Modal className="snk-paused snk-modal">
        <h1 className="snk-modal-title">Game Paused</h1>
        <p className="snk-modal-p">Current Score: {score}</p>
        <div className="snk-modal-actions">
          <button className="snk-btn mint" onClick={this.onClickMainMenu}>Main Menu</button>
          <button className="snk-btn mint" onClick={this.onClickNewGame}>New Game</button>
          <button className="snk-btn mint" onClick={this.onClickResume}>Resume</button>
        </div>
      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  isPaused: Slx.status(state) === 'paused',
  score: Slx.score(state),
});

export default connect(stateToProps)(ModalPaused);
