import _ from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { Capacitor } from '@capacitor/core';

class LayoutMain extends React.PureComponent {

  render() {
    const {children, className} = this.props;
    const platform = Capacitor.getPlatform();
    return (
      <div className={`snk-layout ${className} platform-${platform}`}>
        {children}
      </div>
    );
  }

}

LayoutMain.propTypes = {
  className: PropTypes.string,
};

LayoutMain.defaultProps = {
  className: '',
};

export default LayoutMain;
