'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var parseUrl = exports.parseUrl = function parseUrl(url) {
  var parts = url.split('?');
  var path = parts[0];
  var queryString = parts[1] || '';
  var query = queryString.length > 0 ? queryString.split('&').map(function (part) {
    return part.split('=');
  }).reduce(function (query, kvParts) {
    var decodedKey = decodeURIComponent(kvParts.shift());
    var decodedVal = decodeURIComponent(kvParts.join('='));
    return _extends({}, query, _defineProperty({}, decodedKey, decodedVal));
  }, {}) : {};

  return {
    path: path,
    queryString: queryString,
    query: query
  };
};