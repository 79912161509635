import React from 'react';
import PropTypes from 'prop-types';

import Modal        from 'app/components/common/modal';
import SnakeManager from 'app/dotsnake/game/manager';

class ModalRestorePurchases extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      pending: false,
      success: false,
    };

    this.onClickRestore = this.onClickRestore.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  // componentDidMount() {
  //   this.onClickRestore();
  // }

  onClickRestore(event) {
    event?.preventDefault && event.preventDefault();
    this.setState({pending: true}, () => {
      SnakeManager.restorePurchases()
        .then(() => {
          this.setState({pending: false, success: true});
        })
        .catch((error) => {
          this.setState({pending: false, success: false});
        });
    });
  }

  onClose() {
    this.props.onClose();
  }

  render() {
    const {pending, success} = this.state;

    return (
      <Modal bgClose={false} className="snk-modal-restore snk-modal" onClose={this.onClose}>
        <h1 className="snk-modal-title">Restore Purchases</h1>
        {success && (<>
          <p className="snk-modal-p">Done!</p>
          <div className="snk-modal-actions">
            <button className="snk-btn mint" onClick={this.onClose}>Close</button>
          </div>
        </>)}
        {!success && (
          <div className="snk-modal-actions">
            <button className="snk-btn mint" onClick={this.onClickRestore} disabled={pending}>{pending ? 'Restoring...' : 'Restore Purchases'}</button>
          </div>
        )}
      </Modal>
    );
  }

}

ModalRestorePurchases.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalRestorePurchases;
