import React from 'react';
import {connect} from 'react-redux';

import Modal      from 'app/components/common/modal';
import SafeArea   from 'app/components/common/safe-area';
import LayoutMain from 'app/dotsnake/components/layout-main';
import Game       from 'app/dotsnake/components/play/game';
import Duck       from 'app/dotsnake/ducks/game';

const {Ax, Slx} = Duck;

class PagePlay extends React.PureComponent {

  render() {
    const {color, isSpecial} = this.props;
    const colorClass = color || '';
    const specialClass = isSpecial ? 'special' : '';
    return (
      <LayoutMain>
        <div className={`snk-play ${colorClass} ${specialClass}`}>
          <Game />
        </div>
      </LayoutMain>
    );
  }

}

const stateToProps = (state, ownProps) => ({
  color: Slx.color(state),
  isSpecial: Slx.isSpecial(state),
});

export default connect(stateToProps)(PagePlay);
