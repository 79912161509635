import _ from 'lodash';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { App as CapApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

import {
  client as mainClient,
}                    from 'app/apis/main';
import Apps          from 'app/apps';
import SnkLivesDuck  from 'app/dotsnake/ducks/lives';
import SnkScoresDuck from 'app/dotsnake/ducks/scores';
import SnakeManager  from 'app/dotsnake/game/manager';
import AuthDuck      from 'app/ducks/auth';
import CitiesDuck    from 'app/ducks/cities';
import DeviceDuck    from 'app/ducks/device';
import emitter       from 'app/emitter';
import history       from 'app/history';
import paths         from 'app/paths';

// const getApp = () => {
//   return Apps[_.get(window, '__TYBRO.app')];
// };
const app = Apps.getApp();

const setupApiClients = () => {
  mainClient.defaults.baseURL = `${app.baseUrl}/api`;

  // requests
  const csrfInterceptor = async (config) => {
    config.headers['x-csrf-token'] = _.get(window, '__TYBRO.crsfToken');
    config.headers['x-tybro-app'] = app?.key;
    if (app?.storage) {
      const authToken = await app.storage.get('authToken');
      config.headers['x-auth-token'] = authToken;
    }
    if (app === Apps.dotsnake) {
      const deviceId = await SnakeManager.getDeviceId();
      config.headers['x-device-id'] = deviceId;      
    }
    return config;
  };
  mainClient.interceptors.request.use(csrfInterceptor);

  // responses
  // const responseInterceptor = (error) => {
  //   const status = _.get(error, 'response.status');
  //   if (status === 401) {
  //     window.location.reload();
  //   }
  //   return Promise.reject(error);
  // };
  // mainClient.interceptors.response.use(r => r, responseInterceptor);

};

const listenForSafeArea = async (store) => {
  const setIt = (insets) => {store.dispatch(DeviceDuck.Ax.setSafeArea(insets))};
  const sum = (insets) => _.sum(Object.values(insets));
  const wait = (ms) => new Promise((res) => setTimeout(res, ms));

  SafeArea.addListener('safeAreaChanged', ({insets}) => setIt(insets));

  let tries = 0;
  let insets = {top: 0, right: 0, bottom: 0, left: 0};
  while (tries < 20 && sum(insets) === 0) {
    tries += 1;
    insets = await SafeArea.getSafeAreaInsets().then(({insets}) => insets);
    if (!sum(insets)) await wait(50);
  }
  setIt(insets);
};

const listenForOrientation = async (store) => {
  const setIt = () => store.dispatch(DeviceDuck.Ax.setOrientation(screen.orientation.type));
  setIt();
  screen.orientation.addEventListener('change', setIt);
};

const listenForIosUrlOpens = () => {
  if (Capacitor.getPlatform() !== 'ios') return;
  const handleUrl = (url) => {
    if (!url) return;
    const isHttp = url.startsWith('http');
    const normalizedPath = (() => {
      if (isHttp) {
        const u = new URL(url);
        return `${u.pathname}${u.search}`;
      }
      return url.split(':/')[1] || '/';
    })();
    history.push(normalizedPath);
  };
  CapApp.addListener('appUrlOpen', (event) => {
    handleUrl(event?.url);
  });
  const checkAppLaunchUrl = async () => {
    const launchUrl = await CapApp.getLaunchUrl();
    window.launchUrl = launchUrl;
    if (launchUrl?.url) handleUrl(launchUrl?.url);
  };
  checkAppLaunchUrl();
};

const setupDotsnake = async (store) => {
  SnakeManager.setStore(store);
  const currentUser = await app.storage.getObj('currentUser');
  store.dispatch(AuthDuck.Ax.setCurrentUser(currentUser));
  const city = await app.storage.getObj('city');
  store.dispatch(CitiesDuck.Ax.snkCacheCity(city));
  //
  emitter.currentUserChange.on((prevUser, newUser) => {
    if (newUser) {
      store.dispatch(CitiesDuck.Ax.snkGetCity());
      store.dispatch(SnkScoresDuck.Ax.setPr(newUser.dotsnakeData?.pr || null, {force: true}));
    }
    store.dispatch(SnkScoresDuck.Ax.clearUnsaved());
  });
  emitter.currentUserUpdate.on((currentUser) => {
    if (currentUser) {
      store.dispatch(SnkScoresDuck.Ax.setPr(currentUser.dotsnakeData?.pr || null));
    }
  });
  //
  if (Capacitor.getPlatform() === 'ios') {
    // const refreshSession = () => store.dispatch(AuthDuck.Ax.dotsnakeRefresh());
    const persistScores  = () => store.dispatch(SnkScoresDuck.Ax.sendToServer());
    CapApp.addListener('appStateChange', async ({isActive}) => {
      if (isActive) {
        // await refreshSession();
        persistScores();
      } else {
        SnakeManager.pause();
      }
    });
    // await refreshSession();
    persistScores();
    if (window.location.pathname === '/') {
      history.push(paths.snkHome());
    }
    store.dispatch(SnkLivesDuck.Ax.load());
  }
};

const setup = async (store) => {
  setupApiClients();
  listenForSafeArea(store);
  listenForOrientation(store);
  listenForIosUrlOpens();
  if (app === Apps.dotsnake) await setupDotsnake(store);
};

export default setup;
