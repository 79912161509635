import React from 'react';
import {connect} from 'react-redux';
import numeral from 'numeral';

import Icon       from 'app/components/common/icon';
import Avatar     from 'app/dotsnake/components/avatar';
import LivesDuck  from 'app/dotsnake/ducks/lives';
import ScoresDuck from 'app/dotsnake/ducks/scores';
import AuthDuck   from 'app/ducks/auth';
import CitiesDuck from 'app/ducks/cities';
import history    from 'app/history';
import paths      from 'app/paths';

class Personal extends React.PureComponent {

  componentDidMount() {
    this.props.refreshRank();
  }

  onClickRank(filter) {
    const path = filter
      ? paths.snkScores({filter})
      : paths.snkSettings();
    history.push(path);
  }

  // renderUser() {
  //   const {currentUser, pr} = this.props;
  //   const name = currentUser.displayName || currentUser.defaultDisplayName;
  //   return (
  //     <div className="snk-personal-user">
  //       <Avatar className="snk-personal-avatar" iconName={currentUser.prefs?.snkAvatarIcon} color={currentUser.prefs?.snkAvatarColor} />
  //       <div className="snk-personal-user-name">{name}</div>
  //       {!!pr && (
  //         <div className="snk-personal-user-pr">PR: {pr}</div>
  //       )}
  //     </div>
  //   );
  // }

  renderLives() {
    const {hasInfiniteLives, livesCount} = this.props;
    if (hasInfiniteLives) return null;
    const isOut = livesCount <= 0;
    return isOut
      ? (
        <div className="snk-personal-warn">
          <Icon.AlertCircle className="snk-personal-warn-icon" />
          <div className="snk-personal-warn-text">
            <h3 className="snk-personal-warn-h3">Out of Lives</h3>
            <p className="snk-personal-warn-p">You can purchase infinite lives in Settings.</p>
          </div>
        </div>
      ) : null;
      // : (
      //   <div className="snk-personal-lives">
      //     <Icon.FitnessHeartRate className="snk-personal-lives-icon" />
      //     <span className="snk-personal-lives-count">{livesCount}</span>
      //     <span className="snk-personal-lives-label">Lives Left</span>
      //   </div>
      // );
  }

  renderLoggedOut() {
    const {currentUser, loginPending, deviceLoginPending} = this.props;
    if (currentUser || loginPending || deviceLoginPending) return null;

    return (
      <div className="snk-personal-warn">
        <Icon.AlertCircle className="snk-personal-warn-icon" />
        <div className="snk-personal-warn-text">
          <h3 className="snk-personal-warn-h3">Not Signed In</h3>
          <p className="snk-personal-warn-p">Your scores won’t be saved unless you sign in.</p>
        </div>
      </div>
    );
  }

  renderRank() {
    const {city, pr, currentUser, hasInfiniteLives, livesCount} = this.props;
    if (!currentUser) return null;
    if (!city?.rank) return null;
    if (!pr) return null;
    const name = currentUser.displayName || currentUser.defaultDisplayName;
    const toRank = (num) => {
      if (!num) return '-';
      // if (num >= 1000000) return `#${numeral(num).format('0.00a')}`;
      if (num >= 100000) return `#${numeral(num).format('0.0a')}`;
      return `#${numeral(num).format('0,0')}`;
    };
    const stats = [
      {val: <Avatar className="snk-personal-rank-avatar" iconName={currentUser.prefs?.snkAvatarIcon} color={currentUser.prefs?.snkAvatarColor} size={20} />, key: name, filter: null},
    ];
    if (!hasInfiniteLives) {
      stats.push({val: <><Icon.Heart className={`snk-personal-rank-lives-icon ${livesCount === 0 ? 'red' : ''}`} /> {livesCount}</>, key: 'Lives Left', filter: null});
    }
    if (pr) {
      stats.push({val: numeral(pr).format('0,0'), key: 'Best', filter: 'personal'});
    }
    stats.push({val: toRank(city.rank.city), key: city.name, filter: 'city'});
    if (city.region?.cityCount > 1) {
      stats.push({val: toRank(city.rank.region), key: city.region.name, filter: 'region'});
    }
    stats.push({val: toRank(city.rank.country), key: city.country.name, filter: 'country'});
    stats.push({val: toRank(city.rank.global), key: 'Global', filter: 'global'});
    return (
      <div className="snk-personal-rank">
        <div className="snk-personal-rank-stats">
          {stats.map((stat) => {
            return (
              <div key={stat.key} className="snk-personal-rank-stat" onClick={this.onClickRank.bind(this, stat.filter)}>
                <div className="snk-personal-rank-stat-val">{stat.val}</div>
                <div className="snk-personal-rank-stat-key">{stat.key}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    const {currentUser, city} = this.props;
    return (
      <div className="snk-personal">
        {this.renderLives()}
        {this.renderRank()}
        {this.renderLoggedOut()}
        {/* {this.renderUser()} */}
      </div>
    );
  }

}

const stateToProps = (state) => ({
  currentUser: AuthDuck.Slx.currentUser(state),
  loginPending: AuthDuck.Slx.loginPending(state),
  deviceLoginPending: AuthDuck.Slx.deviceLoginPending(state),
  city: CitiesDuck.Slx.snkCity(state),
  pr: ScoresDuck.Slx.pr(state),

  hasInfiniteLives: LivesDuck.Slx.hasInfinite(state),
  livesCount: LivesDuck.Slx.count(state),
});

const dispatchToProps = (dispatch) => ({
  refreshRank: () => dispatch(CitiesDuck.Ax.snkGetCity()),
});

export default connect(stateToProps, dispatchToProps)(Personal);
