import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Avatar from 'app/dotsnake/components/avatar';
import Duck   from 'app/dotsnake/ducks/game';

const {Ax, Slx} = Duck;

class Cell extends React.PureComponent {
  render() {
    const {obj, m, mr, n, nr} = this.props;
    const headClass = obj?.isHead ? 'head' : '';
    return (
      <div className={`snk-game-board-cell ${headClass}`} data-m={m} data-mr={mr} data-n={n} data-nr={nr}>
        {obj && (
          <div className={`snk-game-board-cell-obj type-${obj.type} color-${obj.color || ''}`} />
        )}
      </div>
    );
  }
}

Cell.propTypes = {
  m:  PropTypes.number.isRequired,
  mr: PropTypes.number.isRequired,
  n:  PropTypes.number.isRequired,
  nr: PropTypes.number.isRequired,
};

const stateToProps = (state, ownProps) => ({
  obj: Slx.cell(state, `${ownProps.m}x${ownProps.n}`),
});

export default connect(stateToProps)(Cell);

