import 'babel-polyfill';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

// globals needs imported first
import globals from 'client/globals';
import setup   from 'client/setup';


// import AppAx from 'app/actions/app';
// import SessionStorageAx from 'app/actions/session-storage';
import Apps         from 'app/apps';
import App          from 'app/components/app';
import {
  Pages,
}                   from 'app/constants';
import RoutingDuck  from 'app/ducks/routing';
import history      from 'app/history';
import createRouter from 'app/router';
import createStore  from 'app/store';

const {serverState, app: appKey} = window.__TYBRO;
const app = Apps.byKey[appKey];
const store = createStore(serverState);
const router = app.createRouter(store);

setup(store);

require('./debug-globals').default({ store });

const initializeReact = () => {
  const rootEl = document.getElementById('app');
  const rootComp = <Provider store={store}><App /></Provider>;
  hydrateRoot(rootEl, rootComp);
};

const onRouteFinish = (location, data, redirect, error) => {
  if (error) throw error;
  if (redirect) {
    if (redirect.startsWith('http')) return window.location.href = redirect;
    return history.replace(redirect);
  }
  const currentPage = RoutingDuck.Slx.page(store.getState());
  // ok or not found
  store.dispatch(RoutingDuck.Ax.setLocation(location));
  const page = (data && data.page) || Pages.NOT_FOUND;
  store.dispatch(RoutingDuck.Ax.setPage(page));
  // scroll to top if new page
  if (currentPage !== page) {
    window.scrollTo(0, 0);
  }
};

const setApp = () => {
  store.dispatch(RoutingDuck.Ax.setApp(appKey));
};

// const clearIsServer = () => {
//   store.dispatch(AppAx.clearIsServer());
// };

// const reconcileSessionStorage = () => {
//   store.dispatch(SessionStorageAx.reconcile());
// };

const startRouting = () => {
  router.startRouting(history, onRouteFinish);
};

const launch = () => {
  setApp();
  initializeReact();
  // clearIsServer();
  // reconcileSessionStorage();
  startRouting();
};

launch();
