import PropTypes from 'prop-types';
import React from 'react';

const Checkmark = () => (
  <svg viewBox="0 0 13 13">
    <polyline fill="none" stroke="#FFFFFF" strokeWidth="2" points="3 7.101477 5.75057787 9.45675126 10.9403789 3" />
  </svg>
);

class Checkbox extends React.PureComponent {
  render() {
    const {isToggle, className, ...restProps} = this.props;
    return (
      <div className={`checkbox ${className} ${isToggle ? 'toggle' : ''}`}>
        <input type="checkbox" {...restProps} />
        {isToggle ? (
          <div className="checkbox-toggle">
            <div className="checkbox-toggle-knob" />
          </div>
        ) : (
          <div className="checkbox-fake">
            <Checkmark />
          </div>
        )}
      </div>
    );
  }
}

Checkbox.propTypes = {
  isToggle: PropTypes.bool,
};

Checkbox.defaultProps = {
  isToggle: false,
};

export default Checkbox;
