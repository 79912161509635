import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RoutingDuck from 'app/ducks/routing';
import history from 'app/history';

class Link extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const {skipConfirm, target, secondaryOnClick, navTo, href} = this.props;
    // go with default behavior (open in new tab) if metaKey is pressed
    if (e.metaKey) return;
    // respect _blank
    if (target === '_blank') return;
    //
    secondaryOnClick && secondaryOnClick(e);
    e.preventDefault();
    navTo(href, skipConfirm);
  }

  render() {
    const { children, onClick, secondaryOnClick, navTo, skipConfirm, ...props } = this.props;

    return (
      <a {...props} onClick={onClick || this.onClick}>{children}</a>
    );
  }

}

Link.propTypes = {
  href: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  // if you want the default onClick functionality, but also want to trigger something else
  secondaryOnClick: PropTypes.func,
  skipConfirm: PropTypes.bool,
};

Link.defaultProps = {
  href: '#',
  target: '_self',
  className: '',
  children: null,
  skipConfirm: false,
};

const dispatchToProps = (dispatch) => ({
  navTo: (path, skipConfirm) => dispatch(RoutingDuck.Ax.navTo(path, {skipConfirm})),
})

export default connect(undefined, dispatchToProps)(Link);
