const tybroEnv = process.env.TYBRO_ENV || 'production';

const defaults = {
  tybroEnv,
};

const envOverwrites = {
  development: {
    baseUrl: 'https://tybro0103-snk.ngrok.io',
    apiBaseUrl: 'https://tybro0103-snk.ngrok.io/api',
    cookieDomain: null,
  },
  production: {
    baseUrl: 'https://tybroapps.com',
    apiBaseUrl: 'https://tybroapps.com/api',
    cookieDomain: 'tybroapps.com',
  },
};

const Config = Object.assign({}, defaults, (envOverwrites[tybroEnv] || {}));

module.exports = Config;
