
const Types = {
  pending: (type) => `${type}_PENDING`,
  resolved: (type) => `${type}_RESOLVED`,
  rejected: (type) => `${type}_REJECTED`,
};

const promiseMiddleware = (_store) => (next) => (action) => {
  const {promise, type} = action;
  // ignore if not promise action
  if (!promise) return next(action);

  // build a redux action object
  const makeAction = (promiseType, data = {}) => {
    const newAction = { ...action, ...data, type: promiseType(type) };
    delete newAction.promise;
    return newAction;
  };

  // dispatch
  next(makeAction(Types.pending));
  return promise.then(
    (result) => {
      next(makeAction(Types.resolved, {result}));
      return Promise.resolve(result);
    },
    (error) => {
      next(makeAction(Types.rejected, {error}));
      return Promise.reject(error);
    }
  );
};

export {
  Types,
};

export default promiseMiddleware;
