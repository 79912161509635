import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Link          from 'app/components/common/link';
import StandardInput from 'app/components/common/standard-input';
import MainLayout    from 'app/components/layout/main-layout';
import AuthDuck      from 'app/ducks/auth';
import paths         from 'app/paths';

class PageLogin extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refEmail = React.createRef();

    this.onClickLogin = this.onClickLogin.bind(this);
  }

  onClickLogin() {
    const email = this.refEmail.current.value;
    this.props.login(email);
  }

  renderForm() {
    const {pending, failed, validations} = this.props;
    return (<>
      <StandardInput name="email" type="email" label="Email Address" ref={this.refEmail} validations={validations} />
      <button className="btn" onClick={this.onClickLogin} disabled={pending}>Log In</button>      
    </>);
  }

  renderSuccess() {
    return (<>
      <h2>Click the magic link</h2>
      <p>
        We've emailed you a one-time link. Click the link, and you'll be in.
      </p>
    </>);
  }

  render() {
    const {success} = this.props;
    return (
      <MainLayout>
        <div className="widther">
          <h1>Login</h1>
          {success ? this.renderSuccess() : this.renderForm()}
        </div>
      </MainLayout>
    );
  }

}

PageLogin.propTypes = {
};

PageLogin.defaultProps = {
};

const stateToProps = (state) => ({
  pending: AuthDuck.Slx.loginPending(state),
  failed: AuthDuck.Slx.loginFailed(state),
  success: AuthDuck.Slx.loginSuccess(state),
  validations: AuthDuck.Slx.loginValidations(state),
});

const dispatchToProps = (dispatch) => ({
  login: (email) => dispatch(AuthDuck.Ax.login(email)),
});

export default connect(stateToProps, dispatchToProps)(PageLogin);
