import _ from 'lodash';

import utils from 'app/helpers/utils';

const { buildQueryString } = utils;

const paths = {

  home: () => '/',
  login: (query={}) => `/login${buildQueryString(query)}`,
  signup: (query={}) => `/signup${buildQueryString(query)}`,
  emailVerification: (id) => `/email-verifications/${id}`,
  // emailVerificationClick: (id) => `/email-verifications/${id}/click`,

  snkLanding:      () => `/`,
  snkHome:         () => `/home`,
  snkPlay:         () => `/play`,
  snkScores:       (query={}) => `/scores${buildQueryString(query)}`,
  snkSettings:     () => `/settings`,
  snkInstructions: () => `/instructions`,

};

export default paths;
