import { combineReducers } from 'redux';

import {reducer as snkGame}        from 'app/dotsnake/ducks/game';
import {reducer as snkLeaderboard} from 'app/dotsnake/ducks/leaderboard';
import {reducer as snkLives}       from 'app/dotsnake/ducks/lives';
import {reducer as snkScores}      from 'app/dotsnake/ducks/scores';
import {reducer as account}        from 'app/ducks/account';
import {reducer as auth}           from 'app/ducks/auth';
import {reducer as cities}         from 'app/ducks/cities';
import {reducer as device}         from 'app/ducks/device';
import {reducer as routing}        from 'app/ducks/routing';
import entities                    from 'app/reducers/entities/';

const rootReducer = combineReducers({
  account,
  auth,
  cities,
  device,
  entities,
  routing,

  snkGame,
  snkLeaderboard,
  snkLives,
  snkScores,
});

export default rootReducer;
