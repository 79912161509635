import _ from 'lodash';
import { createSelector } from 'reselect';

import ScoresDuck   from 'app/dotsnake/ducks/scores';
import AuthDuck     from 'app/ducks/auth';
import reducerUtils from 'app/reducers/utils';



/*
 *  Actions
 */

const Types = {
  SET_SIZE: 'SNAKE_SET_SIZE',
  SET_COUNTDOWN: 'SNAKE_SET_COUNTDOWN',
  SET_STATUS: 'SNAKE_SET_STATUS',
  SET_SPECIAL_ON: 'SNAKE_SET_SPECIAL_ON',
  SET_SPECIAL_IS_ENDING: 'SNAKE_SET_SPECIAL_IS_ENDING',
  SET_SCORE: 'SNAKE_SET_SCORE',
  SET_COLOR: 'SNAKE_SET_COLOR',
  SET_CELL: 'SNAKE_SET_CELL',
  RESET: 'SNAKE_RESET',
  GET_RESULT: 'SNAKE_GET_RESULT',
};

const Ax = {

  setSize: ({rowCount, colCount}) => {
    return {type: Types.SET_SIZE, rowCount, colCount};
  },

  setCountdown: (countdown) => {
    return {type: Types.SET_COUNTDOWN, countdown};
  },

  setStatus: (status) => {
    return {type: Types.SET_STATUS, status};
  },

  setSpecialOn: (isSpecial) => {
    return {type: Types.SET_SPECIAL_ON, isSpecial};
  },

  setSpecialIsEnding: (isSpecialEnding) => {
    return {type: Types.SET_SPECIAL_IS_ENDING, isSpecialEnding};
  },

  setScore: (score) => {
    return {type: Types.SET_SCORE, score};
  },

  setColor: (color) => {
    return {type: Types.SET_COLOR, color};
  },

  setCell: (row, col, obj) => {
    return {type: Types.SET_CELL, row, col, obj};
  },

  reset: () => {
    return {type: Types.RESET};
  },

  getResult: (getFn) => (dispatch, getState) => {
    const currentUser = AuthDuck.Slx.currentUser(getState());
    if (!currentUser) return null;
    const promise = getFn(currentUser.id);
    promise.then((result) => {
      if (result.score) {
        dispatch(ScoresDuck.Ax.saveNew(result));
        dispatch(ScoresDuck.Ax.setPr(result.score));
      }
    });
    return dispatch({type: Types.GET_RESULT, promise});
  },

};



/*
 *  Reducer
 */

const initialState = {
  rowCount: 0,
  colCount: 0,
  countdown: 0,
  score: 0,
  status: 'pending',
  isSpecial: false,
  isSpecialEnding: false,
  cells: {},
  result: null,
  resultPending: false,
  color: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [Types.SET_SIZE]: (state, action) => {
    return {...state,
      rowCount: action.rowCount,
      colCount: action.colCount,
    };
  },

  [Types.SET_COUNTDOWN]: (state, action) => {
    return {...state,
      countdown: action.countdown,
    };
  },

  [Types.SET_STATUS]: (state, action) => {
    return {...state,
      status: action.status,
    };
  },

  [Types.SET_SPECIAL_ON]: (state, action) => {
    return {...state,
      isSpecial: action.isSpecial,
    };
  },

  [Types.SET_SPECIAL_IS_ENDING]: (state, action) => {
    return {...state,
      isSpecialEnding: action.isSpecialEnding,
    };
  },

  [Types.SET_SPECIAL_IS_ENDING]: (state, action) => {
    return {...state,
      isSpecialEnding: action.isSpecialEnding,
    };
  },

  [Types.SET_SCORE]: (state, action) => {
    return {...state,
      score: action.score,
    };
  },

  [Types.SET_COLOR]: (state, action) => {
    return {...state,
      color: action.color,
    };
  },

  [Types.SET_CELL]: (state, {row, col, obj}) => {
    const key = `${row}x${col}`;
    return {...state,
      cells: {...state.cells,
        [key]: obj ? {...obj} : null,
      },
    };
  },

  [Types.RESET]: (state, action) => {
    return {...state,
      ...initialState,
    };
  },

  [`${Types.GET_RESULT}_PENDING`]: (state, action) => {
    return {...state,
      resultPending: true,
      result: null,
    };
  },
  [`${Types.GET_RESULT}_RESOLVED`]: (state, {result}) => {
    return {...state,
      resultPending: false,
      result: {...result},
    };
  },
  [`${Types.GET_RESULT}_REJECTED`]: (state, action) => {
    return {...state,
      resultPending: false,
      result: null,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selRowCount        = (state) => state.snkGame.rowCount;
  const selColCount        = (state) => state.snkGame.colCount;
  const selCountdown       = (state) => state.snkGame.countdown;
  const selScore           = (state) => state.snkGame.score;
  const selColor           = (state) => state.snkGame.color;
  const selStatus          = (state) => state.snkGame.status;
  const selIsSpecial       = (state) => state.snkGame.isSpecial;
  const selIsSpecialEnding = (state) => state.snkGame.isSpecialEnding;
  const selCells           = (state) => state.snkGame.cells;
  const selCell            = (state, key) => state.snkGame.cells[key] || null;

  return {
    rowCount: selRowCount,
    colCount: selColCount,
    countdown: selCountdown,
    score: selScore,
    color: selColor,
    status: selStatus,
    isSpecial: selIsSpecial,
    isSpecialEnding: selIsSpecialEnding,
    cells: selCells,
    cell: selCell,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
