
import Duck  from 'app/dotsnake/ducks/game';
import Snake from 'app/dotsnake/game/snake';

const {Ax, Slx} = Duck;

const STORAGE_KEY = 'tybro-dotsnake';
let snake = null;
let store = null;

const handlers = {
  setSize:            (data) => store.dispatch(Ax.setSize({rowCount: data.rowCount, colCount: data.colCount})),
  setScore:           (data) => store.dispatch(Ax.setScore(data.score)),
  setColor:           (data) => store.dispatch(Ax.setColor(data.color)),
  setStatus:          (data) => store.dispatch(Ax.setStatus(data.status)),
  setSpecialOn:       (data) => store.dispatch(Ax.setSpecialOn(data.specialOn)),
  setSpecialIsEnding: (data) => store.dispatch(Ax.setSpecialIsEnding(data.specialIsEnding)),
  setCountdown:       (data) => store.dispatch(Ax.setCountdown(data.countdown)),
  setCell:            (data) => store.dispatch(Ax.setCell(data.row, data.col, data.obj)),
  reset:              (data) => store.dispatch(Ax.reset()),
};

const onDispatch = (event, data) => {
  const handler = handlers[event];
  handler && handler(data);
};

const newGame = ({useSaved=false} = {}) => {
  if (snake) snake.kill();
  if (!useSaved) localStorage.removeItem(STORAGE_KEY);
  const savedStateJson = localStorage.getItem(STORAGE_KEY);
  const savedState = (savedStateJson && useSaved) ? JSON.parse(savedStateJson) : null;
  snake = new Snake({rowCount: 48, colCount: 27, dispatch: onDispatch, skipInit: !!savedState});
  if (savedState) {
    snake.hydrate(savedState);
  }
};

const play = () => {
  snake && snake.play();
};

const pause = () => {
  snake && snake.pause();
  localStorage.setItem(STORAGE_KEY, JSON.stringify(snake.serialize()));
};

const addDirection = (direction) => {
  snake && snake.addDirection(direction);
};

const setStore = (_store) => {
  store = _store;
};

const getState = () => {
  if (!snake) return null;
  return snake.serialize();
};

const getDeviceId = async () => null;

const getHasInfiniteLives   = async () => true;
const getInfiniteLivesPrice = async () => '-';
const purchaseInfiniteLives = async () => {};
const restorePurchases      = async () => {};

export default {
  newGame,
  play,
  pause,
  addDirection,
  setStore,
  getState,
  getDeviceId,

  getHasInfiniteLives,
  getInfiniteLivesPrice,
  purchaseInfiniteLives,
  restorePurchases,
};
