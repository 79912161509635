import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

import mainApi, {
  client as mainClient,
}                                    from 'app/apis/main';
import Apps                          from 'app/apps';
import config                        from 'app/config';
import SnkScoresDuck                 from 'app/dotsnake/ducks/scores';
import SnakeManager                  from 'app/dotsnake/game/manager';
import AuthDuck                      from 'app/ducks/auth';
import RoutingDuck                   from 'app/ducks/routing';
import emitter                       from 'app/emitter';
import format                        from 'app/helpers/format';
import history                       from 'app/history';
import paths                         from 'app/paths';

const setGlobals = (items={}) => {
  const TYBRO = {
    ...items,
    axios,
    _,
    moment,
    numeral,
    emitter,
    config,
    history,
    paths,
    format,
    mainClient,
    Apps,
    SnakeManager,
    currentApp: Apps.getApp(),
    Apis: {
      Main: mainApi,
    },
    Ax: {
    },
    Slx: {
    },
    Dx: {
      Auth: AuthDuck,
      Routing: RoutingDuck,
      SnkScores: SnkScoresDuck,
    },
  };

  const hasBackstage = _.get(window, '__TYBRO.hasBackstage', false);
  if (process.env.NODE_ENV === 'development' || hasBackstage) {
    window.TYBRO = TYBRO;
  }
};

export default setGlobals;
