import { Preferences } from '@capacitor/preferences';

const lsStorage = {
  async set(key, val) {
    return localStorage.setItem(key, val);
  },
  async get(key) {
    return localStorage.getItem(key);
  },
  async getInt(key) {
    const strVal = localStorage.getItem(key);
    if (!strVal) return null;
    return parseInt(strVal);
  },
  async setObj(key, obj) {
    const val = JSON.stringify(obj);
    return localStorage.setItem(key, val);
  },
  async getObj(key) {
    const json = localStorage.getItem(key);
    try {
      return JSON.parse(json);
    } catch (error) {
      return null;
    }
  },
  async clear(key) {
    return localStorage.removeItem(key);
  },
};

const capStorage = {
  async set(key, value) {
    return Preferences.set({key, value: `${value}`});
  },
  async get(key) {
    const {value} = await Preferences.get({key});
    return value;
  },
  async getInt(key) {
    const strVal = await capStorage.get(key);
    if (!strVal) return null;
    return parseInt(strVal);
  },
  async setObj(key, obj) {
    const value = JSON.stringify(obj);
    return Preferences.set({key, value});
  },
  async getObj(key) {
    const json = await capStorage.get(key);
    try {
      return JSON.parse(json);
    } catch (error) {
      return null;
    }
  },
  async clear(key) {
    return Preferences.remove({key});
  },
};

export default {
  lsStorage,
  capStorage,
};
