import React from 'react';

import Link       from 'app/components/common/link';
import SafeArea   from 'app/components/common/safe-area';
import LayoutMain from 'app/dotsnake/components/layout-main';
import paths      from 'app/paths';

class PageLanding extends React.PureComponent {

  render() {
    return (
      <LayoutMain>
        <div className="snk-landing">
          <h1 className="snk-landing-h1">Dot Snake</h1>
          <p className="snk-landing-p">The classic game of snake with competitive leaderboards.</p>
          <p className="snk-landing-p">No ads. No tricks. No pay-to-win. Just fun.</p>
          <div className="snk-landing-actions">
            <a href="https://apps.apple.com/us/app/dot-snake/id6477844594" className="snk-landing-download">
              <img src="/common/images/apple/app-store-us-black.svg" alt="Apple App Store" />
            </a>
            <Link className="snk-landing-play" href={paths.snkHome()}>
              <span className="snk-landing-play-main">Play Web Version</span>
              <span className="snk-landing-play-sub">No scores saved.</span>
            </Link>
          </div>
          <div className="snk-landing-fp">
            <div className="snk-landing-fp-tybro">Copyright © 2024 <a href="https://tybroapps.com">Tybro Apps, LLC</a> • <a href="https://tybroapps.com/privacy-policy.html">Privacy Policy</a></div>
          </div>
        </div>
      </LayoutMain>
    );
  }

}

export default PageLanding;
