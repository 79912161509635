import _ from 'lodash';
import { createSelector } from 'reselect';

import MainApi      from 'app/apis/main';
import Apps         from 'app/apps';
import AuthDuck     from 'app/ducks/auth';
import reducerUtils from 'app/reducers/utils';

const usKey = 'unsavedScores';

/*
 *  Actions
 */

const Types = {
  SAVE_NEW: 'SNK_SCORES_SAVE_NEW',
  SEND_TO_SERVER: 'SNK_SCORES_SEND_TO_SERVER',
  CLEAR_UNSAVED: 'SNK_SCORES_CLEAR_UNSAVED',
  SET_PR: 'SNK_SCORES_SET_PR',
};

const Ax = {

  saveNew: (score) => (dispatch, getState) => {
    const storage = Apps.getApp()?.storage;
    if (!storage) return;
    const promise = (async () => {
      let unsavedScores = await storage.getObj(usKey);
      if (!unsavedScores) unsavedScores = [];
      unsavedScores.push(score);
      if (unsavedScores.length > 30) {
        unsavedScores.shift();
      }
      await storage.setObj(usKey, unsavedScores);
    })();
    promise.then(() => {
      dispatch(Ax.sendToServer());
    })
    return dispatch({type: Types.SAVE_NEW, score, promise});
  },

  sendToServer: () => (dispatch, getState) => {
    const storage = Apps.getApp()?.storage;
    if (!storage) return;
    const currentUser = AuthDuck.Slx.currentUser(getState());
    if (!currentUser) return;

    const promise = (async () => {
      let unsavedScores = await storage.getObj(usKey);
      if (!unsavedScores) unsavedScores = [];
      const score = unsavedScores[0];
      if (!score) return;

      await MainApi.snkScoresCreate(score).then(async () => {
        let unsavedScores2 = await storage.getObj(usKey);
        unsavedScores2 = unsavedScores2.filter(us => us.checksum !== score.checksum);
        await storage.setObj(usKey, unsavedScores2);
      });

      // check for more
      unsavedScores = await storage.getObj(usKey);
      if (unsavedScores.length) {
        return dispatch(Ax.sendToServer());
      }
    })();

    return dispatch({type: Types.SEND_TO_SERVER, promise});
  },

  clearUnsaved: () => (dispatch, getState) => {
    const storage = Apps.getApp()?.storage;
    if (!storage) return;
    const promise = storage.clear(usKey);
    return dispatch({type: Types.CLEAR_UNSAVED, promise});
  },

  setPr: (score, {force=false}={}) => (dispatch, getState) => {
    const currentPr = Slx.pr(getState());
    console.log('------------------- setPr');
    console.log({score, currentPr, force});
    console.log('------------------- setPr');
    if ((score && (score > currentPr)) || force) {
      dispatch({type: Types.SET_PR, score});
    }
  },

};



/*
 *  Reducer
 */

const initialState = {
  pr: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.SAVE_NEW}_PENDING`]: (state, action) => {
    return {...state,
    };
  },

  [Types.SET_PR]: (state, action) => {
    return {...state,
      pr: action.score,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selPr = state => state.snkScores.pr;

  return {
    pr: selPr,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
