import _ from 'lodash';
import timm from 'timm';

import utils from 'app/reducers/utils';

const initialState = {};

const reducer = utils.createReducer(initialState, {

  ...utils.entityHandlers(['user', 'users']),

});

export default reducer;
