import PropTypes from 'prop-types';
import React from 'react';

import Link             from 'app/components/common/link';
// import Header           from 'app/components/layout/header';
import Session          from 'app/components/auth/session';
import paths            from 'app/paths';

class MainLayout extends React.PureComponent {

  render() {
    const { className } = this.props;

    return (
      <div className={`app ${className}`}>
        <div className="widther">
          <h2>Nav</h2>
          <ul>
            <li><Link href={paths.home()}>Home</Link></li>
            <li><Link href={paths.login()}>Login</Link></li>
            <li><Link href={paths.signup()}>Signup</Link></li>
          </ul>
          <h2>Session</h2>
          <Session />
        </div>
        <hr />
        <div className="app-body">
          {this.props.children}
        </div>
      </div>
    );
  }

}

MainLayout.propTypes = {
  className: PropTypes.string.isRequired,
};

MainLayout.defaultProps = {
  className: '',
};

export default MainLayout;
