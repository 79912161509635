import _ from 'lodash';
import { createSelector } from 'reselect';
import { Capacitor } from '@capacitor/core';

import MainApi      from 'app/apis/main';
import Apps         from 'app/apps';
import SnakeManager from 'app/dotsnake/game/manager';
import reducerUtils from 'app/reducers/utils';

const countKey = 'livesCount';
const defaultCount = 20;

/*
 *  Actions
 */

const Types = {
  LOAD: 'SNK_LIVES_LOAD',
  DECREMENT: 'SNK_LIVES_DECREMENT',
  SAVE_COUNT: 'SNK_LIVES_SAVE_COUNT',
  SET_HAS_INFINITE: 'SNK_LIVES_SET_HAS_INFINITE',
  SET_PURCHASE_PENDING: 'SNK_LIVES_SET_PURCHASE_PENDING',
};

const Ax = {

  load: () => (dispatch, getState) => {
    const countPromise = SnakeManager.keychainGet(countKey).then((strVal) => {
      if (!strVal) return defaultCount;
      const intVal = parseInt(strVal);
      if (!_.isFinite(intVal) || intVal < 0) return 0;
      return intVal;
    });
    const ilPromise = SnakeManager.getHasInfiniteLives();
    const promise = Promise.all([countPromise, ilPromise]).then(([count, hasInfinite]) => {
      return {count, hasInfinite};
    });
    return dispatch({type: Types.LOAD, promise});
  },

  decrement: () => (dispatch, getState) => {
    let count = Slx.count(getState());
    count = Math.max(count - 1, 0);
    return dispatch(Ax.saveCount(count));
  },

  saveCount: (count) => {
    const promise = SnakeManager.keychainSet(countKey, `${count}`);
    return {type: Types.SAVE_COUNT, promise, count};
  },

  setHasInfinite: (hasInfinite) => {
    return {type: Types.SET_HAS_INFINITE, hasInfinite};
  },

  setPurchasePending: (purchasePending) => {
    return {type: Types.SET_PURCHASE_PENDING, purchasePending};
  },

};



/*
 *  Reducer
 */

const initialState = {
  loadPending: false,
  count: Capacitor.getPlatform() === 'web' ? 20 :  0,
  savePending: false,
  hasInfinite: false,
  purchasePending: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.LOAD}_PENDING`]: (state, action) => {
    return {...state,
      loadPending: true,
    };
  },
  [`${Types.LOAD}_RESOLVED`]: (state, action) => {
    const {count, hasInfinite} = action.result;
    return {...state,
      loadPending: false,
      count,
      hasInfinite,
    };
  },
  [`${Types.LOAD}_REJECTED`]: (state, action) => {
    return {...state,
      loadPending: false,
    };
  },

  [`${Types.SAVE_COUNT}_PENDING`]: (state, action) => {
    return {...state,
      savePending: true,
      count: action.count,
    };
  },
  [`${Types.SAVE_COUNT}_RESOLVED`]: (state, action) => {
    return {...state,
      savePending: false,
    };
  },
  [`${Types.SAVE_COUNT}_REJECTED`]: (state, action) => {
    return {...state,
      savePending: false,
    };
  },

  [Types.SET_HAS_INFINITE]: (state, action) => {
    return {...state,
      hasInfinite: action.hasInfinite,
    };
  },

  [Types.SET_PURCHASE_PENDING]: (state, action) => {
    return {...state,
      purchasePending: action.purchasePending,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selLoadPending     = state => state.snkLives.loadPending;
  const selCount           = state => state.snkLives.count;
  const selHasInfinite     = state => state.snkLives.hasInfinite;
  const selPurchasePending = state => state.snkLives.purchasePending;

  const selCanPlay = createSelector(
    [selCount, selHasInfinite],
    (count, hasInfinite) => {
      if (hasInfinite) return true;
      if (count > 0) return true;
      return false;
    }
  );

  return {
    count: selCount,
    loadPending: selLoadPending,
    hasInfinite: selHasInfinite,
    purchasePending: selPurchasePending,
    canPlay: selCanPlay,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
