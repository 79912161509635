import { Router } from 'pouter';

import {
  Pages,
} from 'app/constants';

const createRouter = (store) => {
  const router = new Router();
  router.setContext({store});

  router.use('/', (done, location, _context, preRendered) => {
    if (preRendered) return;
    return done({page: Pages.TYLER_HOME});
  });

  router.use('/browser-not-supported', (done, _location, _context, preRendered) => {
    if (preRendered) return;
    done({page: Pages.BROWSER_NOT_SUPPORTED});
  });

  return router;
};

export default createRouter;
