import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import DeviceDuck from 'app/ducks/device';

class SafeArea extends React.PureComponent {

  render() {
    const {
      children, className, min,
      insetTop, insetRight, insetBottom, insetLeft,
      top, right, bottom, left,
    } = this.props;
    const style = {
      width: '100%',
      height: '100%',
      paddingTop: Math.max((top ? insetTop : 0), min),
      paddingRight: Math.max((right ? insetRight : 0), min),
      paddingBottom: Math.max((bottom ? insetBottom : 0), min),
      paddingLeft: Math.max((left ? insetLeft : 0), min),
    };
    return (
      <div style={style} className={className}>
        {children}
      </div>
    );
  }

}

SafeArea.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  top: PropTypes.bool,
  right: PropTypes.bool,
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  min: PropTypes.number,
};

SafeArea.defaultProps = {
  className: '',
  top: true,
  right: true,
  bottom: true,
  left: true,
  min: 0,
};

const stateToProps = (state) => ({
  insetTop:    DeviceDuck.Slx.safeAreaInsets(state).top,
  insetRight:  DeviceDuck.Slx.safeAreaInsets(state).right,
  insetBottom: DeviceDuck.Slx.safeAreaInsets(state).bottom,
  insetLeft:   DeviceDuck.Slx.safeAreaInsets(state).left,
});

const ConnectedSafeArea = connect(stateToProps)(SafeArea);



class OnlyLongwise extends React.PureComponent {

  render() {
    const {orientation, ownProps} = this.props;
    const isLandScape = orientation.startsWith('landscape');
    const props = {
      top: !isLandScape,
      bottom: !isLandScape,
      left: !!isLandScape,
      right: !!isLandScape,
    };
    return <ConnectedSafeArea {...ownProps} {...props} />;
  }

}

ConnectedSafeArea.OnlyLongwise = connect((state, ownProps) => ({
  orientation: DeviceDuck.Slx.orientation(state),
  ownProps,
}))(OnlyLongwise);


export default ConnectedSafeArea;
