import timm from 'timm';

// from http://redux.js.org/docs/recipes/ReducingBoilerplate.html#generating-reducers
const createReducer = (initialState, handlers) => {
  Object.keys(handlers).forEach((key) => {
    if (key.includes('undefined')) {
      console.error('action type is undefined');
    }
  });
  return (state = initialState, action) => {
    const handler = handlers[action.type];
    return handler ? handler(state, action) : state;
  };
};

/** Accepts an array of objects and returns a new object that maps the `id`
 *  value in each array element to the element as a whole. Pass an options
 *  object through the second parameter with an `idField` property to specify a
 *  mapping key other than `id`. */
const idMap = (objects, {idField = 'id'} = {}) => {
  return objects.reduce((map, object) => {
    map[object[idField]] = object;
    return map;
  }, {});
};

const entityHandlers = (entityNames) => {
  const handlers = {};
  entityNames.forEach((entityName) => {
    handlers[`ENTITY_SET_${entityName.toUpperCase()}`] = (state, {entities}) => {
      if (!entities) return state;
      return Array.isArray(entities)
        // Add all entity elements, each keyed by their `id` value:
        ? timm.mergeDeep(state, idMap(entities))
        // Add the single entity element, keyed by its `id` value:
        : timm.mergeIn(state, [entities.id], entities);
    };
  });
  return handlers;
};

const createEntityReducer = (names, initialState={}, handlers={}) => {
  const combinedHandlers = {
    ...entityHandlers(names),
    ...handlers,
  };
  return createReducer(initialState, combinedHandlers);
};

export default {
  createReducer,
  idMap,
  entityHandlers,
  createEntityReducer,
};
