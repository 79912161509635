import PropTypes from 'prop-types';
import React from 'react';

import Link       from 'app/components/common/link';
import MainLayout from 'app/components/layout/main-layout';
import paths      from 'app/paths';

class PageSignup extends React.PureComponent {

  render() {
    return (
      <MainLayout>
        <div className="widther">
          <h1>Sign Up</h1>
          <Link href={paths.home()}>Home</Link>
          <Link href={paths.login()}>Login</Link>
        </div>
      </MainLayout>
    );
  }

}

PageSignup.propTypes = {
};

PageSignup.defaultProps = {
};

export default PageSignup;
