import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import LayoutMain from 'app/dotsnake/components/layout-main';

class ScrollyPage extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refBody = React.createRef();

    this.state = {
      isScrolled: false,
    };

    this.onScroll = _.throttle(this.onScroll.bind(this), 200);
  }

  componentDidMount() {
    this.refBody.current.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    this.refBody.current.removeEventListener('scroll', this.onScroll);
  }

  onScroll(event) {
    console.log('scroll', this.refBody.current.scrollTop);
    const isScrolled = this.refBody.current.scrollTop > 0;
    this.setState({isScrolled});
  }

  render() {
    const {children, head} = this.props;
    const {isScrolled} = this.state;
    const scrollClass = isScrolled ? 'scrolled' : '';

    return (
      <LayoutMain className={`snk-scrolly-page ${scrollClass}`}>
        <div className="snk-scrolly-page-head">
          {head}
        </div>
        <div className="snk-scrolly-page-body" ref={this.refBody}>
          {children}
        </div>
      </LayoutMain>
    );
  }

}

ScrollyPage.propTypes = {
  head: PropTypes.node,
};

ScrollyPage.defaultProps = {
  head: null,
};

export default ScrollyPage;
