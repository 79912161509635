
const Apps = {
  SNAKE: 'dotsnake',
  BB: 'babybyte',
  TYBRO_APPS: 'tybroapps',
  TYLER: 'tyler',
};

const Pages = {
  NOT_FOUND: '404-not-found',
  FORBIDDEN: '403-forbidden',
  LOGIN: 'login',
  SIGN_UP: 'signup',
  HOME: 'home',
  BROWSER_NOT_SUPPORTED: 'no-ie',
  SNAKE: 'snake',

  TYLER_HOME: 'tyler-home',

  TYBRO_HOME: 'tybro-home',

  SNK_LANDING:            'snk-landing',
  SNK_HOME:               'snk-home',
  SNK_PLAY:               'snk-play',
  SNK_SCORES:             'snk-scores',
  SNK_SETTINGS:           'snk-settings',
  SNK_INSTRUCTIONS:       'snk-instructions',
  SNK_EMAIL_VERIFICATION: 'snk-email-verification',
};

const EmailVerificationReasons = {
  LOGIN: 'login',
};

const DeviceTypes = {
  IOS: 'ios',
  BROWSER: 'browser',
};

export {
  Apps,
  Pages,
  EmailVerificationReasons,
  DeviceTypes,
};
