import React from 'react';

import { Categories } from 'app/constants';

const StreamlineSvg = ({className, size=24, children, fill='none', ...props}) => (
  <svg {...props} viewBox={`0 0 ${size} ${size}`} className={`icon ${className || ''}`}>
    <g fill={fill} stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
      {children}
    </g>
  </svg>
);

const Icon = {

//   AccountingBill: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="2.504" y="7" width="19" height="10" rx="0.5" ry="0.5" />
//       <rect x="0.504" y="5" width="23" height="14" rx="1" ry="1" />
//       <circle cx="12.004" cy="12" r="3" />
//       <path d="M5,9.25a.25.25,0,1,1-.25.25A.25.25,0,0,1,5,9.25" />
//       <path d="M19,14.25a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
//     </StreamlineSvg>
//   ),
// 
//   AccountingCalc1: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="3.004" y="0.5" width="18" height="23" rx="2.5" ry="2.5" />
//       <line x1="3.004" y1="15.5" x2="21.004" y2="15.5" />
//       <line x1="3.004" y1="8.5" x2="21.004" y2="8.5" />
//       <line x1="12.004" y1="8.5" x2="12.004" y2="23.5" />
//       <line x1="5.504" y1="12.5" x2="8.504" y2="12.5" />
//       <line x1="15.004" y1="12.5" x2="18.004" y2="12.5" />
//       <line x1="15.004" y1="20.5" x2="18.004" y2="20.5" />
//       <line x1="15.004" y1="18.5" x2="18.004" y2="18.5" />
//       <line x1="7.004" y1="11" x2="7.004" y2="14" />
//       <line x1="5.504" y1="18" x2="8.504" y2="21" />
//       <line x1="8.504" y1="18" x2="5.504" y2="21" />
//       <line x1="17.005" y1="5.5" x2="17.005" y2="3.5" />
//     </StreamlineSvg>
//   ),
// 
//   AddCircle: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="12" cy="12" r="11" />
//       <line x1="12" y1="6" x2="12" y2="18" />
//       <line x1="18" y1="12" x2="6" y2="12" />
//     </StreamlineSvg>
//   ),

  AlertCircle: (props) => (
    <StreamlineSvg {...props}>
      <path d="M23,11.811A11.162,11.162,0,0,1,12,23,10.837,10.837,0,0,1,1,12.192,11.162,11.162,0,0,1,12,1,10.838,10.838,0,0,1,23,11.811Z" />
      <line x1="12" y1="14.005" x2="12" y2="7.005" />
      <path d="M11.991,16.005a.245.245,0,0,0-.241.255.254.254,0,0,0,.253.245h0a.246.246,0,0,0,.241-.255A.253.253,0,0,0,12,16.005h-.005" />
    </StreamlineSvg>
  ),

//   AnalyticsPie2: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M13.5,10a.5.5,0,0,0,.5.5h9.5a10,10,0,0,0-10-10Z" />
//       <path d="M20.575,19.571A9.965,9.965,0,0,0,23.5,12.5H14c-.275,0-.341.159-.147.353Z" />
//       <path d="M10.5,3.5a10,10,0,1,0,7.071,17.071l-6.718-6.718A1.391,1.391,0,0,1,10.5,13Z" />
//     </StreamlineSvg>
//   ),
// 
//   ArrowButtonRight: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M19.342,12.376l-12.47,10.8a.5.5,0,0,1-.707-.052L4.606,21.3a.5.5,0,0,1,.056-.706l9.892-8.4a.249.249,0,0,0,0-.381l-9.892-8.4A.5.5,0,0,1,4.606,2.7L6.165.881A.5.5,0,0,1,6.872.829L19.342,11.62A.5.5,0,0,1,19.342,12.376Z" />
//     </StreamlineSvg>
//   ),
// 
//   AthleticsJumpingPerson: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="14" cy="3" r="2.5" />
//       <path d="M22.561,6.439a1.5,1.5,0,0,0-2.121,0L18.731,8.148a1.5,1.5,0,0,1-1.516.368L9.548,6.077a4.477,4.477,0,0,0-4.294.872L3.024,8.861a1.5,1.5,0,1,0,1.953,2.277l2.23-1.912a1.48,1.48,0,0,1,1.431-.29l1.986.632L8.021,15.5H2.5a1.5,1.5,0,0,0,0,3H9a1.5,1.5,0,0,0,1.374-.9l.724-1.649,3.5-.318a1.493,1.493,0,0,1,1.275.518l1.992,2.324a1.5,1.5,0,0,0,2.277-1.953L18.146,14.2a4.492,4.492,0,0,0-3.824-1.554l-1.847.168L13.5,10.484l2.807.893a4.491,4.491,0,0,0,4.546-1.106L22.56,8.562A1.5,1.5,0,0,0,22.561,6.439Z" />
//       <line x1="13" y1="18" x2="13" y2="23.5" />
//       <line x1="11" y1="23.5" x2="15" y2="23.5" />
//     </StreamlineSvg>
//   ),
// 
//   Bin: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M20.5,3.5v19a1,1,0,0,1-1,1H4.5a1,1,0,0,1-1-1V3.5" />
//       <path d="M7.5,3.5v-2a1,1,0,0,1,1-1h7a1,1,0,0,1,1,1v2" />
//       <line x1="0.5" y1="3.5" x2="23.5" y2="3.5" />
//       <line x1="7.5" y1="7" x2="7.5" y2="19" />
//       <line x1="12" y1="7" x2="12" y2="19" />
//       <line x1="16.5" y1="7" x2="16.5" y2="19" />
//     </StreamlineSvg>
//   ),
// 
//   Bracket: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M1,2.765 L4.64606291,2.765 C4.94778469,2.76500896 5.19237852,3.00960279 5.19237852,3.31132457 C5.19237852,3.31132457 5.19237852,3.31132457 5.19237852,3.31132457 L5.19237852,9.10681425 C5.19470995,9.2991979 5.13934472,9.48751728 5.03522404,9.64928981 C4.94171416,9.79457632 4.84185369,9.86721958 4.73564263,9.86721958 L1.02651218,9.86721958"  />
//       <path d="M1,14.1285864 L4.64606291,14.1285864 C4.94778469,14.1285955 5.19237852,14.3731893 5.19237852,14.6749111 C5.19237852,14.6749111 5.19237852,14.6749111 5.19237852,14.6749111 L5.19237852,20.4704022 C5.1947099,20.6627834 5.13934606,20.8511005 5.03522786,21.0128717 C4.94171688,21.1581622 4.84185513,21.2308074 4.73564263,21.2308074 L1.02651218,21.2308074"  />
//       <path d="M5.5625,5.54753174 L9.12502582,5.54753174 C9.63725567,5.54756207 10.0525,5.96280641 10.0525,6.47503625 C10.0525,6.47503625 10.0525,6.47503625 10.0525,6.47503625 L10.0525,16.5560327 C10.0525305,16.6405511 10.041816,16.7247284 10.0206432,16.8065518 C9.88288867,17.3389111 9.73045438,17.6050908 9.56334036,17.6050908 L5.5908943,17.6050908"  />
//       <path d="M18.8101215,2.765 L22.4561844,2.765 C22.7579062,2.76500896 23.0025,3.00960279 23.0025,3.31132457 C23.0025,3.31132457 23.0025,3.31132457 23.0025,3.31132457 L23.0025,9.10681425 C23.0048314,9.2991979 22.9494662,9.48751728 22.8453455,9.64928981 C22.7518356,9.79457632 22.6519752,9.86721958 22.5457641,9.86721958 L18.8366337,9.86721958" transform="translate(20.906311, 6.316110) scale(-1, 1) translate(-20.906311, -6.316110)" />
//       <path d="M18.8101215,14.1285864 L22.4561844,14.1285864 C22.7579062,14.1285955 23.0025,14.3731893 23.0025,14.6749111 C23.0025,14.6749111 23.0025,14.6749111 23.0025,14.6749111 L23.0025,20.4704022 C23.0048314,20.6627834 22.9494675,20.8511005 22.8453493,21.0128717 C22.7518384,21.1581622 22.6519766,21.2308074 22.5457641,21.2308074 L18.8366337,21.2308074" transform="translate(20.906311, 17.679697) scale(-1, 1) translate(-20.906311, -17.679697)" />
//       <path d="M13.95,5.54753174 L17.5125258,5.54753174 C18.0247557,5.54756207 18.44,5.96280641 18.44,6.47503625 C18.44,6.47503625 18.44,6.47503625 18.44,6.47503625 L18.44,16.5560327 C18.4400305,16.6405511 18.429316,16.7247284 18.4081432,16.8065518 C18.2703887,17.3389111 18.1179544,17.6050908 17.9508404,17.6050908 L13.9783943,17.6050908" transform="translate(16.195000, 11.576311) scale(-1, 1) translate(-16.195000, -11.576311)" />
//       <path d="M10.5,11.877755 L13.69,11.877755" />
//     </StreamlineSvg>
//   ),
// 
//   BrowserPageText: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.5" y="0.5" width="23" height="23" rx="2" ry="2" />
//       <line x1="0.5" y1="5.5" x2="23.5" y2="5.5" />
//       <path d="M4,2.75A.25.25,0,1,1,3.75,3,.25.25,0,0,1,4,2.75" />
//       <path d="M7,2.75A.25.25,0,1,1,6.75,3,.25.25,0,0,1,7,2.75" />
//       <path d="M10,2.75A.25.25,0,1,1,9.75,3,.25.25,0,0,1,10,2.75" />
//       <rect x="3.5" y="8.5" width="17" height="3" rx="0.5" ry="0.5" />
//       <rect x="3.5" y="13.5" width="3" height="7" rx="0.5" ry="0.5" />
//       <line x1="9.5" y1="14.5" x2="19.5" y2="14.5" />
//       <line x1="9.5" y1="16.5" x2="19.5" y2="16.5" />
//       <line x1="9.5" y1="18.5" x2="19.5" y2="18.5" />
//       <line x1="9.5" y1="20.5" x2="16.5" y2="20.5" />
//     </StreamlineSvg>
//   ),
// 
//   BuildingDaylight: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M7.5,23.5H15a.5.5,0,0,0,.5-.5V11a.5.5,0,0,0-.5-.5H8a.5.5,0,0,0-.5.5Z" />
//       <path d="M9.5,10.5V5A.5.5,0,0,0,9,4.5H1A.5.5,0,0,0,.5,5V23a.5.5,0,0,0,.5.5H7.5" />
//       <path d="M2.5,23.5V21a.5.5,0,0,1,.5-.5H5a.5.5,0,0,1,.5.5v2.5" />
//       <path d="M10.5,23.5V21a.5.5,0,0,1,.5-.5h2a.5.5,0,0,1,.5.5v2.5" />
//       <line x1="15.5" y1="12.5" x2="12.5" y2="12.5" />
//       <line x1="9.5" y1="8.5" x2="6.5" y2="8.5" />
//       <line x1="9.5" y1="6.5" x2="4.5" y2="6.5" />
//       <line x1="15.5" y1="14.5" x2="13.5" y2="14.5" />
//       <circle cx="18.502" cy="5.5" r="2" />
//       <line x1="18.502" y1="0.5" x2="18.502" y2="1.5" />
//       <line x1="14.967" y1="1.964" x2="15.674" y2="2.671" />
//       <line x1="13.502" y1="5.498" x2="14.502" y2="5.498" />
//       <line x1="14.966" y1="9.035" x2="15.673" y2="8.327" />
//       <line x1="18.501" y1="10.498" x2="18.5" y2="9.499" />
//       <line x1="22.036" y1="9.035" x2="21.328" y2="8.329" />
//       <line x1="23.5" y1="5.501" x2="22.5" y2="5.502" />
//       <line x1="22.036" y1="1.966" x2="21.33" y2="2.674" />
//     </StreamlineSvg>
//   ),
// 
//   Buildings1: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M9.5,7A.5.5,0,0,0,9,6.5H1A.5.5,0,0,0,.5,7V23a.5.5,0,0,0,.5.5H9.5Z" />
//       <path d="M1.5,6.5V4A.5.5,0,0,1,2,3.5H8a.5.5,0,0,1,.5.5V6.5" />
//       <line x1="4.5" y1="3.5" x2="4.5" y2="0.5" />
//       <path d="M23.5,23a.5.5,0,0,1-.5.5H16.5V10a.5.5,0,0,1,.5-.5h6a.5.5,0,0,1,.5.5Z" />
//       <path d="M17.5,9.5V8a.5.5,0,0,1,.5-.5h4a.5.5,0,0,1,.5.5V9.5" />
//       <line x1="9.5" y1="12.5" x2="16.5" y2="12.5" />
//       <line x1="9.5" y1="23.5" x2="16.5" y2="23.5" />
//       <line x1="2.5" y1="9.5" x2="7.5" y2="9.5" />
//       <line x1="2.5" y1="12.5" x2="7.5" y2="12.5" />
//       <line x1="2.5" y1="15.5" x2="7.5" y2="15.5" />
//       <line x1="18.5" y1="12.5" x2="21.5" y2="12.5" />
//       <line x1="18.5" y1="15.5" x2="21.5" y2="15.5" />
//       <line x1="2.5" y1="18.5" x2="7.5" y2="18.5" />
//       <line x1="18.5" y1="18.5" x2="21.5" y2="18.5" />
//       <line x1="11.5" y1="15.5" x2="14.5" y2="15.5" />
//       <line x1="11.5" y1="18.5" x2="14.5" y2="18.5" />
//       <path d="M5.5,23.5V21a.5.5,0,0,0-.5-.5H4a.5.5,0,0,0-.5.5v2.5" />
//       <path d="M19.5,23.5V21a.5.5,0,0,1,.5-.5h1a.5.5,0,0,1,.5.5v2.5" />
//     </StreamlineSvg>
//   ),
// 
//   Calendar: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.5" y="2.501" width="23" height="21" rx="1" ry="1" />
//       <line x1="5.5" y1="0.501" x2="5.5" y2="5.501" />
//       <line x1="18.5" y1="0.501" x2="18.5" y2="5.501" />
//       <line x1="0.5" y1="7.501" x2="23.5" y2="7.501" />
//     </StreamlineSvg>
//   ),
// 
//   CalendarCheck1: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="17.5" cy="17.501" r="6" />
//       <path d="M20.174,15.756,17.269,19.63a.751.751,0,0,1-1.131.08l-1.5-1.5" />
//       <path d="M9.5,18.5h-8a1,1,0,0,1-1-1V3.5a1,1,0,0,1,1-1h18a1,1,0,0,1,1,1V10" />
//       <line x1="5.5" y1="0.501" x2="5.5" y2="5.501" />
//       <line x1="15.5" y1="0.501" x2="15.5" y2="5.501" />
//       <line x1="0.5" y1="7.501" x2="20.5" y2="7.501" />
//     </StreamlineSvg>
//   ),
// 
//   CalendarDate: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.5" y="2.501" width="23" height="21" rx="1" ry="1" />
//       <line x1="5.5" y1="0.501" x2="5.5" y2="5.501" />
//       <line x1="18.5" y1="0.501" x2="18.5" y2="5.501" />
//       <line x1="0.5" y1="7.501" x2="23.5" y2="7.501" />
//       <line x1="6.5" y1="19.001" x2="11.5" y2="19.001" />
//       <path d="M9,19V11.5a.5.5,0,0,0-.812-.391L6.5,12.46" />
//       <path d="M15.5,19l2.931-7.255a.469.469,0,0,0-.005-.5A.546.546,0,0,0,17.962,11H14a.5.5,0,0,0-.5.5v1" />
//     </StreamlineSvg>
//   ),
// 
//   CalendarWarning: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M17.5,20.5a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
//       <line x1="17.5" y1="18.501" x2="17.5" y2="15.501" />
//       <path d="M18.338,12.5a.95.95,0,0,0-1.676,0l-5.056,9.634a.925.925,0,0,0,.031.915.948.948,0,0,0,.807.448H22.556a.948.948,0,0,0,.807-.448.925.925,0,0,0,.031-.915Z" />
//       <path d="M9.5,18.5h-8a1,1,0,0,1-1-1V3.5a1,1,0,0,1,1-1h18a1,1,0,0,1,1,1V10" />
//       <line x1="5.5" y1="0.501" x2="5.5" y2="5.501" />
//       <line x1="15.5" y1="0.501" x2="15.5" y2="5.501" />
//       <line x1="0.5" y1="7.501" x2="20.5" y2="7.501" />
//     </StreamlineSvg>
//   ),

  Caret: ({direction='up', ...props}) => {
    const className = `caret ${direction} ${props.className || ''}`;
    return (
      <StreamlineSvg size={12} {...props} className={className}>
        <polyline points="1 3.5 6 8.5 11 3.5" />
      </StreamlineSvg>
    );
  },

//   CatAnimals: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M8.166,3.084A5.251,5.251,0,0,0,5.06,2.6,5.446,5.446,0,0,0,.5,8.049v5.515a7.454,7.454,0,0,0,2.5,5.5l1.683-1A5.181,5.181,0,0,1,3.5,14.515V12.433a7.181,7.181,0,0,0,3.507.758l.27,1.349A16.488,16.488,0,0,1,7.5,17.789v4.275h3v-6h1" />
//       <path d="M23.5,12.564V10.988a4.785,4.785,0,0,0-4.633-4.924H14.623" />
//       <path d="M7.267,4.431C8.451,2.064,9.67,2.119,12,2.064c3.455-.081,3.105,2,2.5,4.514-.484,2.019-1.285,3.769-3.613,3.769" />
//       <path d="M4.5,6.814a.25.25,0,0,1,.25.25" />
//       <path d="M4.25,7.064a.25.25,0,0,1,.25-.25" />
//       <path d="M4.5,7.314a.249.249,0,0,1-.25-.25" />
//       <path d="M4.75,7.064a.249.249,0,0,1-.25.25" />
//       <path d="M23.5,19.564V17a3.221,3.221,0,0,0-3.25-2.936" />
//       <path d="M17.5,19.064a1.6,1.6,0,0,0-1.5-1.5h-.5" />
//       <path d="M17.5,13.066a2.512,2.512,0,0,0-2.235-.4,2.456,2.456,0,0,0-1.671,1.7,2.658,2.658,0,0,0-.092.619v5.072a.979.979,0,0,0,1,1" />
//       <line x1="17.5" y1="19.064" x2="17.5" y2="22.064" />
//       <path d="M18,16.064a1.1,1.1,0,0,0,1.5.5c1-.5,1-4,0-4a5.172,5.172,0,0,0-2,.5" />
//       <polyline points="19.5 22.064 19.5 19.564 23.5 19.564 23.5 22.064" />
//     </StreamlineSvg>
//   ),
// 
//   CatCivilRights: (props) => (
//     <StreamlineSvg {...props}>
//       <line x1="12" y1="6" x2="12" y2="21" />
//       <line x1="9.081" y1="21" x2="14.892" y2="21" />
//       <polyline points="1.001 11.998 4.5 4 7.987 11.998" />
//       <line x1="10.585" y1="4" x2="3" y2="4" />
//       <path d="M8,12a3.5,3.5,0,0,1-7,0Z" />
//       <polyline points="22.999 11.998 19.5 4 16.013 11.998" />
//       <line x1="13.415" y1="4" x2="21" y2="4" />
//       <path d="M16,12a3.5,3.5,0,0,0,7,0Z" />
//       <circle cx="12" cy="4.5" r="1.5" />
//     </StreamlineSvg>
//   ),
// 
//   CatCommunityDev: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M15.5,10 C13.8431458,10 12.5,11.4774603 12.5,13.3 L12.5,16.05 L14,16.05 L14.5,21 L16.5,21 L17,16.05 L18.5,16.05 L18.5,13.3 C18.5,11.4774603 17.1568542,10 15.5,10 Z" />
//       <path d="M19.0031792,7.89350358 C20.3455816,8.26552794 21.7747648,7.63487075 22.3010179,6.43826287 C22.8272709,5.24165499 22.2774313,3.87283776 21.0322636,3.27971871 C19.7870959,2.68659967 18.25632,3.06434342 17.5,4.15136196" />
//       <path d="M19.2557692,9.12417687 C19.5424688,9.04133911 19.8379284,8.99959304 20.1346154,9 C21.993266,9 23.5,10.6351365 23.5,12.652176 L23.5,15.78261 L21.9778846,15.78261 L21.5769231,21 L18.6923077,21 L18.5,18.4956528" />
//       <path d="M8.5,11 C10.1568542,11 11.5,12.4923842 11.5,14.3333333 L11.5,17.1111111 L10,17.1111111 L9.5,21 L7.5,21 L7,17.1111111 L5.5,17.1111111 L5.5,14.3333333 C5.5,12.4923842 6.84314575,11 8.5,11 Z" />
//       <path d="M3.99682075,7.89350358 C2.65441842,8.26552794 1.22523519,7.63487075 0.698982135,6.43826287 C0.172729084,5.24165499 0.722568689,3.87283776 1.96773637,3.27971871 C3.21290406,2.68659967 4.74367998,3.06434342 5.5,4.15136196" />
//       <path d="M4.82236584,9.12417687 C4.53038827,9.04133911 4.22948932,8.99959304 3.92734038,9 C2.03447256,9 0.5,10.6351365 0.5,12.652176 L0.5,15.78261 L2.05013709,15.78261 L2.45848022,21 L5.39620055,21 L5.5,19.6674786" />
//       <circle cx="8.5" cy="8" r="2" />
//       <circle cx="15.5" cy="7" r="2" />
//     </StreamlineSvg>
//   ),
// 
//   CatCulture: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="1.5" y="0.5" width="21" height="11" rx="1" ry="1" />
//       <rect x="3.5" y="2.5" width="17" height="7" rx="1" ry="1" />
//       <line x1="1.5" y1="23.5" x2="1.5" y2="16.5" />
//       <circle cx="1.5" cy="15.5" r="1" />
//       <line x1="12" y1="23.5" x2="12" y2="16.5" />
//       <circle cx="12" cy="15.5" r="1" />
//       <line x1="22.5" y1="23.5" x2="22.5" y2="16.5" />
//       <circle cx="22.5" cy="15.5" r="1" />
//       <path d="M7.5,9.5s1.3-3.243,2.919-3.243S13.338,8.2,13.338,8.2l1.955-3.352a1.088,1.088,0,0,1,1.913.062L19.447,9.4" />
//       <path d="M6.75,4.5A.25.25,0,0,1,7,4.75" />
//       <path d="M6.5,4.75a.25.25,0,0,1,.25-.25" />
//       <path d="M6.75,5a.25.25,0,0,1-.25-.25" />
//       <path d="M7,4.75A.25.25,0,0,1,6.75,5" />
//       <path d="M22.5,16.5c0,2.209-2.351,4-5.25,4S12,18.709,12,16.5c0,2.209-2.351,4-5.25,4s-5.25-1.791-5.25-4" />
//     </StreamlineSvg>
//   ),
// 
//   CatEducation: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M12,14.413c.881,0,.294.587,2.055.587s3.521-3.521,3.521-5.869-1.468-4.109-3.228-4.109-1.761.586-2.348.586c-.5,0-.979-.437-1.935-.57a.515.515,0,0,0-.58.484c-.171,2.945-1.789,3.5-2.6,3.6a.485.485,0,0,0-.435.519C6.67,11.955,8.308,15,9.945,15,11.707,15,11.119,14.413,12,14.413Z" />
//       <path d="M12,5.608V4.174A1.177,1.177,0,0,0,10.826,3H9.652" />
//       <path d="M13.358,3.642A2.642,2.642,0,0,0,16,1,2.643,2.643,0,0,0,13.358,3.642Z" />
//       <path d="M.5,23h5a2,2,0,0,0,2-2V19a2,2,0,0,0-2-2H.5" />
//       <path d="M5.5,23H22c1.1,0,1.5-.895,1.5-2V19c0-1.1-.4-2-1.5-2H5.5" />
//       <line x1="19.5" y1="17" x2="19.5" y2="23" />
//       <line x1="17.5" y1="17" x2="17.5" y2="23" />
//       <line x1="13.5" y1="17" x2="13.5" y2="23" />
//       <line x1="11.5" y1="17" x2="11.5" y2="23" />
//       <line x1="5.5" y1="21" x2="1.5" y2="21" />
//       <line x1="5.5" y1="19" x2="1.5" y2="19" />
//     </StreamlineSvg>
//   ),
// 
//   CatEnvironment: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.5" y="14.528" width="4" height="8" rx="0.5" ry="0.5" />
//       <path d="M4.5,21.028c10.729,3.577,6.772,3.614,19-2.5a2.763,2.763,0,0,0-3-1L16.066,19" />
//       <path d="M4.5,15.528h3a6.59,6.59,0,0,1,4.5,2h3a1.016,1.016,0,1,1,0,2H9.5" />
//       <path d="M6.852,12.124a8,8,0,1,1,11.24,3.281" />
//       <path d="M19.914,3.141a3.975,3.975,0,0,1-1.74,2.606c-1.391-.348-2.782.694-2.435.694s.7,1.392.7,1.392c.348.348,1.044,0,1.044,0,.869.869-1.392,2.436-1.392,2.783s.871.522.348,1.044a2.983,2.983,0,0,1-1.043.7c0,1.739-1.74,1.739-2.087,1.739s-1.044-1.392-1.044-1.739.349-.7.349-1.043-.7-1.043-.7-1.043c0-1.392-1.043-1.044-2.088-1.044S8.783,7.833,8.783,7.833s0-2.434,1.74-2.781,1.738.347,1.738.347c.7.7,2.087,0,2.783,0,0,0,.348-2.435-.348-2.087s-1.319.078-1.391-.7C13.2,1.528,16.34.877,16.34.877" />
//     </StreamlineSvg>
//   ),
// 
//   CatHealth: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M15.256,9.453c1.95.457,3.262,1.314,3.262,2.3,0,1.507-1.119,2.157-2.6,2.437A22.365,22.365,0,0,1,12,14.4a13.408,13.408,0,0,0-3.224.3c-1.249.323-2.072.956-2.072,2.138,0,2.037,2.37,2.445,5.3,2.445,0,0,3.666,0,2.443,3.259" />
//       <path d="M17.051,15.857a1.931,1.931,0,0,1,.244.981c0,2.037-2.371,2.445-5.3,2.445,0,0-3.666,0-2.444,3.259" />
//       <path d="M8.743,9.453c-1.95.457-3.261,1.314-3.261,2.3a2.1,2.1,0,0,0,1.211,1.987" />
//       <path d="M10.706,12.5C10.321,7.361,10,2.939,10,2.5a2,2,0,0,1,4,0c0,.442-.321,4.864-.706,10.006" />
//       <path d="M12.993,16.5c-.173,2.288-.346,4.555-.5,6.54a.5.5,0,0,1-.991,0c-.152-1.985-.324-4.252-.5-6.54" />
//       <path d="M10.029,3.089C7.391-.1,3.444,3.6.868,4.351a.51.51,0,0,0-.348.34.49.49,0,0,0,.107.467C2.134,6.827,6.261,8.171,10.32,7.28" />
//       <path d="M13.971,3.089C16.609-.1,20.556,3.6,23.132,4.351a.51.51,0,0,1,.348.34.49.49,0,0,1-.107.467C21.866,6.827,17.739,8.171,13.68,7.28" />
//     </StreamlineSvg>
//   ),
// 
//   CatHumanServices: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.5" y="15.5" width="4" height="7" rx="0.5" ry="0.5" />
//       <path d="M4.5,21c10.528,3.51,7.3,3.611,18.723-2.164a.511.511,0,0,0,.084-.854A2.483,2.483,0,0,0,20.89,17.5l-4.955,1.644" />
//       <path d="M9.5,20.5H15a1,1,0,0,0,0-2H12a6.59,6.59,0,0,0-4.5-2h-3" />
//       <rect x="19.5" y="1.5" width="4" height="7" rx="0.5" ry="0.5" />
//       <path d="M19.5,3C8.972-.51,12.2-.611.777,5.164a.511.511,0,0,0-.084.854A2.483,2.483,0,0,0,3.11,6.5L8.065,4.856" />
//       <path d="M14.5,3.5H9a1,1,0,0,0,0,2h3a6.59,6.59,0,0,0,4.5,2h3" />
//       <path d="M12,10.205c-.856-1.34-3.427-.861-3.427,1.531.045,1.826,2.423,3.28,3.191,3.7a.489.489,0,0,0,.472,0c.768-.423,3.146-1.877,3.191-3.7C15.427,9.344,12.857,8.865,12,10.205Z" />
//     </StreamlineSvg>
//   ),
// 
//   CatInternational: (props) => (
//     <StreamlineSvg {...props}>
//       <line x1="1.897" y1="17.5" x2="9.5" y2="17.501" />
//       <line x1="2.51" y1="5.5" x2="21.495" y2="5.5" />
//       <line x1="10.5" y1="11.501" x2="0.509" y2="11.5" />
//       <path d="M11.377,23.484A11.5,11.5,0,1,1,23.412,10.5" />
//       <path d="M11.268.526c-6,6.5-5.891,14.958.109,22.958" />
//       <path d="M12.729.526A15.837,15.837,0,0,1,17.153,10.5" />
//       <path d="M17.5,23.5l-5.114-5.335a3.025,3.025,0,0,1-.567-3.492h0a3.026,3.026,0,0,1,4.846-.786l.835.835.835-.835a3.026,3.026,0,0,1,4.846.786h0a3.025,3.025,0,0,1-.567,3.492Z" />
//     </StreamlineSvg>
//   ),
// 
//   CatPublicPolicy: (props) => (
//     <StreamlineSvg {...props}>
//       <line x1="8.032" y1="17" x2="15.508" y2="17" />
//       <line x1="18.508" y1="21" x2="14.347" y2="21" />
//       <line x1="12.032" y1="5" x2="14.032" y2="5" />
//       <line x1="12.282" y1="11" x2="18.032" y2="11" />
//       <line x1="12.282" y1="8" x2="18.032" y2="8" />
//       <line x1="11.532" y1="14" x2="17.532" y2="14" />
//       <line x1="10.057" y1="1" x2="22.532" y2="1" />
//       <path d="M10.057,1c-1,0-1.025,1.5-1.025,1.5s.112,8.949-1,14.5h-4.5c-2.5,0,.5,6-3,6H12.508c3.5,0,.5-6,3-6s-.5,4,3,4,3-18.5,3-18.5S21.532,1,22.532,1c.586,0,.829,1.03.929,1.883A1,1,0,0,1,22.467,4h-.935" />
//     </StreamlineSvg>
//   ),
// 
//   CatReligion: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="11.251" cy="3.75" r="2.5" />
//       <path d="M18.251,22.75h-7a1.5,1.5,0,0,1-.949-2.662l4.726-3.859-1.6-5.086L9.6,13.8a2.5,2.5,0,0,1-3.651-.92L4.481,10.551a1.5,1.5,0,0,1,2.539-1.6l1.307,2.075L13.4,7.518a1.5,1.5,0,0,1,2.284.784L18,15.672a2.5,2.5,0,0,1-.791,2.652l-1.747,1.427H18.25a1.5,1.5,0,1,1,0,3Z" />
//       <line x1="15.751" y1="19.75" x2="14.251" y2="19.75" />
//     </StreamlineSvg>
//   ),
// 
//   CatOther: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M12.359,21.671a.5.5,0,0,1-.718,0L2.2,11.949A5.669,5.669,0,0,1,1.112,5.342h0A5.84,5.84,0,0,1,10.4,3.854L12,5.313l1.6-1.459a5.839,5.839,0,0,1,9.287,1.488h0A5.671,5.671,0,0,1,21.8,11.949Z" />
//     </StreamlineSvg>
//   ),
// 
//   CheckCircle: (props) => (
//     <StreamlineSvg {...props}>
//       <polyline points="23.5 0.499 9 18.499 4 13.499" />
//       <path d="M18.273,12.493A8.993,8.993,0,1,1,13.5,6.435" />
//     </StreamlineSvg>
//   ),
// 
//   CheckCircle1: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="12" cy="11.999" r="11.5" />
//       <polyline points="18 6.999 11 16.499 6 12.499" />
//     </StreamlineSvg>
//   ),

  Cog1: (props) => (
    <StreamlineSvg {...props}>
      <path d="M19.922,7.213a1.874,1.874,0,0,0,1.065,2.571l1.265.45a1.875,1.875,0,0,1,0,3.534l-1.265.45a1.874,1.874,0,0,0-1.065,2.571L20.5,18A1.875,1.875,0,0,1,18,20.5l-1.213-.576a1.874,1.874,0,0,0-2.571,1.065l-.45,1.265a1.875,1.875,0,0,1-3.533,0l-.45-1.265a1.875,1.875,0,0,0-2.572-1.065L6,20.5A1.874,1.874,0,0,1,3.5,18l.576-1.213a1.874,1.874,0,0,0-1.065-2.571l-1.265-.45a1.875,1.875,0,0,1,0-3.534l1.265-.45A1.874,1.874,0,0,0,4.076,7.213L3.5,6A1.874,1.874,0,0,1,6,3.5l1.213.576A1.875,1.875,0,0,0,9.783,3.013l.45-1.265a1.875,1.875,0,0,1,3.533,0l.45,1.265a1.874,1.874,0,0,0,2.571,1.065L18,3.5A1.875,1.875,0,0,1,20.5,6Z" />
      <circle cx="11.999" cy="12.001" r="4.5" />
    </StreamlineSvg>
  ),

  CommonFileTextUpload: (props) => (
    <StreamlineSvg {...props}>
      <line x1="4.5" y1="8.5" x2="14" y2="8.5" />
      <line x1="4.5" y1="11.5" x2="10.5" y2="11.5" />
      <line x1="4.5" y1="5.5" x2="11.5" y2="5.5" />
      <line x1="4.5" y1="14.5" x2="8.5" y2="14.5" />
      <line x1="4.5" y1="17.5" x2="8.5" y2="17.5" />
      <path d="M10.5,23.5h-9a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H14.793A1,1,0,0,1,15.5.793L19.207,4.5a1,1,0,0,1,.293.707V8.5" />
      <circle cx="17.5" cy="17.5" r="6" />
      <line x1="17.5" y1="20.5" x2="17.5" y2="14.5" />
      <line x1="17.5" y1="14.5" x2="15.25" y2="16.75" />
      <line x1="17.5" y1="14.5" x2="19.75" y2="16.75" />
    </StreamlineSvg>
  ),

  CommonFileTextDownload: (props) => (
    <StreamlineSvg {...props}>
      <line x1="4.5" y1="8.5" x2="14" y2="8.5" />
      <line x1="4.5" y1="11.5" x2="10.5" y2="11.5" />
      <line x1="4.5" y1="5.5" x2="11.5" y2="5.5" />
      <line x1="4.5" y1="14.5" x2="8.5" y2="14.5" />
      <line x1="4.5" y1="17.5" x2="8.5" y2="17.5" />
      <path d="M10.5,23.5h-9a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H14.793A1,1,0,0,1,15.5.793L19.207,4.5a1,1,0,0,1,.293.707V8.5" />
      <circle cx="17.5" cy="17.5" r="6" />
      <line x1="17.5" y1="14.5" x2="17.5" y2="20.5" />
      <line x1="17.5" y1="20.5" x2="15.25" y2="18.25" />
      <line x1="17.5" y1="20.5" x2="19.75" y2="18.25" />
    </StreamlineSvg>
  ),

//   ConcertRock: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M12.49,7.5a1,1,0,0,0-1,1V12a1.5,1.5,0,0,0,3,0V8.5a1,1,0,0,0-1-1Z" />
//       <path d="M15.49,7.5a1,1,0,0,0-1,1V12a1.5,1.5,0,1,0,3,0V8.5a1,1,0,0,0-1-1Z" />
//       <path d="M18.49,19.5v-.526a1,1,0,0,1,.336-.747A4.954,4.954,0,0,0,20.49,14.5V5a1.5,1.5,0,0,0-3,0V9.5" />
//       <path d="M11.49,8.5V2a1.5,1.5,0,0,0-3,0V12l-3-3.1a1.2,1.2,0,0,0-1.76,1.6s2.481,3.969,4.2,6.679A4.931,4.931,0,0,0,9.1,18.445a1,1,0,0,1,.391.795v.26" />
//       <rect x="8.49" y="19.5" width="11" height="4" rx="1" ry="1" />
//       <path d="M10.74,21.25a.25.25,0,0,1,.25.25" />
//       <path d="M10.49,21.5a.25.25,0,0,1,.25-.25" />
//       <path d="M10.74,21.75a.25.25,0,0,1-.25-.25" />
//       <path d="M10.99,21.5a.25.25,0,0,1-.25.25" />
//       <path d="M13.99,21.25a.25.25,0,0,1,.25.25" />
//       <path d="M13.74,21.5a.25.25,0,0,1,.25-.25" />
//       <path d="M13.99,21.75a.25.25,0,0,1-.25-.25" />
//       <path d="M14.24,21.5a.25.25,0,0,1-.25.25" />
//       <path d="M17.24,21.25a.25.25,0,0,1,.25.25" />
//       <path d="M16.99,21.5a.25.25,0,0,1,.25-.25" />
//       <path d="M17.24,21.75a.25.25,0,0,1-.25-.25" />
//       <path d="M17.49,21.5a.25.25,0,0,1-.25.25" />
//     </StreamlineSvg>
//   ),
// 
//   ContentPenWrite3: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M12.916,16.872a2.473,2.473,0,1,1,4.571,1.88c-.519,1.263-4.167,3.631-4.167,3.631S12.4,18.134,12.916,16.872Z" />
//       <line x1="14.843" y1="18.684" x2="13.32" y2="22.383" />
//       <rect x="13.698" y="9.747" width="7.996" height="4" transform="translate(0.098 23.64) rotate(-67.628)" />
//       <path d="M19.651,1.739a2,2,0,0,0-2.61,1.089L14,10.225" />
//       <path d="M19.651,1.74a2,2,0,1,1,3.7,1.521L21.067,8.81l-3.7-1.521Z" />
//       <path d="M.5,23.174a4.392,4.392,0,0,1,2.933-1.1c1.758,0,4.864,2.847,7.7.5" />
//     </StreamlineSvg>
//   ),
// 
//   CreditCardAmex: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.504" y="3.5" width="23" height="17" rx="2" ry="2" />
//       <path d="M4.5,14.5v-4a1,1,0,0,1,2,0v4" />
//       <line x1="4.504" y1="12.5" x2="6.504" y2="12.5" />
//       <polyline points="11.504 14.5 11.504 9.5 10.004 12 8.504 9.5 8.504 14.5" />
//       <line x1="17.504" y1="9.5" x2="19.504" y2="14.5" />
//       <line x1="19.504" y1="9.5" x2="17.504" y2="14.5" />
//       <path d="M15.5,14.5h-1a1,1,0,0,1-1-1v-3a1,1,0,0,1,1-1h1" />
//       <line x1="13.504" y1="12.5" x2="15.504" y2="12.5" />
//     </StreamlineSvg>
//   ),
// 
//   CreditCardGeneric: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.504" y="4" width="23" height="16" rx="2" ry="2" />
//       <line x1="0.504" y1="8" x2="23.504" y2="8" />
//       <line x1="20.504" y1="12" x2="17.504" y2="12" />
//       <line x1="11.504" y1="12" x2="3.504" y2="12" />
//       <line x1="6.504" y1="15" x2="3.504" y2="15" />
//     </StreamlineSvg>
//   ),
// 
//   CreditCardMastercard: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.504" y="3.5" width="23" height="17" rx="2" ry="2" />
//       <path d="M13.5,12a5,5,0,1,1-5-5A5,5,0,0,1,13.5,12Z" />
//       <path d="M13.5,12a5,5,0,1,1-5-5A5,5,0,0,1,13.5,12Z" />
//       <path d="M12.018,15.557A4.949,4.949,0,0,0,15.5,17a5,5,0,1,0,0-10,4.945,4.945,0,0,0-3.486,1.444" />
//       <path d="M12.5,11.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M12.5,13.9a.1.1,0,0,0,0,.2.1.1,0,0,0,.092-.056.073.073,0,0,0,.008-.033V14a.1.1,0,0,0-.1-.1" />
//       <path d="M12.5,9.9a.1.1,0,1,0,.1.1V9.989a.073.073,0,0,0-.008-.033A.1.1,0,0,0,12.5,9.9" />
//       <path d="M6.5,8.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M10.5,8.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M10.5,10.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M10.5,12.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M10.5,14.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M6.5,10.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M6.5,12.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M6.5,14.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M8.5,9.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M8.5,7.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M8.5,11.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M8.5,13.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M8.5,15.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M4.5,11.9a.1.1,0,1,0,.1.1.1.1,0,0,0-.1-.1" />
//       <path d="M4.5,13.9a.1.1,0,0,0-.1.1.118.118,0,0,0,.014.055.084.084,0,0,0,.075.045H4.5a.1.1,0,1,0,0-.2" />
//       <path d="M4.5,9.9H4.493a.083.083,0,0,0-.075.045A.118.118,0,0,0,4.4,10a.1.1,0,1,0,.1-.1" />
//     </StreamlineSvg>
//   ),
// 
//   CreditCardVisa: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.504" y="3.5" width="23" height="17" rx="2" ry="2" />
//       <path d="M4.957,10H6.238L6.6,13.6a.448.448,0,0,0,.85.148L9.3,9.891" />
//       <line x1="11.113" y1="9.969" x2="10.301" y2="14.031" />
//       <path d="M14.832,9.922s-1.375-.36-1.844.422.266,1.281.75,1.578,1.016.75.875,1.281-.734,1.313-2.3.766" />
//       <path d="M16,14.031l1.88-3.789a.436.436,0,0,1,.825.158l.3,3.569" />
//       <line x1="16.598" y1="12.828" x2="18.926" y2="12.828" />
//     </StreamlineSvg>
//   ),
// 
//   CurrencyDollar: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M16,3.533H11.712A3.829,3.829,0,0,0,8,7.473,3.97,3.97,0,0,0,10.054,11l3.9,2.072A3.974,3.974,0,0,1,16,16.593a3.829,3.829,0,0,1-3.708,3.94H8" />
//       <line x1="11.504" y1="20.533" x2="11.504" y2="23.533" />
//       <line x1="13.504" y1="0.467" x2="13.504" y2="3.533" />
//     </StreamlineSvg>
//   ),
// 
//   DesignToolsPenStation: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M17,12.5h1a3,3,0,0,1,0,6H17" />
//       <path d="M17,21.5a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V11a.5.5,0,0,1,.5-.5h12a.5.5,0,0,1,.5.5Z" />
//       <path d="M16.881.564c1.033,1.574-.5,2.437-.853,3.282a1.548,1.548,0,0,0,.885,2.129C19.813,7.2,20.677,1.482,16.881.564Z" />
//       <line x1="14" y1="10.5" x2="16.609" y2="5.813" />
//       <line x1="17.641" y1="6.156" x2="16" y2="10.5" />
//       <polygon points="8 10.5 5 10.5 3.5 7.5 3 4.5 5.5 6 8 10.5" />
//       <line x1="3.5" y1="7.5" x2="5.5" y2="6" />
//       <path d="M13,1a.5.5,0,0,0-.5-.5h-3A.5.5,0,0,0,9,1v9.5h4Z" />
//       <line x1="13" y1="2.5" x2="11" y2="2.5" />
//       <line x1="13" y1="4.5" x2="12" y2="4.5" />
//       <line x1="13" y1="6.5" x2="11" y2="6.5" />
//       <line x1="13" y1="8.5" x2="12" y2="8.5" />
//     </StreamlineSvg>
//   ),
// 
//   DiagramDown: (props) => (
//     <StreamlineSvg {...props}>
//       <polyline points="20.477 23.5 23.477 20.5 20.477 17.5" />
//       <path d="M23.477,20.5c-.361.018-1.224,0-1.589,0A21.412,21.412,0,0,1,.523.5" />
//     </StreamlineSvg>
//   ),
// 
//   EllipsisHorizontal: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="3" cy="12" r="2.5" />
//       <circle cx="12" cy="12" r="2.5" />
//       <circle cx="21" cy="12" r="2.5" />
//     </StreamlineSvg>
//   ),
// 
//   Envelope: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.5" y="4.5" width="23" height="15" rx="1.5" ry="1.5" />
//       <polyline points="20.5 8.5 12 14 3.5 8.5" />
//       <line x1="3.5" y1="16" x2="7" y2="14" />
//       <line x1="20.5" y1="16" x2="17" y2="14" />
//     </StreamlineSvg>
//   ),
// 
//   EnvelopePigeon: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M12.041,19.706a9.388,9.388,0,0,0,4.5,1.421c4.338.333,6-.669,6.632-1.373a.5.5,0,0,0-.182-.8,23.012,23.012,0,0,1-4.45-2.832c-.337-.254-.873-.682-1.339-1.053a.5.5,0,0,1,.1-.845c3.35-1.55,5.51-4.627,6.139-8.89a.5.5,0,0,0-.654-.547c-1.725.578-5.854,2.073-6.249,3.256-.371,1.114-1.294,3.3-1.745,4.358a.5.5,0,0,1-.763.2,11.761,11.761,0,0,0-4.492-2.479,2.8,2.8,0,0,0-3.495,2.822.5.5,0,0,1-.124.3,1.863,1.863,0,0,0-.506,1.271A.5.5,0,0,0,6.043,15l1.5-.375" />
//       <polyline points="9.046 15.627 11.546 16.127 9.046 21.627 0.546 19.627 3.046 14.127 5.471 14.733" />
//       <polyline points="3.046 14.127 5.546 18.127 11.546 16.127" />
//       <path d="M8.8,12.127a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
//       <path d="M12.546,11.548s-.5-4-.5-6c0-1.128,1.979-3.386,2.961-4.438a.5.5,0,0,1,.837.176,13.032,13.032,0,0,1,.7,4.262" />
//       <line x1="17.309" y1="21.193" x2="18.546" y2="23.048" />
//       <line x1="20.215" y1="21.051" x2="21.546" y2="23.048" />
//     </StreamlineSvg>
//   ),
// 
//   GiftBox: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M23.5,10.5a1,1,0,0,1-1,1H1.5a1,1,0,0,1-1-1v-2a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z" />
//       <path d="M21.5,22.5a1,1,0,0,1-1,1H3.5a1,1,0,0,1-1-1v-11h19Z" />
//       <line x1="8.5" y1="7.5" x2="8.5" y2="23.5" />
//       <line x1="15.5" y1="23.5" x2="15.5" y2="7.5" />
//       <path d="M17.427,3.84C16.663,4.605,13,5.5,13,5.5s.9-3.663,1.66-4.427A1.957,1.957,0,1,1,17.427,3.84Z" />
//       <path d="M6.573,3.84C7.337,4.605,11,5.5,11,5.5s-.9-3.663-1.66-4.427A1.957,1.957,0,0,0,6.573,3.84Z" />
//     </StreamlineSvg>
//   ),
// 
//   GiftBox1: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M10.5,14h-4a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1h11a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1h-4" />
//       <path d="M13.5,14H17l-.434,6.071a1,1,0,0,1-1,.929H8.431a1,1,0,0,1-1-.929L7,14h3.5" />
//       <line x1="10.5" y1="11" x2="10.5" y2="21" />
//       <line x1="13.5" y1="21" x2="13.5" y2="11" />
//       <path d="M8.5,9.688C9.1,10.292,12,11,12,11s-.709-2.9-1.312-3.5A1.547,1.547,0,0,0,8.5,9.688Z" />
//       <path d="M15.5,9.688C14.9,10.292,12,11,12,11s.709-2.9,1.312-3.5A1.547,1.547,0,0,1,15.5,9.688Z" />
//       <line x1="12" y1="4.5" x2="12" y2="3" />
//       <line x1="19.071" y1="7.429" x2="20.132" y2="6.369" />
//       <line x1="22" y1="14" x2="23.5" y2="14" />
//       <line x1="4.929" y1="7.429" x2="3.868" y2="6.369" />
//       <line x1="2" y1="14" x2="0.5" y2="14" />
//     </StreamlineSvg>
//   ),
// 
//   Hash: (props) => (
//     <StreamlineSvg {...props}>
//       <line x1="2.5" y1="8.498" x2="23.5" y2="8.498" />
//       <line x1="0.5" y1="15.498" x2="21.5" y2="15.498" />
//       <line x1="6.521" y1="23.498" x2="10.531" y2="0.498" />
//       <line x1="13.521" y1="23.498" x2="17.531" y2="0.498" />
//     </StreamlineSvg>
//   ),

  FitnessHeartRate: (props) => (
    <StreamlineSvg {...props}>
      <path d="M1.544,11.068H8.232a.5.5,0,0,0,.416-.222l1.359-2.038a.5.5,0,0,1,.869.066l2.66,5.7a.5.5,0,0,0,.91-.008l1.422-3.2a.5.5,0,0,1,.457-.3h6.131" />
      <path d="M12,21.932,2.2,11.84A5.669,5.669,0,0,1,1.112,5.233h0A5.84,5.84,0,0,1,10.4,3.746L12,5.205l1.6-1.459a5.839,5.839,0,0,1,9.287,1.487h0A5.67,5.67,0,0,1,21.8,11.84Z" />
    </StreamlineSvg>
  ),

//   FishBowl: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M1.751,4a.5.5,0,0,0-.466.318,11.5,11.5,0,1,0,21.43,0A.5.5,0,0,0,22.249,4Z" />
//       <path d="M7.458,13c1.849,1.849,2.633,3,4.5,3,2.437,0,3-1.5,3-1.5s-.625-1.5-3-1.5c-1.867,0-2.651,1.151-4.5,3" />
//       <line x1="0.511" y1="8" x2="23.489" y2="8" />
//     </StreamlineSvg>
//   ),
// 
//   HandExpand: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M19,17.35a5.411,5.411,0,0,1,.636-2.544l2.142-4.022a1.5,1.5,0,0,0-2.555-1.57L16.5,13.5l1.617-8.821a1.5,1.5,0,0,0-2.932-.631L13.5,11.5V2a1.5,1.5,0,1,0-3,0V11.5L8.816,3.683a1.5,1.5,0,0,0-2.933.631L7.5,15.5,4.513,12.892a1.5,1.5,0,1,0-2.025,2.213l5.257,7A4.5,4.5,0,0,0,11,23.5h3.5C18.055,23.5,19,20.723,19,17.35Z" />
//     </StreamlineSvg>
//   ),

  Heart: (props) => (
    <StreamlineSvg {...props}>
      {/* <path d="M1.544,11.068H8.232a.5.5,0,0,0,.416-.222l1.359-2.038a.5.5,0,0,1,.869.066l2.66,5.7a.5.5,0,0,0,.91-.008l1.422-3.2a.5.5,0,0,1,.457-.3h6.131" /> */}
      <path d="M12,21.932,2.2,11.84A5.669,5.669,0,0,1,1.112,5.233h0A5.84,5.84,0,0,1,10.4,3.746L12,5.205l1.6-1.459a5.839,5.839,0,0,1,9.287,1.487h0A5.67,5.67,0,0,1,21.8,11.84Z" />
    </StreamlineSvg>
  ),

  ImageFileAdd: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="6.5" cy="7.5" r="2" />
      <path d="M12.544,11.17l-.62-.992a.5.5,0,0,0-.848,0L8.437,14.4,7.362,12.678a.5.5,0,0,0-.848,0L3.5,17.5h5" />
      <path d="M10.5,23.5h-9a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H14.793A1,1,0,0,1,15.5.793L19.207,4.5a1,1,0,0,1,.293.707V9.5" />
      <circle cx="17.5" cy="17.5" r="6" />
      <line x1="17.5" y1="14.5" x2="17.5" y2="20.5" />
      <line x1="20.5" y1="17.5" x2="14.5" y2="17.5" />
    </StreamlineSvg>
  ),

  ImageFileUpload: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="6.5" cy="7.5" r="2" />
      <path d="M12.3,10.785l-.379-.607a.5.5,0,0,0-.848,0L8.438,14.4,7.362,12.678a.5.5,0,0,0-.848,0L3.5,17.5h5" />
      <path d="M9.5,23.5h-8a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H14.793A1,1,0,0,1,15.5.793L19.207,4.5a1,1,0,0,1,.293.707V8.5" />
      <circle cx="17.5" cy="17.5" r="6" />
      <line x1="17.5" y1="20.5" x2="17.5" y2="14.5" />
      <line x1="17.5" y1="14.5" x2="15.25" y2="16.75" />
      <line x1="17.5" y1="14.5" x2="19.75" y2="16.75" />
    </StreamlineSvg>
  ),

//   InfoCircle: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="12" cy="12.001" r="11" />
//       <path d="M14.5,17.005H13a1,1,0,0,1-1-1v-6.5a.5.5,0,0,0-.5-.5H10" />
//       <line x1="11.745" y1="6.504" x2="11.745" y2="6.504" />
//       <path d="M11.745,6.5a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
//     </StreamlineSvg>
//   ),
// 
//   JellyFishGroup: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M6.127,8.751A3.187,3.187,0,0,1,7.7,6.193l-1.6-.98a2.286,2.286,0,0,1,.252-1.6c.866-1.413,3.286-1.5,5.405-.206s3.134,3.5,2.268,4.91a2.289,2.289,0,0,1-1.316.953l-1.6-.981a3.189,3.189,0,0,1-1.568,2.558" />
//       <line x1="9.4" y1="7.239" x2="7.832" y2="9.796" />
//       <path d="M13.936,17.924A3.188,3.188,0,0,1,15.5,15.366l-1.6-.98a2.286,2.286,0,0,1,.252-1.6c.866-1.413,3.285-1.505,5.4-.205s3.134,3.5,2.268,4.909a2.289,2.289,0,0,1-1.316.953l-1.6-.981a3.189,3.189,0,0,1-1.568,2.558" />
//       <line x1="17.209" y1="16.412" x2="15.641" y2="18.969" />
//       <path d="M1.936,19.424A3.188,3.188,0,0,1,3.5,16.866l-1.6-.98a2.286,2.286,0,0,1,.252-1.6c.866-1.413,3.285-1.505,5.4-.205s3.134,3.5,2.268,4.909a2.289,2.289,0,0,1-1.316.953l-1.6-.981a3.189,3.189,0,0,1-1.568,2.558" />
//       <line x1="5.209" y1="17.912" x2="3.641" y2="20.469" />
//     </StreamlineSvg>
//   ),
// 
//   LayoutDashboard: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.5" y="16.5" width="10" height="7" rx="1" ry="1" />
//       <rect x="13.5" y="10.5" width="10" height="13" rx="1" ry="1" transform="translate(37 34) rotate(180)" />
//       <rect x="13.5" y="0.5" width="10" height="7" rx="1" ry="1" transform="translate(37 8) rotate(180)" />
//       <rect x="0.5" y="0.5" width="10" height="13" rx="1" ry="1" />
//     </StreamlineSvg>
//   ),

  Loading: (props) => (
    <StreamlineSvg {...props} className={`${props.className || ''} spin`}>
      <path d="M11,2a1,1,0,1,1,2,0V5a1,1,0,1,1-2,0Z" />
      <path d="M4.382,5.753A1,1,0,0,1,5.8,4.338L7.918,6.459A1,1,0,1,1,6.505,7.874Z" />
      <path d="M1,12a1,1,0,0,1,1-1H5a1,1,0,0,1,0,2H2A1,1,0,0,1,1,12Z" />
      <path d="M4.129,19.852a1,1,0,0,1,0-1.415l2.12-2.122a1,1,0,1,1,1.415,1.414L5.543,19.851A1,1,0,0,1,4.129,19.852Z" />
      <path d="M11,19a1,1,0,0,1,2,0v3a1,1,0,0,1-2,0Z" />
      <path d="M16.4,17.774a1,1,0,0,1,1.416-1.416l2.122,2.12A1,1,0,0,1,18.527,19.9Z" />
      <path d="M18,12a1,1,0,0,1,1-1h3a1,1,0,0,1,0,2H19A1,1,0,0,1,18,12Z" />
      <path d="M16.218,7.738a1,1,0,0,1,0-1.416L18.337,4.2a1,1,0,0,1,1.416,1.416L17.632,7.738A1,1,0,0,1,16.218,7.738Z" />
    </StreamlineSvg>
  ),

//   Lock1: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="12" cy="15" r="1" />
//       <line x1="12" y1="16" x2="12" y2="19" />
//       <rect x="3.5" y="9.5" width="17" height="14" rx="1" ry="1" />
//       <path d="M6.5,6a5.5,5.5,0,0,1,11,0V9.5H6.5Z" />
//     </StreamlineSvg>
//   ),
// 
//   LogoFacebook: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M12.5,23.5H1.5a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1v21a1,1,0,0,1-1,1h-6v-9h2.559a.5.5,0,0,0,.5-.438l.375-3a.5.5,0,0,0-.5-.562H16.5V9.185A1.687,1.687,0,0,1,18.186,7.5H20a.5.5,0,0,0,.5-.5V4a.5.5,0,0,0-.5-.5H18.186A5.692,5.692,0,0,0,12.5,9.185V10.5H10a.5.5,0,0,0-.5.5v3a.5.5,0,0,0,.5.5h2.5v9Z" />
//     </StreamlineSvg>
//   ),
// 
//   LogoInstagram: (props) => (
//     <StreamlineSvg {...props}>
//       <rect x="0.5" y="0.5" width="23" height="23" rx="6" ry="6" />
//       <circle cx="12" cy="12" r="6" />
//       <circle cx="19" cy="5" r="1.5" />
//     </StreamlineSvg>
//   ),
// 
//   LogoLinkedIn: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M6.5,22.5h-5V9.5h5Zm9-9a2,2,0,0,0-2,2v7h-5V9.5h5v1.485A6.307,6.307,0,0,1,17.49,9.49c2.962,0,5.01,2.2,5.01,6.355V22.5h-5v-7A2,2,0,0,0,15.5,13.5ZM6.5,5A2.5,2.5,0,1,1,4,2.5,2.5,2.5,0,0,1,6.5,5Z" />
//     </StreamlineSvg>
//   ),
// 
//   LogoMedium: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M7.5,6V21.5a.5.5,0,0,1-.724.447l-6-3.309A.5.5,0,0,1,.5,18.191V3.309a.5.5,0,0,1,.724-.448Z" />
//       <line x1="16" y1="18" x2="7.5" y2="6" />
//       <polyline points="23.444 6.089 21.625 8.999 15.747 18.404" />
//       <path d="M7.5,14.248l15.282,7.7a.5.5,0,0,0,.718-.45V6.318a.5.5,0,0,0-.289-.453L16.384,2.679a.5.5,0,0,0-.624.169l-5.168,7.517" />
//     </StreamlineSvg>
//   ),
// 
//   LogoMsTeams: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M2.95714286,17 L8.02857143,17 C8.46250945,17 8.81428571,16.6482237 8.81428571,16.2142857 L8.81428571,6.78571429 C8.81428571,6.35177627 8.46250945,6 8.02857143,6 L0.6,6" transform="translate(4.707143, 11.500000) scale(-1, 1) translate(-4.707143, -11.500000) " />
//       <line x1="3.01628571" y1="9" x2="6.41628571" y2="9" />
//       <line x1="4.63569973" y1="9.00289466" x2="4.7928717" y2="13.5037116" transform="translate(4.714286, 11.253303) rotate(2.000000) translate(-4.714286, -11.253303) " />
//       <path d="M15.8,22 L18.3,22 L18.3,15.5 C18.3,12.7385763 16.0614237,10.5 13.3,10.5 C10.5385763,10.5 8.3,12.7385763 8.3,15.5 L8.3,22 L10.8,22 L15.8,22 Z" transform="translate(13.300000, 16.250000) scale(1, -1) translate(-13.300000, -16.250000) " />
//       <path d="M19.9950935,18.5 L23.4095238,18.5 L23.4095238,14.3095238 C23.4095238,12.2055819 21.7039419,10.5 19.6,10.5" transform="translate(21.504762, 14.500000) scale(1, -1) translate(-21.504762, -14.500000) " />
//       <circle cx="13.3" cy="5" r="3" />
//       <circle cx="20.2" cy="6" r="2" />
//     </StreamlineSvg>
//   ),
// 
//   LogoSlack: ({color, ...props}) => (
//     <StreamlineSvg className={`slack ${color ? 'color' : ''}`} {...props}>
//       <path d="M8.85607992,1 C7.67162821,1.00087374 6.71303647,1.96024465 6.7139113,3.14242027 C6.71303647,4.32459589 7.67250364,5.2839668 8.85695535,5.28484054 L10.9999994,5.28484054 L10.9999994,3.14329401 C11.0008742,1.96111839 10.0414071,1.00174749 8.85607992,1 C8.85695535,1 8.85695535,1 8.85607992,1 L8.85607992,1 Z M8.85607992,6.71428571 L3.14304823,6.71428571 C1.95859652,6.71515946 0.999129352,7.67453036 1.00000239,8.85670599 C0.998253926,10.0388816 1.95772109,10.9982525 3.14217281,11 L8.85607992,11 C10.0405316,10.9991263 11.0000024,10.0397554 10.999124,8.85757973 C11.0000024,7.67453036 10.0405316,6.71515946 8.85607992,6.71428571 Z" />
//       <path d="M22.9999994,8.85670599 C23.0008743,7.67453036 22.0413231,6.71515946 20.8567677,6.71428571 C19.6722123,6.71515946 18.7126611,7.67453036 18.713536,8.85670599 L18.713536,11 L20.8567677,11 C22.0413231,10.9991263 23.0008743,10.0397554 22.9999994,8.85670599 Z M17.285592,8.85670599 L17.285592,3.14242027 C17.2864669,1.96111839 16.3277913,1.00174749 15.1432359,1 C13.9586804,1.00087374 12.9991293,1.96024465 13.0000024,3.14242027 L13.0000024,8.85670599 C12.9982538,10.0388816 13.9578049,10.9982525 15.1423604,11 C16.3269158,10.9991263 17.2864669,10.0397554 17.285592,8.85670599 Z" />
//       <path d="M15.1430447,23 C16.3274964,22.9991263 17.2869635,22.0398392 17.2860887,20.8577669 C17.2869635,19.6756946 16.3274964,18.7164075 15.1430447,18.7155338 L13.0000006,18.7155338 L13.0000006,20.8577669 C12.9991258,22.0389656 13.9585929,22.9982527 15.1430447,23 Z M15.1430447,17.2853399 L20.8569518,17.2853399 C22.0414035,17.2844662 23.000876,16.3251791 22.9999976,15.1431068 C23.0017461,13.9610344 22.0422789,13.0017473 20.8578272,13 L15.1439201,13 C13.9594684,13.0008737 13.0000012,13.9601608 13.000876,15.1422331 C13.0000012,16.3251791 13.9585929,17.2844662 15.1430447,17.2853399 L15.1430447,17.2853399 Z" />
//       <path d="M1.0000006,15.1424203 C0.999125694,16.3245959 1.95867686,17.2839668 3.14323227,17.2848405 C4.32778769,17.2839668 5.28733886,16.3245959 5.28646395,15.1424203 L5.28646395,13 L3.14323227,13 C1.95867686,13.0008737 0.999125694,13.9602446 1.0000006,15.1424203 Z M6.71440618,15.1424203 L6.71440618,20.856706 C6.71265756,22.0388816 7.67220873,22.9982525 8.85676414,23 C10.0413196,22.9991263 11.0008707,22.0397554 10.9999976,20.8575797 L10.9999976,15.1441678 C11.0017462,13.9619921 10.0421951,13.0026212 8.85763965,13.0008737 C7.67220873,13.0008737 6.71353307,13.9602446 6.71440618,15.1424203 C6.71440618,15.1424203 6.71440618,15.143294 6.71440618,15.1424203 L6.71440618,15.1424203 Z" />
//     </StreamlineSvg>
//   ),
// 
//   LogoTwitter: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M23,6.628l-2-.5,1-2-2.464.7A4.48,4.48,0,0,0,12,8.128v1c-3.539.73-6.634-1.2-9.5-4.5q-.75,4,1.5,6l-3-.5c.405,2.069,1.362,3.7,4,4l-2.5,1c1,2,2.566,2.31,5,2.5a10.748,10.748,0,0,1-6.5,2c12.755,5.669,20-2.664,20-10V8.3Z" />
//     </StreamlineSvg>
//   ),

  Logout2: (props) => (
    <StreamlineSvg {...props}>
      <line x1="23.5" y1="12" x2="11.5" y2="12" />
      <polyline points="19.5 16 23.5 12 19.5 8" />
      <path d="M9.5,2.5H17a.5.5,0,0,1,.5.5V5.5" />
      <path d="M17.5,18.5V21a.5.5,0,0,1-.5.5H9.5" />
      <path d="M.5,21.223a.5.5,0,0,0,.392.488l8,1.777A.5.5,0,0,0,9.5,23V1A.5.5,0,0,0,8.892.512l-8,1.778A.5.5,0,0,0,.5,2.778Z" />
      <circle cx="6" cy="12" r="1.5" />
    </StreamlineSvg>
  ),

//   LogoYoutube: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M23.5,6.939A3.438,3.438,0,0,0,20.062,3.5H3.937A3.438,3.438,0,0,0,.5,6.939V16.06A3.438,3.438,0,0,0,3.937,19.5H20.062A3.438,3.438,0,0,0,23.5,16.06Zm-14,8.5V6.779l6.769,4.331Z" />
//     </StreamlineSvg>
//   ),
// 
//   LoveHeartHandsHold: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M16.442,8.08A4.354,4.354,0,0,0,12,11.108,4.354,4.354,0,0,0,7.558,8.08" />
//       <path d="M3.9,2.5,1.486,5.517A4.5,4.5,0,0,0,.5,8.329V13.5A1.5,1.5,0,0,0,2,15H2a1.5,1.5,0,0,0,1.5-1.5V9.625" />
//       <path d="M8,2.5V6.136a4.493,4.493,0,0,1-.757,2.5L6.1,10.346a1.4,1.4,0,0,1-1.977.445h0A1.4,1.4,0,0,1,3.648,9L5,6.75" />
//       <rect x="2.5" y="0.5" width="6" height="2" rx="0.5" ry="0.5" />
//       <path d="M20.1,2.5l2.414,3.017A4.5,4.5,0,0,1,23.5,8.329V13.5A1.5,1.5,0,0,1,22,15h0a1.5,1.5,0,0,1-1.5-1.5V9.625" />
//       <path d="M16,2.5V6.136a4.493,4.493,0,0,0,.757,2.5L17.9,10.346a1.4,1.4,0,0,0,1.977.445h0A1.4,1.4,0,0,0,20.352,9L19,6.75" />
//       <rect x="15.5" y="0.5" width="6" height="2" rx="0.5" ry="0.5" transform="translate(37 3) rotate(-180)" />
//       <path d="M19.547,16.125c-1.911,3.31-6.124,6.466-7.26,7.281a.485.485,0,0,1-.574,0c-1.136-.815-5.349-3.971-7.26-7.281" />
//     </StreamlineSvg>
//   ),
// 
//   LoveHeartHold: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M4.036,11.129A5.97,5.97,0,0,0,3,14.5v4.046c0,1.513.672,2.23,2,2.954v2" />
//       <path d="M16,17.5,16,18.693c-.043,1.413-.713,2.106-2,2.807v2" />
//       <path d="M6.28,13.346,2.8,9.909C-2.42,5.041,4.866-2.795,10,1.994l2,1.864,2-1.864c5.136-4.789,12.422,3.047,7.2,7.915l-8.849,8.738a.5.5,0,0,1-.7,0l-.666-.658c.1-1,.483-2.789-1.338-3.753l1.19-2.248a1.7,1.7,0,1,0-3-1.585L5,15.765" />
//     </StreamlineSvg>
//   ),
// 
//   LoveItHandGive: (props) => (
//     <StreamlineSvg {...props}>
//       <line x1="1" y1="23" x2="1" y2="13" />
//       <path d="M1,15H8a3,3,0,0,1,3,3h6a3,3,0,0,1,3,3H1Z" />
//       <line x1="11" y1="18" x2="8" y2="18" />
//       <path d="M17.347,11.139a.477.477,0,0,1-.692,0L11.887,6.165a3.027,3.027,0,0,1-.567-3.493h0a3.026,3.026,0,0,1,4.846-.786L17,2.722l.835-.835a3.024,3.024,0,0,1,4.844.786h0a3.027,3.027,0,0,1-.567,3.493Z" />
//     </StreamlineSvg>
//   ),
// 
//   LoveItRibbon1: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M11.372,13.672a1,1,0,0,0,1.258,0c1.238-1,3.87-3.353,3.87-5.208,0-2.8-3.682-3.681-4.5-.279-.818-3.4-4.5-2.517-4.5.279C7.5,10.318,10.135,12.669,11.372,13.672Z" />
//       <path d="M4.148,14.034.56,20.181c-.138.238-.028.395.243.349l3.358-.569,1.164,3.2c.094.258.286.277.427.04l3.065-5.148" />
//       <path d="M19.852,14.034l3.588,6.147c.138.238.028.395-.243.349l-3.358-.569-1.164,3.2c-.094.258-.286.277-.427.04l-3.065-5.148" />
//       <circle cx="12" cy="9.632" r="9" />
//     </StreamlineSvg>
//   ),
// 
//   M: (props) => (
//     <StreamlineSvg {...props}>
//       <polygon points="20.7121212 17.953125 23.5 20.3320312 23.5 21 13.8369286 21 13.8369286 20.3320312 16.6366204 17.953125 16.6366204 7.46484375 11.3916281 20.765625 10.5174628 20.765625 5.1779661 8.30859375 5.1779661 17.7890625 8.15485362 20.34375 8.21391885 21 0.5 21 0.5 20.3554688 3.5241397 17.7890625 3.5241397 6.12890625 0.653569594 3.6796875 0.653569594 3 7.84771443 3 12.2539805 14.0742188 16.6484335 3 23.488187 3 23.488187 3.66796875 20.7121212 6.046875" />
//     </StreamlineSvg>
//   ),
// 
//   MessageBubbleQuestion: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M23.5,17.5a1,1,0,0,1-1,1h-11l-4,4v-4h-6a1,1,0,0,1-1-1V2.5a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z" />
//       <path d="M9.5,7.5a3,3,0,1,1,4.2,2.75,2,2,0,0,0-1.2,1.834V12.5" />
//       <path d="M12.5,14.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
//     </StreamlineSvg>
//   ),
// 
//   MessageBubbleInfo: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M23.522,17.5a1,1,0,0,1-1,1h-11l-4,4v-4h-6a1,1,0,0,1-1-1V2.5a1,1,0,0,1,1-1h21a1,1,0,0,1,1,1Z" />
//       <path d="M12.522,14.5v-6a1,1,0,0,0-1-1h-1" />
//       <line x1="10.522" y1="14.5" x2="14.522" y2="14.5" />
//       <path d="M12.272,5a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
//     </StreamlineSvg>
//   ),
// 
//   MicrophonePodcast2: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M18,12.5a6,6,0,0,1-12,0v-6a6,6,0,0,1,12,0Z" />
//       <path d="M4,10.5v2a7.929,7.929,0,0,0,8,8,7.93,7.93,0,0,0,8-8v-2" />
//       <line x1="12" y1="20.5" x2="12" y2="23.5" />
//       <line x1="12" y1="0.5" x2="12" y2="3.5" />
//       <line x1="14.001" y1="0.842" x2="14" y2="3.5" />
//       <line x1="10" y1="0.842" x2="10" y2="3.5" />
//       <line x1="4" y1="12.5" x2="20" y2="12.5" />
//       <line x1="6" y1="6.5" x2="10" y2="6.5" />
//       <line x1="6" y1="8.5" x2="10" y2="8.5" />
//       <line x1="18" y1="6.5" x2="14" y2="6.5" />
//       <line x1="18" y1="8.5" x2="14" y2="8.5" />
//       <line x1="6" y1="10.5" x2="10" y2="10.5" />
//       <line x1="18" y1="10.5" x2="14" y2="10.5" />
//     </StreamlineSvg>
//   ),
// 
//   MonetizationTablet: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M21.254,23.5l-2.481-3.434L18.754,15.5c.042-1.778-2.081-3.363-4-5" />
//       <path d="M16.273,17.5l-3.255-3.349A1.248,1.248,0,1,0,11.233,15.9l3.54,3.605V21c0,.961,1.56,2.5,1.56,2.5" />
//       <path d="M8.5,17a.25.25,0,1,1-.25.25A.25.25,0,0,1,8.5,17" />
//       <path d="M11.754,19.5h-8a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H13.773a1,1,0,0,1,1,1V15.956" />
//       <line x1="2.754" y1="15.5" x2="8.754" y2="15.5" />
//       <line x1="8.754" y1="10.5" x2="8.754" y2="11.5" />
//       <line x1="8.754" y1="5.5" x2="8.754" y2="6.5" />
//       <path d="M6.754,10.5h3a1,1,0,0,0,0-2h-2a1,1,0,0,1,0-2h3" />
//     </StreamlineSvg>
//   ),
// 
//   MoodPeace: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M19,15.25a1.5,1.5,0,0,1-3,0v-3a1.5,1.5,0,0,1,3,0Z" />
//       <path d="M16,14.25a1.5,1.5,0,0,1-3,0v-3a1.5,1.5,0,0,1,3,0Z" />
//       <path d="M10,11.25l.869-9.142a1.5,1.5,0,0,1,2.986.29L13,11.25" />
//       <path d="M7.034,11.247,6.157,2.393a1.5,1.5,0,0,1,2.986-.286L10,11.25" />
//       <path d="M11.5,19.25a4,4,0,0,0-4-4H11a2,2,0,0,0,0-4H7a2,2,0,0,0-2,2v5a3,3,0,0,0,3,3v2" />
//       <path d="M18,16.665V17.75a3,3,0,0,1-3,3v2.5" />
//     </StreamlineSvg>
//   ),

  NavigationMenu: (props) => (
    <StreamlineSvg {...props}>
      <line x1="21" y1="7" x2="3" y2="7" />
      <line x1="21" y1="12" x2="3" y2="12" />
      <line x1="21" y1="17" x2="3" y2="17" />
    </StreamlineSvg>
  ),

//   PaginateFilterHeart: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M23.5,1.5a1,1,0,0,0-1-1H4.5a1,1,0,0,0-1,1v18a1,1,0,0,0,1,1h18a1,1,0,0,0,1-1Z" />
//       <path d="M3.5,4.5h-2a1,1,0,0,0-1,1v17a1,1,0,0,0,1,1h17a1,1,0,0,0,1-1v-2" />
//       <path d="M13.5,16,8.387,10.662A3.027,3.027,0,0,1,7.82,7.169h0a3.027,3.027,0,0,1,4.846-.786l.834.836.835-.835a3.026,3.026,0,0,1,4.845.786h0a3.027,3.027,0,0,1-.567,3.493Z" />
//     </StreamlineSvg>
//   ),
// 
//   PartyConfetti: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M16.483,12.216a4.967,4.967,0,0,1,7.017,0" />
//       <path d="M11.768,7.518a4.961,4.961,0,0,0,0-7.018" />
//       <line x1="18.075" y1="8.249" x2="21.584" y2="7.081" />
//       <line x1="15.736" y1="5.912" x2="16.906" y2="2.402" />
//       <path d="M7.965,4.771a.25.25,0,0,1,.25.25" />
//       <path d="M7.715,5.021a.25.25,0,0,1,.25-.25" />
//       <path d="M7.965,5.271a.25.25,0,0,1-.25-.25" />
//       <path d="M8.215,5.021a.25.25,0,0,1-.25.25" />
//       <path d="M15.465,7.771a.25.25,0,0,1,.25.25" />
//       <path d="M15.215,8.021a.25.25,0,0,1,.25-.25" />
//       <path d="M15.465,8.271a.25.25,0,0,1-.25-.25" />
//       <path d="M15.715,8.021a.25.25,0,0,1-.25.25" />
//       <path d="M19.465,13.771a.25.25,0,0,1,.25.25" />
//       <path d="M19.215,14.021a.25.25,0,0,1,.25-.25" />
//       <path d="M19.465,14.271a.25.25,0,0,1-.25-.25" />
//       <path d="M19.715,14.021a.25.25,0,0,1-.25.25" />
//       <path d="M19.965,3.771a.25.25,0,0,1,.25.25" />
//       <path d="M19.715,4.021a.25.25,0,0,1,.25-.25" />
//       <path d="M19.965,4.271a.25.25,0,0,1-.25-.25" />
//       <path d="M20.215,4.021a.25.25,0,0,1-.25.25" />
//       <ellipse cx="11.502" cy="12.498" rx="2.5" ry="5.5" transform="translate(-5.469 11.794) rotate(-45)" />
//       <path d="M15.145,16.575,2.484,23.341A1.348,1.348,0,0,1,.66,21.516L7.425,8.855" />
//     </StreamlineSvg>
//   ),
// 
//   PartyMusicDanceWoman: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="19.237" cy="5.023" r="1.575" />
//       <polyline points="20.802 5.198 21.327 0.503 23.5 2.331" />
//       <circle cx="19.547" cy="13.603" r="1.188" />
//       <polyline points="20.703 13.328 19.879 9.862 21.887 10.605" />
//       <circle cx="5" cy="2.994" r="2.5" />
//       <path d="M13.234,15.018c.365-.116,1.2-.457,1.283-.816a1,1,0,0,0-.268-.945L9.589,9.138c.157-.036.308-.088.467-.115l3.24-.54A1.5,1.5,0,1,0,12.8,5.524l-3.24.54A10.247,10.247,0,0,0,4.006,8.907L.945,11.937a1.5,1.5,0,1,0,2.11,2.132l2.861-2.832L4.682,15.913a1,1,0,0,0,.335.914,2.1,2.1,0,0,0,1.408.278l.009,0L5.061,21.58a1.5,1.5,0,1,0,2.878.847l1.249-4.21.523-1.777.747.868a2.191,2.191,0,0,1,.476,1.894l-.4,1.976a1.5,1.5,0,1,0,2.928.651l.6-2.7c.036-.718.318-2.778-.743-4.01Z" />
//     </StreamlineSvg>
//   ),
// 
//   PasswordLock2: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M10.75,10.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
//       <path d="M6,10.75a.25.25,0,1,1-.25.25A.25.25,0,0,1,6,10.75" />
//       <rect x="14.5" y="15.501" width="9" height="7.999" rx="1" ry="1" />
//       <circle cx="19" cy="19.563" r="1.25" />
//       <path d="M21.5,14a2.5,2.5,0,0,0-5,0v1.5h5Z" />
//       <path d="M12.5,15.5H2.5a2,2,0,0,1-2-2v-5a2,2,0,0,1,2-2h19a2,2,0,0,1,2,2v5" />
//     </StreamlineSvg>
//   ),


  Pencil: (props) => (
    <StreamlineSvg {...props}>
      <rect x="9.268" y="1.09" width="6" height="21.284" transform="translate(11.889 -5.238) rotate(45)" />
      <polygon points="2.621 17.136 0.5 23.5 6.864 21.379 2.621 17.136" />
      <path d="M21.914,6.328,17.672,2.086l.707-.707a3,3,0,0,1,4.242,4.242Z" />
    </StreamlineSvg>
  ),

//   Pin: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M12,.71a7.5,7.5,0,0,1,7.5,7.5c0,3.547-5.5,12.381-7.079,14.85a.5.5,0,0,1-.842,0C10,20.592,4.5,11.757,4.5,8.21A7.5,7.5,0,0,1,12,.71Z" />
//       <circle cx="12" cy="8.21" r="3" />
//     </StreamlineSvg>
//   ),
// 
//   ProductsGiftGive: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M15.5,7.476h-4a1,1,0,0,1-1-1v-1a1,1,0,0,1,1-1h11a1,1,0,0,1,1,1v1a1,1,0,0,1-1,1h-4" />
//       <path d="M18.5,7.476h4v6a1,1,0,0,1-1,1h-9a1,1,0,0,1-1-1v-6h4" />
//       <line x1="15.5" y1="4.476" x2="15.5" y2="14.476" />
//       <line x1="18.5" y1="14.476" x2="18.5" y2="4.476" />
//       <path d="M13.5,3.164c.6.605,3.5,1.312,3.5,1.312s-.709-2.9-1.312-3.5A1.547,1.547,0,0,0,13.5,3.164Z" />
//       <path d="M20.5,3.164c-.6.605-3.5,1.312-3.5,1.312s.709-2.9,1.312-3.5A1.547,1.547,0,0,1,20.5,3.164Z" />
//       <line x1="0.5" y1="23.476" x2="0.5" y2="13.476" />
//       <path d="M.5,15.476h7a3,3,0,0,1,3,3h6a3,3,0,0,1,3,3H.5Z" />
//       <line x1="10.5" y1="18.476" x2="7.5" y2="18.476" />
//     </StreamlineSvg>
//   ),
// 
//   ReceiptDollar: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M4,22.293a.5.5,0,0,0,.854.353L6,21.5l1.646,1.646a.5.5,0,0,0,.708,0L10,21.5l1.646,1.646a.5.5,0,0,0,.708,0L14,21.5l1.646,1.646a.5.5,0,0,0,.708,0L18,21.5l1.146,1.146A.5.5,0,0,0,20,22.293V1.707a.5.5,0,0,0-.854-.353L18,2.5,16.354.854a.5.5,0,0,0-.708,0L14,2.5,12.354.854a.5.5,0,0,0-.708,0L10,2.5,8.354.854a.5.5,0,0,0-.708,0L6,2.5,4.854,1.354A.5.5,0,0,0,4,1.707Z" />
//       <line x1="7" y1="6.5" x2="15" y2="6.5" />
//       <line x1="7" y1="9.5" x2="11" y2="9.5" />
//       <line x1="7" y1="12.5" x2="11" y2="12.5" />
//       <line x1="7" y1="15.5" x2="10" y2="15.5" />
//       <line x1="15" y1="17.414" x2="15" y2="18.5" />
//       <line x1="16" y1="10.5" x2="16" y2="11.588" />
//       <path d="M14,16a1.5,1.5,0,1,0,1.5-1.5A1.5,1.5,0,1,1,17,13" />
//     </StreamlineSvg>
//   ),
// 
//   ReceiptRegister: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M19.968,18.5h.688a1,1,0,0,1,1,1v3a1,1,0,0,1-1,1h-17a1,1,0,0,1-1-1v-3" />
//       <line x1="9.656" y1="4.5" x2="9.656" y2="7.5" />
//       <line x1="12.523" y1="17.866" x2="9.423" y2="14.767" />
//       <path d="M9.656,7.643A12.256,12.256,0,0,1,19.968,18.5" />
//       <circle cx="13.406" cy="18.75" r="1.25" />
//       <line x1="2.656" y1="18.5" x2="8.656" y2="18.5" />
//       <line x1="17.156" y1="18.5" x2="19.968" y2="18.5" />
//       <path d="M9.656,4.5l1.277-2.553A1,1,0,0,0,10.038.5H3.656a1,1,0,0,0-1,1v18" />
//       <circle cx="7.656" cy="13" r="2.5" />
//       <line x1="2.656" y1="4.5" x2="9.656" y2="4.5" />
//       <line x1="14.656" y1="7.529" x2="15.656" y2="8.029" />
//       <line x1="17.261" y1="9.259" x2="18.11" y2="9.988" />
//       <line x1="19.404" y1="11.61" x2="19.971" y2="12.574" />
//       <line x1="20.83" y1="14.435" x2="21.171" y2="15.5" />
//     </StreamlineSvg>
//   ),
// 
//   ReceiptSlip1: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M19.5,22.475a.5.5,0,0,1-.825.38L16,20.563l-3.7,2.774a.5.5,0,0,1-.6,0L8,20.563,5.325,22.855a.5.5,0,0,1-.825-.38V1.563a1,1,0,0,1,1-1h13a1,1,0,0,1,1,1Z" />
//       <line x1="7" y1="5.563" x2="11" y2="5.563" />
//       <line x1="7" y1="8.563" x2="12.5" y2="8.563" />
//       <line x1="7" y1="11.563" x2="13" y2="11.563" />
//       <line x1="7" y1="14.563" x2="11.5" y2="14.563" />
//       <path d="M16.5,5.313a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
//       <path d="M16.5,8.313a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
//       <path d="M16.5,11.313a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
//       <path d="M16.5,14.313a.25.25,0,1,1-.25.25.249.249,0,0,1,.25-.25" />
//     </StreamlineSvg>
//   ),

  Remove: (props) => (
    <StreamlineSvg {...props}>
      <line x1="23.5" y1="0.5" x2="0.5" y2="23.5" />
      <line x1="23.5" y1="23.5" x2="0.5" y2="0.5" />
    </StreamlineSvg>
  ),


  // SavingBank: (props) => (
  //   <StreamlineSvg {...props}>
  //     <line x1="4.452" y1="20.927" x2="4.452" y2="11.927" />
  //     <line x1="3.452" y1="20.927" x2="9.452" y2="20.927" />
  //     <line x1="1.004" y1="22.927" x2="23.004" y2="22.927" />
  //     <line x1="3.452" y1="11.927" x2="9.452" y2="11.927" />
  //     <line x1="6.452" y1="20.927" x2="6.452" y2="11.927" />
  //     <line x1="8.452" y1="20.927" x2="8.452" y2="11.927" />
  //     <line x1="14.452" y1="20.927" x2="20.452" y2="20.927" />
  //     <line x1="14.452" y1="11.927" x2="20.452" y2="11.927" />
  //     <line x1="15.452" y1="20.927" x2="15.452" y2="11.927" />
  //     <line x1="17.452" y1="20.927" x2="17.452" y2="11.927" />
  //     <line x1="19.452" y1="20.927" x2="19.452" y2="11.927" />
  //     <path d="M2.1,9.032a.5.5,0,0,0,.308.895H21.6a.5.5,0,0,0,.326-.88L12.793,1.194a.5.5,0,0,0-.633-.015Z" />
  //   </StreamlineSvg>
  // ),

  Search: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="8.5" cy="8.5" r="8" />
      <line x1="14.156" y1="14.156" x2="23.5" y2="23.5" />
    </StreamlineSvg>
  ),

//   ShowTheater: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M1.5.5h21a1,1,0,0,1,1,1v2a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0v-2A1,1,0,0,1,1.5.5Z" />
//       <line x1="17.585" y1="22.5" x2="6.415" y2="22.5" />
//       <path d="M3.5,22a1.5,1.5,0,0,1-3,0V3.5" />
//       <path d="M6.5,3.5V22a1.5,1.5,0,0,1-3,0V7.465" />
//       <path d="M20.5,22a1.5,1.5,0,0,0,3,0V3.5" />
//       <path d="M17.5,3.5V22a1.5,1.5,0,0,0,3,0V7.465" />
//     </StreamlineSvg>
//   ),
// 
//   StampsMail: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="12" cy="12" r="3.5" />
//       <path d="M15.4,18.063A7.162,7.162,0,0,1,11.845,19a7,7,0,1,1-.039-14c9.5,0,8.982,10.5,4.944,10.5a1.25,1.25,0,0,1-1.25-1.25V9" />
//       <path d="M.5,4.5H1a1.5,1.5,0,0,1,0,3H.5v3H1a1.5,1.5,0,0,1,0,3H.5v3H1a1.5,1.5,0,0,1,0,3H.5v3a1,1,0,0,0,1,1h3V23a1.5,1.5,0,0,1,3,0v.5h3V23a1.5,1.5,0,0,1,3,0v.5h3V23a1.5,1.5,0,0,1,3,0v.5h3a1,1,0,0,0,1-1v-3H23a1.5,1.5,0,0,1,0-3h.5v-3H23a1.5,1.5,0,0,1,0-3h.5v-3H23a1.5,1.5,0,0,1,0-3h.5v-3a1,1,0,0,0-1-1h-3V1a1.5,1.5,0,0,1-3,0V.5h-3V1a1.5,1.5,0,0,1-3,0V.5h-3V1a1.5,1.5,0,0,1-3,0V.5h-3a1,1,0,0,0-1,1Z" />
//     </StreamlineSvg>
//   ),
// 
//   StartupLaunch1: (props) => (
//     <StreamlineSvg {...props}>
//       <line x1="9" y1="2" x2="9" y2="4" />
//       <line x1="10" y1="3" x2="8" y2="3" />
//       <line x1="5" y1="5" x2="5" y2="7" />
//       <line x1="6" y1="6" x2="4" y2="6" />
//       <line x1="20" y1="18" x2="18" y2="18" />
//       <line x1="19" y1="17" x2="19" y2="19" />
//       <path d="M7.051,20.444C6.058,21.437,3,21.5,3,21.5s.23-2.892,1.222-3.884,2.026-.1,2.475.353S8.043,19.451,7.051,20.444Z" />
//       <line x1="9.001" y1="11.593" x2="12.907" y2="15.499" />
//       <line x1="19.439" y1="5.061" x2="20.5" y2="4" />
//       <path d="M19.793,8.95l-.354-3.889L15.55,4.707a2.982,2.982,0,0,0-2.828,1.414L6.711,14.96l1.415,1.414L9.54,17.789l8.839-6.011A2.982,2.982,0,0,0,19.793,8.95Z" />
//       <path d="M9,11.593l-1.078-.412a2,2,0,0,0-2.129.454L3.837,13.592a.5.5,0,0,0,.255.844l2.619.524" />
//       <path d="M12.907,15.5l.412,1.078a2,2,0,0,1-.454,2.129l-1.957,1.957a.5.5,0,0,1-.844-.255L9.54,17.789" />
//       <circle cx="15.55" cy="8.95" r="1.5" />
//     </StreamlineSvg>
//   ),
// 
//   SyncHeart: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M12,15 L9.44322839,12.4598774 C8.98272286,12.0211923 8.86853388,11.3511074 9.159726,10.7962257 L9.159726,10.7962257 C9.37723896,10.3820896 9.7904901,10.0927632 10.2704104,10.0186107 C10.7503307,9.94445816 11.2384241,10.0945177 11.5822465,10.4219219 L12,10.8197983 L12.4177535,10.4219219 C12.7615759,10.0945177 13.2496693,9.94445816 13.7295896,10.0186107 C14.2095099,10.0927632 14.622761,10.3820896 14.840274,10.7962257 L14.840274,10.7962257 C15.1314661,11.3511074 15.0172771,12.0211923 14.5567716,12.4598774 L12,15 Z" />
//       <polyline points="0.714 10.353 3.501 14.503 6.706 10.665" />
//       <polyline points="23.286 14.655 20.501 10.503 17.294 14.342" />
//       <path d="M20.464,10.54 C21.1653222,14.9849276 18.330348,19.2217117 13.954,20.269 C11.1036981,20.9450436 8.10620701,20.1044913 6.023,18.045" />
//       <path d="M3.505,14.479 C2.93329712,12.1853457 3.3026107,9.7583119 4.53067588,7.73852222 C5.75874106,5.71873254 7.74355537,4.27394966 10.043,3.726 C13.0926051,3.00336504 16.2923354,4.01894498 18.367,6.368" />
//     </StreamlineSvg>
//   ),
// 
//   TaskChecklistCheck: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M10,23.5H1.5a1,1,0,0,1-1-1V3.5a1,1,0,0,1,1-1h2" />
//       <path d="M15.5,2.5h2a1,1,0,0,1,1,1v6" />
//       <path d="M11.5,2.5a2,2,0,0,0-4,0h-2v3h8v-3Z" />
//       <path d="M16.5,9.5V5a.5.5,0,0,0-.5-.5H13.5" />
//       <path d="M5.5,4.5H3a.5.5,0,0,0-.5.5V21a.5.5,0,0,0,.5.5h7" />
//       <line x1="5.5" y1="8.499" x2="13.5" y2="8.499" />
//       <line x1="5.5" y1="11.499" x2="11.5" y2="11.499" />
//       <line x1="5.5" y1="14.499" x2="9.5" y2="14.499" />
//       <circle cx="17.5" cy="17.499" r="6" />
//       <path d="M20.174,15.755l-2.905,3.874a.75.75,0,0,1-1.13.08l-1.5-1.5" />
//     </StreamlineSvg>
//   ),
// 
//   TeamworkHands: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M6.854,9.838a1.13,1.13,0,0,0-2.2-.481c-.395,1.21-1.078,3.371-1.4,4.825a3.368,3.368,0,0,0,.052,1.673L.781,18.367" />
//       <line x1="2.785" y1="23.192" x2="6.54" y2="19.462" />
//       <path d="M16.805,8.422c-.463-.864-.968-1.766-1.4-2.447a3.385,3.385,0,0,0-1.219-1.146L14.2.766" />
//       <path d="M9.367.77,9.349,4.563A3.394,3.394,0,0,0,7.218,6.428" />
//       <path d="M9,12.134A1.03,1.03,0,0,0,7.545,13.59l-.017.017,3.784,3.713-1.447-.159a1.13,1.13,0,0,0-.481,2.2c1.209.395,3.371,1.077,4.825,1.4a3.38,3.38,0,0,0,1.673-.053l2.512,2.528" />
//       <path d="M12.643,12.924l-2.5-2.558a1.03,1.03,0,0,0-1.456,1.456l2.395,2.345" />
//       <path d="M10.457,10.678a1.03,1.03,0,1,1,1.456-1.456l2.395,2.512" />
//       <path d="M23.219,21.231l-3.73-3.755a3.363,3.363,0,0,0-.567-3.986l-4.959-4.9,0,0A.976.976,0,0,0,12.4,9.713" />
//     </StreamlineSvg>
//   ),
// 
//   UserMultipleNeutral2: (props) => (
//     <StreamlineSvg {...props}>
//       <path d="M14.407,19.385,14.75,23.5h4l.5-6h2.5V14a5,5,0,0,0-8-4" />
//       <circle cx="16.75" cy="4" r="3.5" />
//       <path d="M9.75,23.5l.5-6h2.5V14a5,5,0,0,0-10,0v3.5h2.5l.5,6Z" />
//       <circle cx="7.75" cy="4" r="3.5" />
//     </StreamlineSvg>
//   ),
// 
//   UserSingleNeutralCircle: (props) => (
//     <StreamlineSvg {...props}>
//       <circle cx="12" cy="12" r="11.5" />
//       <path d="M3.959,20.221a25.59,25.59,0,0,1,5.413-2.352c.837-.309.928-2.229.328-2.889-.866-.953-1.6-2.07-1.6-4.766A3.812,3.812,0,0,1,12,6.047a3.812,3.812,0,0,1,3.9,4.167c0,2.7-.734,3.813-1.6,4.766-.6.66-.509,2.58.328,2.889a25.59,25.59,0,0,1,5.413,2.352" />
//     </StreamlineSvg>
//   ),
































  /*
   * ================================================================================
   * Smilies
   * ================================================================================
   */

  SmileyAngel: (props) => (
    <StreamlineSvg {...props}>
      <path d="M9.5,9.664a2.359,2.359,0,0,0-4,0" />
      <path d="M14.5,9.664a2.359,2.359,0,0,1,4,0" />
      <path d="M6,15.556a6.5,6.5,0,0,0,12,0" />
      <circle cx="12" cy="12.056" r="11.5" />
      <path d="M17.274,1.834c3.7.416,6.226,1.255,6.226,2.222,0,1.381-5.149,2.5-11.5,2.5S.5,5.437.5,4.056c0-.967,2.528-1.806,6.226-2.222" />
    </StreamlineSvg>
  ),

  // SmileyAngry: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M17.035,6.615a7.756,7.756,0,0,1-10.07.056" />
  //     <path d="M15,4.877A4.62,4.62,0,0,1,9,4.91" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,10.25A.25.25,0,1,1,6.75,10a.25.25,0,0,1-.25.25" />
  //     <path d="M17.5,10.25a.25.25,0,1,1,.25-.25.25.25,0,0,1-.25.25" />
  //     <path d="M12,14.5A1.5,1.5,0,0,0,10.5,16v2a1.5,1.5,0,0,0,3,0V16A1.5,1.5,0,0,0,12,14.5Z" />
  //   </StreamlineSvg>
  // ),

  // SmileyBad: (props) => (
  //   <StreamlineSvg {...props}>
  //     <polyline points="6.5 9.5 9.5 9.5 7 7" />
  //     <polyline points="17.5 9.5 14.5 9.5 17 7" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M15.873,18.5a1,1,0,0,0,.97-1.249,5,5,0,0,0-9.686,0,1,1,0,0,0,.97,1.249Z" />
  //   </StreamlineSvg>
  // ),

  SmileyBlessed: (props) => (
    <StreamlineSvg {...props}>
      <path d="M15.873,15.5a1,1,0,0,1,.97,1.249,5,5,0,0,1-9.686,0,1,1,0,0,1,.97-1.249Z" />
      <path d="M14.5,9.392a2.359,2.359,0,0,0,4,0" />
      <path d="M9.5,9.392a2.359,2.359,0,0,1-4,0" />
      <circle cx="12" cy="12" r="11.5" />
    </StreamlineSvg>
  ),

  // SmileyBlush: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M8.5,7.75A.25.25,0,1,1,8.25,8a.25.25,0,0,1,.25-.25" />
  //     <path d="M15.5,7.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M9,18.5a4.38,4.38,0,0,1,3-1,4.38,4.38,0,0,1,3,1" />
  //     <line x1="14.5" y1="14" x2="16.5" y2="12" />
  //     <line x1="17.5" y1="14" x2="19.5" y2="12" />
  //     <line x1="4.5" y1="14" x2="6.5" y2="12" />
  //     <line x1="7.5" y1="14" x2="9.5" y2="12" />
  //   </StreamlineSvg>
  // ),

  // SmileyBlushing: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M15,17a1.5,1.5,0,0,1-3,0,1.5,1.5,0,0,1-3,0" />
  //     <path d="M8.5,6.5A3.564,3.564,0,0,1,5.5,8" />
  //     <path d="M15.5,6.5a3.564,3.564,0,0,0,3,1.5" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <line x1="14.5" y1="14" x2="16.5" y2="12" />
  //     <line x1="17.5" y1="14" x2="19.5" y2="12" />
  //     <line x1="4.5" y1="14" x2="6.5" y2="12" />
  //     <line x1="7.5" y1="14" x2="9.5" y2="12" />
  //   </StreamlineSvg>
  // ),

  SmileyBright: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <path d="M7,7A2.5,2.5,0,0,0,9.5,9.5,2.5,2.5,0,0,0,7,12,2.5,2.5,0,0,0,4.5,9.5,2.5,2.5,0,0,0,7,7" />
      <path d="M17,7a2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1,17,12a2.5,2.5,0,0,1,2.5-2.5A2.5,2.5,0,0,1,17,7" />
      <path d="M10,19.5h4a3,3,0,0,0,3-3" />
      {/* <path d="M19,17a1.415,1.415,0,0,0,0-2" /> */}
    </StreamlineSvg>
  ),

  SmileyCheeky: (props) => (
    <StreamlineSvg {...props}>
      <path d="M17.708,16.053A6.991,6.991,0,0,1,12,19" />
      <line x1="5.5" y1="8.5" x2="8.5" y2="8.5" />
      <line x1="12.5" y1="8.5" x2="15.5" y2="8.5" />
      <circle cx="12" cy="12" r="11.5" />
    </StreamlineSvg>
  ),

  SmileyCheerful: (props) => (
    <StreamlineSvg {...props}>
      <path d="M9.5,9.608a2.359,2.359,0,0,0-4,0" />
      <path d="M14.5,9.608a2.359,2.359,0,0,1,4,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M15,17a1.5,1.5,0,0,1-3,0,1.5,1.5,0,0,1-3,0" />
    </StreamlineSvg>
  ),

  SmileyConcerned: (props) => (
    <StreamlineSvg {...props}>
      <path d="M17.5,10.25a.25.25,0,1,0-.25-.25.25.25,0,0,0,.25.25" />
      <path d="M18,7l-1.643.986A3.615,3.615,0,0,1,14.5,8.5h-1" />
      <path d="M6.5,10.25A.25.25,0,1,1,6.75,10a.25.25,0,0,1-.25.25" />
      <path d="M8.5,7.5a2.5,2.5,0,0,0-4,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M10.5,19.611a6.8,6.8,0,0,1,7-3" />
    </StreamlineSvg>
  ),

  SmileyCowboy: (props) => (
    <StreamlineSvg {...props}>
      <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
      <path d="M16.677,15.5a.313.313,0,0,1,.312.333,5,5,0,0,1-9.958.223.5.5,0,0,1,.5-.556Z" />
      <path d="M20,6.5,18.269,2.346A3,3,0,0,0,15.5.5h-7A3,3,0,0,0,5.731,2.346L4,6.5" />
      <path d="M22.914,3.086A2,2,0,0,1,21.5,6.5H2.5A2,2,0,0,1,1.086,3.086" />
      <path d="M2.511,8.5a10.5,10.5,0,1,0,18.978,0" />
    </StreamlineSvg>
  ),

  SmileyCrazyTongue: (props) => (
    <StreamlineSvg {...props}>
      <path d="M6.5,9.5H8.293a.5.5,0,0,0,.353-.854L7,7" />
      <path d="M17.5,9.5H15.707a.5.5,0,0,1-.353-.854L17,7" />
      <path d="M14.5,19.806a5.017,5.017,0,0,0,2.339-3.052,1,1,0,0,0-.968-1.254H8.129a1,1,0,0,0-.968,1.254A5.017,5.017,0,0,0,9.5,19.806" />
      <path d="M14.5,21a2.5,2.5,0,0,1-5,0V18.5a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1Z" />
      <path d="M16.5,22.585a11.5,11.5,0,1,0-9,0" />
    </StreamlineSvg>
  ),

  SmileyCrazy: (props) => (
    <StreamlineSvg {...props}>
      <path d="M14.5,19.806a5.017,5.017,0,0,0,2.339-3.052,1,1,0,0,0-.968-1.254H8.129a1,1,0,0,0-.968,1.254A5.017,5.017,0,0,0,9.5,19.806" />
      <path d="M14.5,21a2.5,2.5,0,0,1-5,0V18.5a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1Z" />
      <path d="M16.5,22.585a11.5,11.5,0,1,0-9,0" />
      <path d="M14.5,9.608a2.359,2.359,0,0,1,4,0" />
      <circle cx="7" cy="9" r="2.5" />
      <path d="M7,8.75A.25.25,0,1,0,7.25,9,.25.25,0,0,0,7,8.75" />
    </StreamlineSvg>
  ),

  SmileyCryingRainbow: (props) => (
    <StreamlineSvg {...props}>
      <path d="M21.8,18A11.5,11.5,0,1,0,.5,12a11.429,11.429,0,0,0,1.7,6" />
      <path d="M18.8,10.108a2.359,2.359,0,0,0-4,0" />
      <path d="M9.5,10.108a2.359,2.359,0,0,0-4,0" />
      <path d="M12,14.5A1.5,1.5,0,0,0,10.5,16v2a1.5,1.5,0,0,0,3,0V16A1.5,1.5,0,0,0,12,14.5Z" />
      <line x1="4.5" y1="12.5" x2="4.5" y2="19.506" />
      <line x1="7.5" y1="12.5" x2="7.5" y2="19.506" />
      <path d="M9,23.5a1.5,1.5,0,1,0-.656-2.843,2.486,2.486,0,0,0-4.688,0A1.5,1.5,0,1,0,3,23.5Z" />
      <line x1="19.5" y1="12.5" x2="19.5" y2="19.506" />
      <line x1="16.5" y1="12.5" x2="16.5" y2="19.506" />
      <path d="M15,23.5a1.5,1.5,0,1,1,.656-2.843,2.486,2.486,0,0,1,4.688,0A1.5,1.5,0,1,1,21,23.5Z" />
    </StreamlineSvg>
  ),

  // SmileyCrying: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M6.035,9.07A7.5,7.5,0,0,1,8.105,7" />
  //     <path d="M18.509,9.07A7.535,7.535,0,0,0,16.439,7" />
  //     <path d="M21.76,18.5a11.5,11.5,0,1,1,1.468-10" />
  //     <path d="M22.605,14.167a1.834,1.834,0,0,1-3.667,0c0-1.013,1.834-3.667,1.834-3.667S22.605,13.154,22.605,14.167Z" />
  //     <path d="M7.772,18a4.5,4.5,0,1,1,9,0" />
  //   </StreamlineSvg>
  // ),

  // SmileyDecode: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M6.5,10.25A.25.25,0,1,1,6.75,10a.25.25,0,0,1-.25.25" />
  //     <path d="M17.5,10.25a.25.25,0,1,0-.25-.25.25.25,0,0,0,.25.25" />
  //     <path d="M8.5,8.5c-1-2-4-2-4-2" />
  //     <path d="M15.5,8.5c1-2,4-2,4-2" />
  //     <rect x="0.5" y="13.5" width="23" height="7" rx="1" ry="1" />
  //     <rect x="2.5" y="15.5" width="2" height="3" />
  //     <line x1="8.5" y1="18.5" x2="6.5" y2="18.5" />
  //     <line x1="7.5" y1="18.5" x2="7.5" y2="15.5" />
  //     <line x1="19.5" y1="15.5" x2="21.5" y2="18.5" />
  //     <line x1="19.5" y1="18.5" x2="21.5" y2="15.5" />
  //     <polyline points="10.5 18.5 12 15.5 13.5 18.5" />
  //     <circle cx="16.5" cy="16.5" r="1" />
  //     <line x1="16.5" y1="17.5" x2="16.5" y2="18.5" />
  //     <path d="M23.489,11.5a11.5,11.5,0,0,0-22.978,0" />
  //     <path d="M7.3,22.5a11.526,11.526,0,0,0,9.392,0" />
  //   </StreamlineSvg>
  // ),

  // SmileyDevastated1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M7.894,19.225a1,1,0,0,1-1.285-1.363,6,6,0,0,1,10.782,0,1,1,0,0,1-1.285,1.363A10.565,10.565,0,0,0,12,18.5,10.565,10.565,0,0,0,7.894,19.225Z" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M14.5,9.392a2.359,2.359,0,0,0,4,0" />
  //     <path d="M9.5,9.392a2.359,2.359,0,0,1-4,0" />
  //     <path d="M7,7s2.5,0,3-2" />
  //     <path d="M17,7s-2.5,0-3-2" />
  //   </StreamlineSvg>
  // ),

  // SmileyDevastated: (props) => (
  //   <StreamlineSvg {...props}>
  //     <polyline points="6.5 9.5 9.5 9.5 7 7" />
  //     <polyline points="17.5 9.5 14.5 9.5 17 7" />
  //     <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
  //     <circle cx="12" cy="12" r="11.5" />
  //   </StreamlineSvg>
  // ),

  // SmileyDisappointed1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M4.5,8a3.564,3.564,0,0,1,3-1.5" />
  //     <path d="M19.5,8a3.564,3.564,0,0,0-3-1.5" />
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M16.134,18.22a4.5,4.5,0,0,0-8.268,0,.739.739,0,0,0,1.01.953A6.446,6.446,0,0,1,12,18.5a6.446,6.446,0,0,1,3.124.673.739.739,0,0,0,1.01-.953Z" />
  //     <circle cx="12" cy="12" r="11.5" />
  //   </StreamlineSvg>
  // ),

  // SmileyDisappointed2: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M4.5,8a3.564,3.564,0,0,1,3-1.5" />
  //     <path d="M19.5,8a3.564,3.564,0,0,0-3-1.5" />
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M16.134,18.22a4.5,4.5,0,0,0-8.268,0,.739.739,0,0,0,1.01.953A6.446,6.446,0,0,1,12,18.5a6.446,6.446,0,0,1,3.124.673.739.739,0,0,0,1.01-.953Z" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <line x1="10.5" y1="12.5" x2="11" y2="11.5" />
  //     <line x1="13.5" y1="12.5" x2="13" y2="11.5" />
  //   </StreamlineSvg>
  // ),

  // SmileyDisappointedMad: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M8.5,7.5a2.5,2.5,0,0,0-4,0" />
  //     <path d="M15.5,7.5a2.5,2.5,0,0,1,4,0" />
  //   </StreamlineSvg>
  // ),

  SmileyDisappointed: (props) => (
    <StreamlineSvg {...props}>
      <line x1="9.5" y1="18.5" x2="14.5" y2="18.5" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M14.5,12.392a2.359,2.359,0,0,0,4,0" />
      <path d="M9.5,12.392a2.359,2.359,0,0,1-4,0" />
      <line x1="4.5" y1="9.5" x2="8.5" y2="7.5" />
      <line x1="19.5" y1="9.5" x2="15.5" y2="7.5" />
    </StreamlineSvg>
  ),

  SmileyDizzy: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <line x1="7.5" y1="15.5" x2="16.5" y2="15.5" />
      <path d="M12.5,15.5v3a1.5,1.5,0,0,0,3,0v-3" />
      <path d="M7,11A2.5,2.5,0,0,1,7,6,2,2,0,0,1,9,8,1,1,0,0,1,7,8" />
      <path d="M17,11a2.5,2.5,0,0,1,0-5,2,2,0,0,1,2,2,1,1,0,0,1-2,0" />
    </StreamlineSvg>
  ),

  // SmileyDrool1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M3.5,8.5S4,6,7,6.5" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M14.5,18.737V22.5a1,1,0,0,0,2,0V19.3" />
  //     <path d="M7.894,19.225a1,1,0,0,1-1.285-1.363,6,6,0,0,1,10.782,0,1,1,0,0,1-1.285,1.363A10.565,10.565,0,0,0,12,18.5,10.565,10.565,0,0,0,7.894,19.225Z" />
  //     <path d="M18.357,21.584A11.5,11.5,0,1,0,12,23.5q.335,0,.665-.02" />
  //     <path d="M16.5,7.5a2.648,2.648,0,0,1,4,1.5" />
  //   </StreamlineSvg>
  // ),

  // SmileyDrool: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M5.5,8a3.564,3.564,0,0,1,3-1.5" />
  //     <path d="M18.5,8a3.564,3.564,0,0,0-3-1.5" />
  //     <path d="M11.5,20.475a5,5,0,0,1-4.343-3.726,1,1,0,0,1,.97-1.249h7.746a1,1,0,0,1,.97,1.249A5,5,0,0,1,15.5,19.071" />
  //     <path d="M12.371,18.886A1.148,1.148,0,0,0,11.5,20v.5a1,1,0,0,0,2,0v2a1,1,0,0,0,2,0V18a.47.47,0,0,0-.754-.375A7.255,7.255,0,0,1,12.371,18.886Z" />
  //     <path d="M17.5,22.1a11.522,11.522,0,1,0-6,1.374" />
  //   </StreamlineSvg>
  // ),

  SmileyDrop: (props) => (
    <StreamlineSvg {...props}>
      <path d="M17.5,1.9a11.5,11.5,0,1,0,6,10.1,11.587,11.587,0,0,0-.109-1.5" />
      <path d="M15.873,15.5a1,1,0,0,1,.97,1.249,5,5,0,0,1-9.686,0,1,1,0,0,1,.97-1.249Z" />
      <path d="M18.8,10.108a2.359,2.359,0,0,0-4,0" />
      <path d="M9.5,10.108a2.359,2.359,0,0,0-4,0" />
      <path d="M23.5,6a2.5,2.5,0,0,1-5,0C18.5,4.619,21,.5,21,.5S23.5,4.619,23.5,6Z" />
    </StreamlineSvg>
  ),

  // SmileyEvil: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M17.5,17.574l-1.053-2.106a.5.5,0,0,0-.894,0l-1.106,2.211a.5.5,0,0,1-.894,0l-1.106-2.211a.5.5,0,0,0-.894,0l-1.106,2.211a.5.5,0,0,1-.894,0L8.447,15.468a.5.5,0,0,0-.894,0L6.5,17.574" />
  //     <circle cx="12" cy="12.055" r="11.5" />
  //     <line x1="6" y1="9.055" x2="9.5" y2="11.055" />
  //     <line x1="18" y1="9.055" x2="14.5" y2="11.055" />
  //   </StreamlineSvg>
  // ),

  SmileyExplosion: (props) => (
    <StreamlineSvg {...props}>
      <path d="M21.2,5a3.034,3.034,0,0,0-3.067-3,3.077,3.077,0,0,0-1.847.62,5.392,5.392,0,0,0-8.572,0A3.077,3.077,0,0,0,5.867,2,3.034,3.034,0,0,0,2.8,5" />
      <path d="M2.8,5a2.251,2.251,0,1,0,0,4.5H5.5" />
      <path d="M21.2,5a2.251,2.251,0,1,1,0,4.5H18.5" />
      <path d="M8.5,7.5V9.366A3.134,3.134,0,0,1,5.366,12.5" />
      <path d="M15.5,7.5V9.366A3.134,3.134,0,0,0,18.634,12.5" />
      <line x1="10.5" y1="8.5" x2="10.5" y2="10.5" />
      <line x1="13.5" y1="8.5" x2="13.5" y2="10.5" />
      <path d="M8.5,15.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M15.5,15.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M12,17.5A1.5,1.5,0,0,0,10.5,19v1a1.5,1.5,0,0,0,3,0V19A1.5,1.5,0,0,0,12,17.5Z" />
      <path d="M18.634,12.5S18,13.5,12,13.5s-6.634-1-6.634-1a7.5,7.5,0,1,0,13.268,0Z" />
    </StreamlineSvg>
  ),

  SmileyEyesOnly: (props) => (
    <StreamlineSvg {...props}>
      <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
      <circle cx="12" cy="12" r="11.5" />
    </StreamlineSvg>
  ),

  // SmileyFever: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
  //     <path d="M4.5,8a3.564,3.564,0,0,1,3-1.5" />
  //     <path d="M19.5,8a3.564,3.564,0,0,0-3-1.5" />
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M16.132,17.222l6.959-5.1a1,1,0,1,0-1.182-1.614l-6.971,5.105" />
  //     <line x1="19.489" y1="12.285" x2="20.67" y2="13.898" />
  //     <path d="M22.771,8a11.492,11.492,0,1,0,.448,6.5" />
  //   </StreamlineSvg>
  // ),

  SmileyGlasses: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <path d="M15.873,15.5a1,1,0,0,1,.97,1.249,5,5,0,0,1-9.686,0,1,1,0,0,1,.97-1.249Z" />
      <line x1="10.5" y1="9.5" x2="13.5" y2="9.5" />
      <rect x="4.5" y="7.5" width="6" height="4" rx="1" ry="1" />
      <rect x="13.5" y="7.5" width="6" height="4" rx="1" ry="1" />
    </StreamlineSvg>
  ),

  SmileyGrin: (props) => (
    <StreamlineSvg {...props}>
      {/* <path d="M7.5,19a4.5,4.5,0,0,1,9,0" /> */}
      {/* <path d="M5.369,13.5a7.5,7.5,0,0,0,13.262,0" /> */}
      <path d="M6,15.5a6.5,6.5,0,0,0,12,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M15,5.5a2.5,2.5,0,0,1,4,0" />
      <line x1="5.5" y1="9.5" x2="8.5" y2="9.5" />
      <path d="M8.5,10.25A.25.25,0,1,1,8.75,10a.25.25,0,0,1-.25.25" />
      <line x1="15.5" y1="9.5" x2="18.5" y2="9.5" />
      <path d="M18.5,10.25a.25.25,0,1,1,.25-.25.25.25,0,0,1-.25.25" />
      <path d="M5,5.5a2.5,2.5,0,0,1,4,0" />
    </StreamlineSvg>
  ),

  SmileyHappy1: (props) => (
    <StreamlineSvg {...props}>
      <path d="M5.369,13.5a7.5,7.5,0,0,0,13.262,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M5.5,8a3.564,3.564,0,0,1,3-1.5" />
      <path d="M18.5,8a3.564,3.564,0,0,0-3-1.5" />
    </StreamlineSvg>
  ),

  SmileyHappy: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
      <path d="M15.873,15.5a1,1,0,0,1,.97,1.249,5,5,0,0,1-9.686,0,1,1,0,0,1,.97-1.249Z" />
    </StreamlineSvg>
  ),

  // SmileyHeadPatch: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M16.5,7.5a3.564,3.564,0,0,0,3,1.5" />
  //     <path d="M6.5,11.25A.25.25,0,1,1,6.75,11a.25.25,0,0,1-.25.25" />
  //     <path d="M17.5,11.25a.25.25,0,1,0-.25-.25.25.25,0,0,0,.25.25" />
  //     <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
  //     <path d="M4.207,1.379,1.379,4.207A3,3,0,0,0,5.621,8.45L8.45,5.621A3,3,0,1,0,4.207,1.379Z" />
  //     <rect x="3.414" y="3.914" width="3" height="2" transform="translate(-2.036 4.914) rotate(-45)" />
  //     <path d="M.6,10.5A11.49,11.49,0,1,0,10.5.6" />
  //   </StreamlineSvg>
  // ),

  // SmileyHeadSick: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
  //     <path d="M17.307,6.5A3.563,3.563,0,0,0,20,8.5" />
  //     <path d="M6.5,10.25A.25.25,0,1,1,6.75,10a.25.25,0,0,1-.25.25" />
  //     <path d="M17.5,10.25a.25.25,0,1,0-.25-.25.25.25,0,0,0,.25.25" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <line x1="12.195" y1="3.5" x2="15.713" y2="1.113" />
  //     <line x1="7.829" y1="3.5" x2="12.246" y2="0.503" />
  //     <path d="M1.9,6.5H22.1a11.558,11.558,0,0,0-2.356-3H4.254A11.558,11.558,0,0,0,1.9,6.5Z" />
  //     <line x1="0.514" y1="11.427" x2="7.774" y2="6.5" />
  //   </StreamlineSvg>
  // ),

  SmileyHypnotized: (props) => (
    <StreamlineSvg {...props}>
      {/* <line x1="9.5" y1="18.5" x2="14.5" y2="18.5" /> */}
      <path d="M15.873,15.5a1,1,0,0,1,.97,1.249,5,5,0,0,1-9.686,0,1,1,0,0,1,.97-1.249Z" />
      <path d="M4.5,7a3.564,3.564,0,0,1,3-1.5" />
      <path d="M19.5,7a3.564,3.564,0,0,0-3-1.5" />
      <circle cx="12" cy="12" r="11.5" />
      <circle cx="7" cy="11" r="2.5" />
      <path d="M7,10.75a.25.25,0,1,1-.25.25A.25.25,0,0,1,7,10.75" />
      <circle cx="17" cy="11" r="2.5" />
      <path d="M17,10.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
    </StreamlineSvg>
  ),

  SmileyInLove: (props) => (
    <StreamlineSvg {...props}>
      <path d="M6,15.5a6.5,6.5,0,0,0,12,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M7.143,11.5,4.464,8.706a1.586,1.586,0,0,1-.3-1.83h0a1.586,1.586,0,0,1,2.539-.411l.437.437.438-.437a1.585,1.585,0,0,1,2.538.411h0a1.585,1.585,0,0,1-.3,1.83Z" />
      <path d="M16.857,11.5l2.679-2.795a1.586,1.586,0,0,0,.3-1.83h0a1.586,1.586,0,0,0-2.539-.411l-.437.437-.438-.437a1.585,1.585,0,0,0-2.538.411h0a1.586,1.586,0,0,0,.3,1.83Z" />
    </StreamlineSvg>
  ),

  // SmileyInTrouble: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M17.5,1.9a11.5,11.5,0,1,0,6,10.1,11.587,11.587,0,0,0-.109-1.5" />
  //     <path d="M23.5,6a2.5,2.5,0,0,1-5,0C18.5,4.619,21,.5,21,.5S23.5,4.619,23.5,6Z" />
  //     <path d="M8.5,7.75A.25.25,0,1,1,8.25,8a.25.25,0,0,1,.25-.25" />
  //     <path d="M15.5,7.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
  //   </StreamlineSvg>
  // ),

  // SmileyIndiferent1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <line x1="5.5" y1="15.5" x2="18.5" y2="15.5" />
  //     <line x1="5.5" y1="8.5" x2="8.5" y2="8.5" />
  //     <line x1="18.5" y1="8.5" x2="15.5" y2="8.5" />
  //   </StreamlineSvg>
  // ),

  // SmileyIndifferent: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M8.5,7.75A.25.25,0,1,1,8.25,8a.25.25,0,0,1,.25-.25" />
  //     <path d="M15.5,7.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <line x1="5.5" y1="15.5" x2="18.5" y2="15.5" />
  //   </StreamlineSvg>
  // ),

  SmileyKiss1: (props) => (
    <StreamlineSvg {...props}>
      <path d="M12.5,18.5h2a1,1,0,0,0,0-2,1,1,0,0,0,0-2h-2" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
    </StreamlineSvg>
  ),

  SmileyKiss2: (props) => (
    <StreamlineSvg {...props}>
      <path d="M12.5,18.5h2a1,1,0,0,0,0-2,1,1,0,0,0,0-2h-2" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M18.8,10.108a2.359,2.359,0,0,0-4,0" />
      <path d="M9.5,10.108a2.359,2.359,0,0,0-4,0" />
    </StreamlineSvg>
  ),

  // SmileyKissBlush: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M12.5,19.5h2a1,1,0,0,0,0-2,1,1,0,0,0,0-2h-2" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M5.5,8a3.564,3.564,0,0,1,3-1.5" />
  //     <path d="M18.5,8a3.564,3.564,0,0,0-3-1.5" />
  //     <line x1="14.5" y1="13.5" x2="16.5" y2="11.5" />
  //     <line x1="17.5" y1="13.5" x2="19.5" y2="11.5" />
  //     <line x1="4.5" y1="13.5" x2="6.5" y2="11.5" />
  //     <line x1="7.5" y1="13.5" x2="9.5" y2="11.5" />
  //   </StreamlineSvg>
  // ),

  SmileyKissHeart: (props) => (
    <StreamlineSvg {...props}>
      <path d="M7.25,9.251A.25.25,0,1,1,7,9.5a.25.25,0,0,1,.25-.25" />
      <path d="M14.5,9.608a2.359,2.359,0,0,1,4,0" />
      <path d="M12.5,18.5h2a1,1,0,0,0,0-2,1,1,0,0,0,0-2h-2" />
      <path d="M19.1,23.5l-1.548-3.548a1.583,1.583,0,0,1,.353-1.819h0a1.585,1.585,0,0,1,2.524.49l.26.561.561-.259a1.585,1.585,0,0,1,2.24,1.263h0a1.586,1.586,0,0,1-.91,1.615Z" />
      <path d="M22.484,16.708a11.512,11.512,0,1,0-6.9,6.215" />
    </StreamlineSvg>
  ),

  SmileyKiss: (props) => (
    <StreamlineSvg {...props}>
      <path d="M14.5,9.392a2.359,2.359,0,0,0,4,0" />
      <path d="M9.5,9.392a2.359,2.359,0,0,1-4,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M13.5,18.5h2a1,1,0,0,0,2,0v-3a1,1,0,0,0-2,0h-2" />
    </StreamlineSvg>
  ),

  // SmileyLiar: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M4.5,8a3.564,3.564,0,0,1,3-1.5" />
  //     <path d="M19.5,8a3.564,3.564,0,0,0-3-1.5" />
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M16.134,19.22a4.5,4.5,0,0,0-8.268,0,.739.739,0,0,0,1.01.953A6.446,6.446,0,0,1,12,19.5a6.446,6.446,0,0,1,3.124.673.739.739,0,0,0,1.01-.953Z" />
  //     <path d="M23.4,10.5a11.5,11.5,0,1,0-.817,6" />
  //     <path d="M12.5,14.5h10a1,1,0,0,0,0-2h-10" />
  //   </StreamlineSvg>
  // ),

  SmileyLolSide: (props) => (
    <StreamlineSvg {...props}>
      <path d="M6.343,14.121l1.268-1.267A.5.5,0,0,0,7.257,12H4.929" />
      <path d="M14.121,6.343,12.854,7.611A.5.5,0,0,1,12,7.257V4.929" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M17.912,10.694a.5.5,0,0,1,.909-.164,6,6,0,0,1-8.291,8.291.5.5,0,0,1,.164-.909,9.3,9.3,0,0,0,4.488-2.73A9.3,9.3,0,0,0,17.912,10.694Z" />
    </StreamlineSvg>
  ),

  SmileyLolSideways: (props) => (
    <StreamlineSvg {...props}>
      <path d="M19.637,3.422A11.484,11.484,0,0,0,3.422,19.637" />
      <path d="M23.167,9.3A11.475,11.475,0,0,1,9.3,23.167" />
      <path d="M8.177,20.584a1.5,1.5,0,0,1-2.956.514c-.142-.816.964-3.212.964-3.212S8.035,19.768,8.177,20.584Z" />
      <path d="M20.584,8.177A1.5,1.5,0,1,0,21.1,5.221c-.816-.142-3.212.964-3.212.964S19.768,8.035,20.584,8.177Z" />
      <path d="M17.914,10.7a.5.5,0,0,1,.909-.163,6,6,0,0,1-8.29,8.29.5.5,0,0,1,.163-.909,9.294,9.294,0,0,0,4.488-2.73A9.294,9.294,0,0,0,17.914,10.7Z" />
      <polyline points="6.345 14.124 8.467 12.002 4.931 12.002" />
      <polyline points="14.124 6.346 12.002 8.467 12.002 4.931" />
    </StreamlineSvg>
  ),

  SmileyLol: (props) => (
    <StreamlineSvg {...props}>
      <path d="M23.466,11.331a11.485,11.485,0,0,0-22.932,0" />
      <path d="M21.809,17.981a11.476,11.476,0,0,1-19.618,0" />
      <path d="M18.8,10.108a2.359,2.359,0,0,0-4,0" />
      <path d="M9.5,10.108a2.359,2.359,0,0,0-4,0" />
      <path d="M3.227,15.363A1.5,1.5,0,1,1,.773,13.637c.477-.678,2.954-1.59,2.954-1.59S3.7,14.686,3.227,15.363Z" />
      <path d="M20.773,15.363a1.5,1.5,0,1,0,2.454-1.726c-.477-.678-2.954-1.59-2.954-1.59S20.3,14.686,20.773,15.363Z" />
      <path d="M17.1,15.256a.5.5,0,0,1,.758.528,6,6,0,0,1-11.724,0,.5.5,0,0,1,.758-.528A9.292,9.292,0,0,0,12,16.5,9.292,9.292,0,0,0,17.1,15.256Z" />
    </StreamlineSvg>
  ),

  SmileyLookOneEye: (props) => (
    <StreamlineSvg {...props}>
      <line x1="8.5" y1="15.5" x2="15.5" y2="15.5" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M4.5,8.5A1.85,1.85,0,0,1,6,7.5c1,0,1.5,1,2.5,1a1.85,1.85,0,0,0,1.5-1" />
      <circle cx="17" cy="10" r="2.5" />
      <path d="M17,9.75a.25.25,0,1,0,.25.25A.25.25,0,0,0,17,9.75" />
      <path d="M19,5.5a2.5,2.5,0,0,0-4,0" />
      <line x1="5.5" y1="11.5" x2="8.5" y2="11.5" />
    </StreamlineSvg>
  ),

  // SmileyMad1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,10.25A.25.25,0,1,1,6.75,10a.25.25,0,0,1-.25.25" />
  //     <path d="M17.5,10.25a.25.25,0,1,0-.25-.25.25.25,0,0,0,.25.25" />
  //     <path d="M12,14.5A1.5,1.5,0,0,0,10.5,16v2a1.5,1.5,0,0,0,3,0V16A1.5,1.5,0,0,0,12,14.5Z" />
  //     <path d="M8.5,7.5a2.5,2.5,0,0,0-4,0" />
  //     <path d="M15.5,7.5a2.5,2.5,0,0,1,4,0" />
  //   </StreamlineSvg>
  // ),

  // SmileyMad: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <line x1="6" y1="9" x2="9.5" y2="11" />
  //     <line x1="18" y1="9" x2="14.5" y2="11" />
  //   </StreamlineSvg>
  // ),

  SmileyNasty: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <line x1="6" y1="9" x2="9.5" y2="11" />
      <line x1="18" y1="9" x2="14.5" y2="11" />
      <path d="M18,14.5A6.5,6.5,0,0,1,9.5,18" />
    </StreamlineSvg>
  ),

  // SmileyNauseous1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M7.5,6.5A3.564,3.564,0,0,1,4.5,8" />
  //     <path d="M6.5,10.25A.25.25,0,1,1,6.75,10a.25.25,0,0,1-.25.25" />
  //     <path d="M7.354,19.5a4.5,4.5,0,0,0,0-6" />
  //     <path d="M16.5,6.5a3.564,3.564,0,0,0,3,1.5" />
  //     <path d="M17.5,10.25a.25.25,0,1,0-.25-.25.25.25,0,0,0,.25.25" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <line x1="8.5" y1="16.5" x2="15.5" y2="16.5" />
  //     <path d="M16.646,19.5a4.5,4.5,0,0,1,0-6" />
  //   </StreamlineSvg>
  // ),

  // SmileyNauseous: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M7.5,6.5A3.564,3.564,0,0,1,4.5,8" />
  //     <path d="M16.5,6.5a3.564,3.564,0,0,0,3,1.5" />
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M15.086,15.278a6.509,6.509,0,0,0-6.172,0" />
  //     <path d="M7.068,13.5a4.5,4.5,0,0,1,2.432,4" />
  //     <path d="M16.932,13.5a4.5,4.5,0,0,0-2.432,4" />
  //   </StreamlineSvg>
  // ),

  // SmileyNoseBlow1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M17.5,17.518l-1.053-2.105a.5.5,0,0,0-.894,0l-1.106,2.211a.5.5,0,0,1-.894,0l-1.106-2.211a.5.5,0,0,0-.894,0l-1.106,2.211A.5.5,0,0,1,10,17.9a.55.55,0,0,1-.091-.008" />
  //     <path d="M1.456,16.6a11.5,11.5,0,1,1,5.479,5.729" />
  //     <path d="M6.5,9.5H8.293a.5.5,0,0,0,.353-.854L7,7" />
  //     <path d="M17.5,9.5H15.707a.5.5,0,0,1-.353-.854L17,7" />
  //     <path d="M9.661,12a1.37,1.37,0,0,0-1.046.485c-.8.944-2.745,2.941-4.991,3.013a2.624,2.624,0,0,0-2.613,2.267A2.5,2.5,0,0,0,3.5,20.5a1.5,1.5,0,0,0-1.461,1.848A1.552,1.552,0,0,0,3.6,23.5c4.693-.071,6.869-5.557,7.326-10.1A1.269,1.269,0,0,0,9.661,12Z" />
  //     <path d="M3.5,20.5a4.189,4.189,0,0,0,4-3" />
  //   </StreamlineSvg>
  // ),

  // SmileyNoseBlow: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M17.5,17.518l-1.053-2.105a.5.5,0,0,0-.894,0l-1.106,2.211a.5.5,0,0,1-.894,0l-1.106-2.211a.5.5,0,0,0-.894,0l-1.106,2.211a.5.5,0,0,1-.894,0l-.574-1.148" />
  //     <path d="M1.423,16.522a11.5,11.5,0,1,1,5.1,5.594" />
  //     <polyline points="6.5 9.5 9.5 9.5 7 7" />
  //     <polyline points="17.5 9.5 14.5 9.5 17 7" />
  //     <path d="M4.623,23.405a1.236,1.236,0,0,0,1.61-.654l3.984-9.1A1.037,1.037,0,0,0,10,12.5a.934.934,0,0,0-1.117-.155L1.138,16.681A1.241,1.241,0,0,0,.72,18.472a1.5,1.5,0,0,0,1.145.639,1.385,1.385,0,0,0,0,2,1.385,1.385,0,0,0,2,0S3.186,22.807,4.623,23.405Z" />
  //   </StreamlineSvg>
  // ),

  SmileyOneEyeSmile1: (props) => (
    <StreamlineSvg {...props}>
      <path d="M17.1,15.256a.5.5,0,0,1,.758.528,6,6,0,0,1-11.724,0,.5.5,0,0,1,.758-.528A9.292,9.292,0,0,0,12,16.5,9.292,9.292,0,0,0,17.1,15.256Z" />
      <circle cx="12" cy="12" r="11.5" />
      <circle cx="17" cy="10" r="2.5" />
      <path d="M17,9.75a.25.25,0,1,0,.25.25A.25.25,0,0,0,17,9.75" />
      <path d="M7,9.75a.25.25,0,1,1-.25.25A.25.25,0,0,1,7,9.75" />
    </StreamlineSvg>
  ),

  SmileyOneEyeSmile: (props) => (
    <StreamlineSvg {...props}>
      <path d="M17.1,15.256a.5.5,0,0,1,.758.528,6,6,0,0,1-11.724,0,.5.5,0,0,1,.758-.528A9.292,9.292,0,0,0,12,16.5,9.292,9.292,0,0,0,17.1,15.256Z" />
      <circle cx="12" cy="12" r="11.5" />
      <circle cx="17" cy="10" r="2.5" />
      <path d="M17,9.75a.25.25,0,1,0,.25.25A.25.25,0,0,0,17,9.75" />
      <circle cx="7" cy="10" r="1.5" />
    </StreamlineSvg>
  ),

  SmileyOops: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <line x1="9.5" y1="18.5" x2="14.5" y2="18.5" />
      <line x1="10.5" y1="12.5" x2="11" y2="11.5" />
      <line x1="13.5" y1="12.5" x2="13" y2="11.5" />
      <path d="M7.737,7.616a.25.25,0,1,1,0-.353.249.249,0,0,1,0,.353" />
      <circle cx="6.5" cy="8.5" r="3" />
      <path d="M18.737,7.616a.25.25,0,1,1,0-.353.249.249,0,0,1,0,.353" />
      <circle cx="17.5" cy="8.5" r="3" />
    </StreamlineSvg>
  ),

  SmileyPetrified: (props) => (
    <StreamlineSvg {...props}>
      <path d="M12,12.5A1.5,1.5,0,0,0,10.5,14v6a1.5,1.5,0,0,0,3,0V14A1.5,1.5,0,0,0,12,12.5Z" />
      <path d="M20.628,16.615C22.2,15.531,23.5,14.767,23.5,11,23.5,4.649,18.351.5,12,.5S.5,4.649.5,11c0,3.767,1.3,4.531,2.872,5.615" />
      <path d="M7.381,21.246A5.055,5.055,0,0,0,12,23.5a5.055,5.055,0,0,0,4.619-2.254" />
      <path d="M.5,23.5s3-1,3-5S2,14,3,13s4.5,2,4.5,5.5a17.751,17.751,0,0,1-.5,5" />
      <path d="M23.5,23.5s-3-1-3-5S22,14,21,13s-4.5,2-4.5,5.5a17.751,17.751,0,0,0,.5,5" />
      <ellipse cx="6.5" cy="9" rx="2" ry="2.5" />
      <ellipse cx="17.5" cy="9" rx="2" ry="2.5" />
    </StreamlineSvg>
  ),

  SmileyPrank: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <path d="M15.873,15.5a1,1,0,0,1,.97,1.249,5,5,0,0,1-9.686,0,1,1,0,0,1,.97-1.249Z" />
      <polyline points="6.5 9.5 9.5 9.5 7 7" />
      <polyline points="17.5 9.5 14.5 9.5 17 7" />
    </StreamlineSvg>
  ),

  // SmileyRage: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M15.336,17.005a4.45,4.45,0,0,0-6.672,0" />
  //     <path d="M14.5,9.392a2.359,2.359,0,0,0,4,0" />
  //     <path d="M9.5,9.392a2.359,2.359,0,0,1-4,0" />
  //     <path d="M1.9,17.5A11.5,11.5,0,1,1,23.5,12a11.439,11.439,0,0,1-1.4,5.5" />
  //     <path d="M17.5,22.1A11.515,11.515,0,0,1,6.5,22.1" />
  //     <line x1="9.5" y1="6.5" x2="6.5" y2="5.5" />
  //     <line x1="14.5" y1="6.5" x2="17.5" y2="5.5" />
  //     <path d="M2.842,17.759a1.5,1.5,0,1,0-1.351,2.646c-.016.015-.037.019-.052.034a1.5,1.5,0,0,0,2.122,2.122c.015-.015.019-.036.034-.052a1.5,1.5,0,1,0,2.646-1.351L9.664,15.29a.7.7,0,0,0-.954-.954Z" />
  //     <path d="M21.158,17.759a1.5,1.5,0,1,1,1.351,2.646c.016.015.037.019.052.034a1.5,1.5,0,0,1-2.122,2.122c-.015-.015-.019-.036-.034-.052a1.5,1.5,0,1,1-2.646-1.351L14.336,15.29a.7.7,0,0,1,.954-.954Z" />
  //   </StreamlineSvg>
  // ),

  SmileyRich: (props) => (
    <StreamlineSvg {...props}>
      <path d="M6,15.5a6.5,6.5,0,0,0,12,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M8.5,7.5H7.427a.927.927,0,0,0-.415,1.756l.976.488A.927.927,0,0,1,7.573,11.5H6.5" />
      <line x1="7.5" y1="6.5" x2="7.5" y2="7.5" />
      <line x1="7.5" y1="11.5" x2="7.5" y2="12.5" />
      <path d="M17.5,7.5H16.427a.927.927,0,0,0-.414,1.756l.975.488a.927.927,0,0,1-.415,1.756H15.5" />
      <line x1="16.5" y1="6.5" x2="16.5" y2="7.5" />
      <line x1="16.5" y1="11.5" x2="16.5" y2="12.5" />
    </StreamlineSvg>
  ),

  // SmileySad1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
  //   </StreamlineSvg>
  // ),

  // SmileySadCrying1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M23.5,16a2.5,2.5,0,0,1-5,0c0-1.381,2.5-5.5,2.5-5.5S23.5,14.619,23.5,16Z" />
  //     <path d="M7.5,6.5A3.564,3.564,0,0,1,4.5,8" />
  //     <path d="M16.5,6.5a3.564,3.564,0,0,0,3,1.5" />
  //     <path d="M6.5,10.25A.25.25,0,1,1,6.75,10a.25.25,0,0,1-.25.25" />
  //     <path d="M17.5,10.25a.25.25,0,1,0-.25-.25.25.25,0,0,0,.25.25" />
  //     <path d="M16.134,18.22a4.5,4.5,0,0,0-8.268,0,.739.739,0,0,0,1.01.953A6.446,6.446,0,0,1,12,18.5a6.446,6.446,0,0,1,3.124.673.739.739,0,0,0,1.01-.953Z" />
  //     <path d="M23.391,10.5a11.511,11.511,0,1,0-3.664,10" />
  //   </StreamlineSvg>
  // ),

  // SmileySadCrying: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M8.5,7.75A.25.25,0,1,1,8.25,8a.25.25,0,0,1,.25-.25" />
  //     <path d="M15.5,7.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M19,12.5a1.5,1.5,0,0,1-3,0c0-.828,1.5-3,1.5-3S19,11.672,19,12.5Z" />
  //     <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
  //   </StreamlineSvg>
  // ),

  SmileyNerd: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="7.5" cy="9.5" r="3" />
      <circle cx="16.5" cy="9.5" r="3" />
      <line x1="10.5" y1="9.5" x2="13.5" y2="9.5" />
      {/* <path d="M7.5,19a4.5,4.5,0,0,1,9,0" /> */}
      {/* <path d="M5.369,13.5a7.5,7.5,0,0,0,13.262,0" /> */}
      <path d="M6,15.5a6.5,6.5,0,0,0,12,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M8,8.75A.25.25,0,1,1,7.75,9,.25.25,0,0,1,8,8.75" />
      <path d="M17,8.75a.25.25,0,1,0,.25.25A.25.25,0,0,0,17,8.75" />
      <line x1="0.5" y1="5" x2="4.748" y2="8.304" />
      <line x1="23.5" y1="5" x2="19.252" y2="8.304" />
    </StreamlineSvg>
  ),

  SmileySad: (props) => (
    <StreamlineSvg {...props}>
      <path d="M7.5,19a4.5,4.5,0,0,1,9,0" />
      <path d="M14.5,9.392a2.359,2.359,0,0,0,4,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M9.5,9.392a2.359,2.359,0,0,1-4,0" />
    </StreamlineSvg>
  ),

  // SmileyScared: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,10.25A.25.25,0,1,1,6.75,10a.25.25,0,0,1-.25.25" />
  //     <path d="M17.5,10.25a.25.25,0,1,0-.25-.25.25.25,0,0,0,.25.25" />
  //     <path d="M16.5,14.5h-9a2,2,0,0,0,0,4h9a2,2,0,0,0,0-4Z" />
  //     <line x1="18.5" y1="16.5" x2="5.5" y2="16.5" />
  //   </StreamlineSvg>
  // ),

  SmileyShineBigEyes: (props) => (
    <StreamlineSvg {...props}>
      <path d="M17.1,15.256a.5.5,0,0,1,.758.528,6,6,0,0,1-11.724,0,.5.5,0,0,1,.758-.528A9.292,9.292,0,0,0,12,16.5,9.292,9.292,0,0,0,17.1,15.256Z" />
      <path d="M8.5,6.5a4,4,0,0,0-4,4,4,4,0,0,0-4-4,4,4,0,0,0,4-4,4,4,0,0,0,4,4" />
      <path d="M23.5,6.5a4,4,0,0,1-4-4,4,4,0,0,1-4,4,4,4,0,0,1,4,4,4,4,0,0,1,4-4" />
      <path d="M.773,9.5A11.5,11.5,0,1,0,23.5,12a11.565,11.565,0,0,0-.273-2.5" />
      <path d="M17,1.641a11.526,11.526,0,0,0-10,0" />
    </StreamlineSvg>
  ),

  SmileyShine: (props) => (
    <StreamlineSvg {...props}>
      <path d="M17.1,15.256a.5.5,0,0,1,.758.528,6,6,0,0,1-11.724,0,.5.5,0,0,1,.758-.528A9.292,9.292,0,0,0,12,16.5,9.292,9.292,0,0,0,17.1,15.256Z" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M9.5,9.5A2.5,2.5,0,0,0,7,12,2.5,2.5,0,0,0,4.5,9.5,2.5,2.5,0,0,0,7,7,2.5,2.5,0,0,0,9.5,9.5" />
      <path d="M19.5,9.5A2.5,2.5,0,0,1,17,7a2.5,2.5,0,0,1-2.5,2.5A2.5,2.5,0,0,1,17,12a2.5,2.5,0,0,1,2.5-2.5" />
    </StreamlineSvg>
  ),

  // SmileyShock: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M12,14.5A1.5,1.5,0,0,0,10.5,16v2a1.5,1.5,0,0,0,3,0V16A1.5,1.5,0,0,0,12,14.5Z" />
  //   </StreamlineSvg>
  // ),

  // SmileyShocked: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M17.5,9.5H15.707a.5.5,0,0,1-.353-.854L17,7" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,9.5H8.293a.5.5,0,0,0,.353-.854L7,7" />
  //     <path d="M12,14.5A1.5,1.5,0,0,0,10.5,16v2a1.5,1.5,0,0,0,3,0V16A1.5,1.5,0,0,0,12,14.5Z" />
  //   </StreamlineSvg>
  // ),

  // SmileyShook: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <line x1="5.75" y1="11" x2="8.75" y2="8" />
  //     <line x1="8.75" y1="11" x2="5.75" y2="8" />
  //     <line x1="15" y1="11" x2="18" y2="8" />
  //     <line x1="18" y1="11" x2="15" y2="8" />
  //     <path d="M12,14.5A1.5,1.5,0,0,0,10.5,16v2a1.5,1.5,0,0,0,3,0V16A1.5,1.5,0,0,0,12,14.5Z" />
  //   </StreamlineSvg>
  // ),

  // SmileyShout: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,10.25A.25.25,0,1,1,6.75,10a.25.25,0,0,1-.25.25" />
  //     <path d="M17.5,10.25a.25.25,0,1,0-.25-.25.25.25,0,0,0,.25.25" />
  //     <path d="M8.5,7.5a2.5,2.5,0,0,0-4,0" />
  //     <path d="M15.5,7.5a2.5,2.5,0,0,1,4,0" />
  //     <path d="M12,14.5A1.5,1.5,0,0,0,10.5,16v3a1.5,1.5,0,0,0,3,0V16A1.5,1.5,0,0,0,12,14.5Z" />
  //     <line x1="10.5" y1="12.5" x2="11" y2="12.5" />
  //     <line x1="13" y1="12.5" x2="13.5" y2="12.5" />
  //   </StreamlineSvg>
  // ),

  // SmileySickContagious: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M5.5,9a3.564,3.564,0,0,1,3-1.5" />
  //     <path d="M18.5,9a3.564,3.564,0,0,0-3-1.5" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <rect x="6.5" y="12.5" width="11" height="7" rx="1" ry="1" />
  //     <line x1="6.5" y1="17.5" x2="1.898" y2="17.5" />
  //     <line x1="0.5" y1="12" x2="6.5" y2="14" />
  //     <line x1="17.5" y1="17.5" x2="22.102" y2="17.5" />
  //     <line x1="23.5" y1="12" x2="17.5" y2="14" />
  //   </StreamlineSvg>
  // ),

  // SmileySick: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M14.5,9.392a2.359,2.359,0,0,0,4,0" />
  //     <path d="M9.5,9.392a2.359,2.359,0,0,1-4,0" />
  //     <path d="M11.5,19.5h-2a1,1,0,0,1-1-1,2,2,0,0,1,2-2" />
  //     <path d="M23.5,19.764a3.341,3.341,0,0,0-3.341-3.341,3.3,3.3,0,0,0-2.227.861,1.808,1.808,0,0,1-3.023-1.338.955.955,0,1,0-1.909,0,7.159,7.159,0,0,0,7.159,7.159A3.341,3.341,0,0,0,23.5,19.764Z" />
  //     <path d="M23.091,15A11.6,11.6,0,1,0,13.5,23.391" />
  //   </StreamlineSvg>
  // ),

  // SmileySilent: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M8.5,7.75A.25.25,0,1,1,8.25,8a.25.25,0,0,1,.25-.25" />
  //     <path d="M15.5,7.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <line x1="9" y1="14" x2="15" y2="19" />
  //     <line x1="9" y1="19" x2="15" y2="14" />
  //   </StreamlineSvg>
  // ),

  SmileySleepy: (props) => (
    <StreamlineSvg {...props}>
      <path d="M14.5,10.392a2.359,2.359,0,0,0,4,0" />
      <path d="M9.5,10.392a2.359,2.359,0,0,1-4,0" />
      <path d="M12,15.5A1.5,1.5,0,0,0,10.5,17v1a1.5,1.5,0,0,0,3,0V17A1.5,1.5,0,0,0,12,15.5Z" />
      <polyline points="13.5 4.5 15.5 4.5 13.5 6.5 15.5 6.5" />
      <polyline points="17.5 2.5 19.5 2.5 17.5 4.5 19.5 4.5" />
      <polyline points="21.5 0.5 23.5 0.5 21.5 2.5 23.5 2.5" />
      <path d="M15.5,1.046A11.493,11.493,0,1,0,22.1,6.5" />
    </StreamlineSvg>
  ),

  SmileySmile1: (props) => (
    <StreamlineSvg {...props}>
      <path d="M14.5,9.392a2.359,2.359,0,0,0,4,0" />
      <path d="M9.5,9.392a2.359,2.359,0,0,1-4,0" />
      <path d="M6,15.5a6.5,6.5,0,0,0,12,0" />
      <circle cx="12" cy="12" r="11.5" />
    </StreamlineSvg>
  ),

  SmileySmile2: (props) => (
    <StreamlineSvg {...props}>
      <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
      <path d="M6,15.5a6.5,6.5,0,0,0,12,0" />
      <circle cx="12" cy="12" r="11.5" />
    </StreamlineSvg>
  ),

  SmileySmileUpsideDown: (props) => (
    <StreamlineSvg {...props}>
      <path d="M17.5,13.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M6.5,13.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
      <path d="M18,8.5a6.5,6.5,0,0,0-12,0" />
      <circle cx="12" cy="12" r="11.5" />
    </StreamlineSvg>
  ),

  SmileySmile: (props) => (
    <StreamlineSvg {...props}>
      <path d="M9.5,9.608a2.359,2.359,0,0,0-4,0" />
      <path d="M14.5,9.608a2.359,2.359,0,0,1,4,0" />
      <path d="M6,15.5a6.5,6.5,0,0,0,12,0" />
      <circle cx="12" cy="12" r="11.5" />
    </StreamlineSvg>
  ),

  SmileySmirkGlasses1: (props) => (
    <StreamlineSvg {...props}>
      <path d="M17,16.159A6.516,6.516,0,0,1,10.38,18.3" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M22.586,7.5l-.922,2.907A3,3,0,0,1,18.8,12.5H14.566a1,1,0,0,1-.857-.486l-.952-1.585a.882.882,0,0,0-1.514,0l-.952,1.585a1,1,0,0,1-.857.486H5.2a3,3,0,0,1-2.86-2.093L1.414,7.5Z" />
    </StreamlineSvg>
  ),

  // SmileySmirkGlasses: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M10,19.5h4a3,3,0,0,0,3-3" />
  //     <path d="M19,17a1.415,1.415,0,0,0,0-2" />
  //     <path d="M22.586,7.5l-.922,2.907A3,3,0,0,1,18.8,12.5H14.566a1,1,0,0,1-.857-.486l-.952-1.585a.882.882,0,0,0-1.514,0l-.952,1.585a1,1,0,0,1-.857.486H5.2a3,3,0,0,1-2.86-2.093L1.414,7.5Z" />
  //   </StreamlineSvg>
  // ),

  SmileySmirk: (props) => (
    <StreamlineSvg {...props}>
      <path d="M18,14.5A6.5,6.5,0,0,1,9.5,18" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M8.5,7.75A.25.25,0,1,1,8.25,8a.25.25,0,0,1,.25-.25" />
      <path d="M15.5,7.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
    </StreamlineSvg>
  ),

  // SmileySpoilt: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,8.268a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,8.268a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M17.5,17.518l-1.053-2.105a.5.5,0,0,0-.894,0l-1.106,2.211a.5.5,0,0,1-.894,0l-1.106-2.211a.5.5,0,0,0-.894,0l-1.106,2.211a.5.5,0,0,1-.894,0L8.447,15.413a.5.5,0,0,0-.894,0L6.5,17.518" />
  //   </StreamlineSvg>
  // ),

  // SmileySurprised1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M16.134,18.22a4.5,4.5,0,0,0-8.268,0,.739.739,0,0,0,1.01.953A6.446,6.446,0,0,1,12,18.5a6.446,6.446,0,0,1,3.124.673.739.739,0,0,0,1.01-.953Z" />
  //     <circle cx="12" cy="12" r="11.5" />
  //     <line x1="10.5" y1="12.5" x2="11" y2="11.5" />
  //     <line x1="13.5" y1="12.5" x2="13" y2="11.5" />
  //   </StreamlineSvg>
  // ),

  // SmileySurprised: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M16.134,18.22a4.5,4.5,0,0,0-8.268,0,.739.739,0,0,0,1.01.953A6.446,6.446,0,0,1,12,18.5a6.446,6.446,0,0,1,3.124.673.739.739,0,0,0,1.01-.953Z" />
  //     <circle cx="12" cy="12" r="11.5" />
  //   </StreamlineSvg>
  // ),

  SmileyThrilled: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <path d="M15.873,15.5a1,1,0,0,1,.97,1.249,5,5,0,0,1-9.686,0,1,1,0,0,1,.97-1.249Z" />
      <path d="M18.8,10.108a2.359,2.359,0,0,0-4,0" />
      <path d="M9.5,10.108a2.359,2.359,0,0,0-4,0" />
    </StreamlineSvg>
  ),

  // SmileyThrowUp: (props) => (
  //   <StreamlineSvg {...props}>
  //     <line x1="10.5" y1="18.5" x2="10.5" y2="19.5" />
  //     <line x1="13.5" y1="19.5" x2="13.5" y2="20.5" />
  //     <path d="M7.5,5a3.564,3.564,0,0,1-3,1.5" />
  //     <path d="M16.5,5a3.564,3.564,0,0,0,3,1.5" />
  //     <polyline points="6.5 10.5 9.5 10.5 7 8" />
  //     <polyline points="17.5 10.5 14.5 10.5 17 8" />
  //     <path d="M16,18.5a2.5,2.5,0,0,0,0-5H8a2.5,2.5,0,0,0,0,5" />
  //     <path d="M18,20.5a2,2,0,0,1-2-2,2,2,0,0,0-2-2H10a2,2,0,0,0-2,2,2,2,0,0,1-2,2,1.5,1.5,0,0,0,0,3,4.841,4.841,0,0,0,2.5-.632,1.642,1.642,0,0,1,1.8.09,2.921,2.921,0,0,0,3.39,0,1.642,1.642,0,0,1,1.8-.09A4.841,4.841,0,0,0,18,23.5a1.5,1.5,0,0,0,0-3Z" />
  //     <path d="M20.5,19.746a11.5,11.5,0,1,0-17,0" />
  //   </StreamlineSvg>
  // ),

  SmileyTongue1: (props) => (
    <StreamlineSvg {...props}>
      <path d="M9.5,9.608a2.359,2.359,0,0,0-4,0" />
      <path d="M14.5,9.608a2.359,2.359,0,0,1,4,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M6.5,14.5a2.906,2.906,0,0,0,3,2h5a2.906,2.906,0,0,0,3-2" />
      <path d="M11.672,16.5l3.182,3.182a2,2,0,1,0,2.828-2.828L16.63,15.8" />
    </StreamlineSvg>
  ),

  SmileyTongue3: (props) => (
    <StreamlineSvg {...props}>
      <path d="M14.5,19.806a5.017,5.017,0,0,0,2.339-3.052,1,1,0,0,0-.968-1.254H8.129a1,1,0,0,0-.968,1.254A5.017,5.017,0,0,0,9.5,19.806" />
      <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
      <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
      <path d="M16.5,22.585a11.5,11.5,0,1,0-9,0" />
      <path d="M14.5,21a2.5,2.5,0,0,1-5,0V18.5a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1Z" />
    </StreamlineSvg>
  ),

  SmileyTongueSticking: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <line x1="7.5" y1="15.5" x2="16.5" y2="15.5" />
      <path d="M12.5,15.5v3a1.5,1.5,0,0,0,3,0v-3" />
      <line x1="5.5" y1="7.5" x2="8.5" y2="7.5" />
      <line x1="5.5" y1="9.5" x2="8.5" y2="9.5" />
      <line x1="18.5" y1="7.5" x2="15.5" y2="7.5" />
      <line x1="18.5" y1="9.5" x2="15.5" y2="9.5" />
    </StreamlineSvg>
  ),

  SmileyTongue: (props) => (
    <StreamlineSvg {...props}>
      <path d="M9.5,9.608a2.359,2.359,0,0,0-4,0" />
      <path d="M14.5,9.608a2.359,2.359,0,0,1,4,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M8.5,16.5h7a2,2,0,0,0,2-2" />
      <path d="M10.5,16.5V17a2.5,2.5,0,0,0,5,0v-.5" />
      <path d="M19.5,15a1.415,1.415,0,0,0,0-2" />
    </StreamlineSvg>
  ),

  SmileyTrouble: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <line x1="9.5" y1="16.5" x2="14.5" y2="16.5" />
      <path d="M6.5,7.25A.25.25,0,1,1,6.75,7a.25.25,0,0,1-.25.25" />
      <circle cx="6.5" cy="8.5" r="3" />
      <path d="M17.5,7.25A.25.25,0,1,1,17.75,7a.25.25,0,0,1-.25.25" />
      <circle cx="17.5" cy="8.5" r="3" />
    </StreamlineSvg>
  ),

  // SmileyUnhappy1: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <path d="M6.5,9.75a.25.25,0,1,1-.25.25.25.25,0,0,1,.25-.25" />
  //     <path d="M17.5,9.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M10.5,19.611a6.8,6.8,0,0,1,7-3" />
  //   </StreamlineSvg>
  // ),

  // SmileyUnhappy: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M8.5,7.75A.25.25,0,1,1,8.25,8a.25.25,0,0,1,.25-.25" />
  //     <path d="M15.5,7.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <path d="M10.38,18.5A6.521,6.521,0,0,1,17,16.355" />
  //     <circle cx="12" cy="12" r="11.5" />
  //   </StreamlineSvg>
  // ),

  SmileyVeryHappy: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <path d="M8.5,7.5a2.5,2.5,0,0,0-4,0" />
      <path d="M15.5,7.5a2.5,2.5,0,0,1,4,0" />
      <path d="M12,14.5A1.5,1.5,0,0,0,10.5,16v3a1.5,1.5,0,0,0,3,0V16A1.5,1.5,0,0,0,12,14.5Z" />
      <circle cx="12" cy="12" r="11.5" />
      <line x1="6.5" y1="9" x2="6.5" y2="12" />
      <line x1="5" y1="10.5" x2="8" y2="10.5" />
      <line x1="17.5" y1="9" x2="17.5" y2="12" />
      <line x1="16" y1="10.5" x2="19" y2="10.5" />
    </StreamlineSvg>
  ),

  SmileyWink: (props) => (
    <StreamlineSvg {...props}>
      <path d="M7.25,9.251A.25.25,0,1,1,7,9.5a.25.25,0,0,1,.25-.25" />
      <path d="M5.369,15.5a7.5,7.5,0,0,0,13.262,0" />
      <circle cx="12" cy="12" r="11.5" />
      <path d="M14.5,9.608a2.359,2.359,0,0,1,4,0" />
    </StreamlineSvg>
  ),

  // SmileyWrong: (props) => (
  //   <StreamlineSvg {...props}>
  //     <circle cx="12" cy="12" r="11.5" />
  //     <line x1="7.5" y1="7" x2="7.5" y2="10" />
  //     <line x1="6" y1="8.5" x2="9" y2="8.5" />
  //     <line x1="16.5" y1="7" x2="16.5" y2="10" />
  //     <line x1="15" y1="8.5" x2="18" y2="8.5" />
  //     <path d="M7,18V17a2.5,2.5,0,0,1,5,0,2.5,2.5,0,0,0,5,0V16" />
  //   </StreamlineSvg>
  // ),

  SmileyYawn: (props) => (
    <StreamlineSvg {...props}>
      <circle cx="12" cy="12" r="11.5" />
      <path d="M5.5,8a3.564,3.564,0,0,1,3-1.5" />
      <path d="M18.5,8a3.564,3.564,0,0,0-3-1.5" />
      <path d="M12,12.5A2.5,2.5,0,0,0,9.5,15v3a2.5,2.5,0,0,0,5,0V15A2.5,2.5,0,0,0,12,12.5Z" />
    </StreamlineSvg>
  ),

  // SmileyZipped: (props) => (
  //   <StreamlineSvg {...props}>
  //     <path d="M8.5,7.75A.25.25,0,1,1,8.25,8a.25.25,0,0,1,.25-.25" />
  //     <path d="M15.5,7.75a.25.25,0,1,0,.25.25.25.25,0,0,0-.25-.25" />
  //     <line x1="16.5" y1="16.5" x2="16.5" y2="17.5" />
  //     <line x1="6.5" y1="16.5" x2="18.5" y2="16.5" />
  //     <line x1="7.5" y1="15.5" x2="7.5" y2="16.5" />
  //     <line x1="8.5" y1="17.5" x2="8.5" y2="16.5" />
  //     <line x1="10.5" y1="17.5" x2="10.5" y2="16.5" />
  //     <line x1="11.5" y1="16.5" x2="11.5" y2="15.5" />
  //     <line x1="13.5" y1="16.5" x2="13.5" y2="15.5" />
  //     <line x1="9.5" y1="16.5" x2="9.5" y2="15.5" />
  //     <line x1="12.5" y1="17.5" x2="12.5" y2="16.5" />
  //     <line x1="14.5" y1="17.5" x2="14.5" y2="16.5" />
  //     <line x1="15.5" y1="16.5" x2="15.5" y2="15.5" />
  //     <path d="M23.449,13c.029-.33.051-.662.051-1a11.518,11.518,0,1,0-3.249,8" />
  //     <path d="M22,15c-.975,0-3.5.434-3.5,1.5S21.025,18,22,18a1.5,1.5,0,0,0,0-3Z" />
  //   </StreamlineSvg>
  // ),

  GestureSwipe2: (props) => (
    <StreamlineSvg {...props}>
      <path d="M18.2765038,7.92856125 C16.3184763,7.92856125 14.7984784,7.92856125 13.7165101,7.92856125 C12.6345418,7.92856125 12.3043502,6.63105672 12.7259354,4.03604766" transform="translate(15.412563, 5.982304) rotate(-90.000000) translate(-15.412563, -5.982304)" />
      <polyline transform="translate(17.358819, 4.118363) rotate(-90.000000) translate(-17.358819, -4.118363) " points="16.3588194 6.61836327 18.3588194 4.11836327 16.3588194 1.61836327" />
      <path d="M8.003,9 C8.003,8.44771525 8.45071525,8 9.003,8 C9.55528475,8 10.003,8.44771525 10.003,9 L10.003,13.756 L13.923,14.924 C14.5776199,15.1131564 14.9701829,15.7810404 14.817,16.445 C14.684,17.019 14.074,20.436 13.967,21.022 L7.847,21.022 L4.783,16.43 C3.569,14.574 6.619,13.93 8,17.022 L8.003,9 Z" />
    </StreamlineSvg>
  ),

  GestureSwipeRight: (props) => (
    <StreamlineSvg {...props}>
      <line x1="20" y1="7.022" x2="15" y2="7.022" />
      <polyline points="18 9.522 20 7.022 18 4.522" />
      <path d="M9.003,7 C9.003,6.44771525 9.45071525,6 10.003,6 C10.5552847,6 11.003,6.44771525 11.003,7 L11.003,11.756 L14.923,12.924 C15.5776199,13.1131564 15.9701829,13.7810404 15.817,14.445 C15.684,15.019 15.074,18.436 14.967,19.022 L8.847,19.022 L5.783,14.43 C4.569,12.574 7.619,11.93 9,15.022 L9.003,7 Z" />
    </StreamlineSvg>
  ),

  GestureDoubleTap: (props) => (
    <StreamlineSvg {...props}>
      <path d="M8.67521567,10.9720543 C7.64918231,9.32997959 7.97715916,7.18457655 9.44692618,5.9240454 C10.9166932,4.66351426 13.0870193,4.66627248 14.5535776,5.93053534 C16.0201359,7.1947982 16.3426587,9.34102794 15.3124549,10.9804894" />
      <path d="M7.61204292,12.9633341 C5.43014421,10.588048 5.53157636,6.90860025 7.84102194,4.65712808 C10.1504675,2.4056559 13.8313047,2.39777964 16.1503645,4.63934774 C18.4694243,6.88091584 18.586602,10.5598958 16.4148885,12.9444977" />
      <path d="M10.9977406,8.99666814 C10.9977406,8.44438339 11.4454558,7.99666814 11.9977406,7.99666814 C12.5500253,7.99666814 12.9977406,8.44438339 12.9977406,8.99666814 L12.9977406,13.7506681 L16.9177406,14.9206681 C17.572007,15.108939 17.9644113,15.7764217 17.8107406,16.4396681 C17.6807406,17.0036681 17.0657406,20.4396681 16.9617406,21.0156681 L10.8417406,21.0156681 L7.78074058,16.4306681 C6.56574058,14.5726681 9.61674058,13.9306681 10.9977406,17.0196681 L10.9977406,8.99666814 Z" />
    </StreamlineSvg>
  ),

};

export default Icon;
