import { combineReducers } from 'redux';

import users from 'app/reducers/entities/users';
import utils from 'app/reducers/utils';

const rootReducer = combineReducers({
  cities: utils.createEntityReducer(['city', 'cities']),
  users,
});

export default rootReducer;
