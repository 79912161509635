
const getName = (city) => {
  if (!city) return null;
  const showState = city.countryCode === 'US';
  const secondPart = showState ? city.regionName : city.countryName;
  return `${city.name}, ${secondPart}`;
};

const getFullName = (city) => {
  if (!city) return null;
  const parts = [city.name, city.regionName, city.countryName];
  return parts.filter(p => p).join(', ');
};

export default {
  getName,
  getFullName,
};
