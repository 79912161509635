import React from 'react';
import {connect} from 'react-redux';

import Icon        from 'app/components/common/icon';
import Link        from 'app/components/common/link';
import ScrollyPage from 'app/dotsnake/components/scrolly-page';
import DeviceDuck  from 'app/ducks/device';
import paths       from 'app/paths';

class PageInstructions extends React.PureComponent {

  render() {
    const head = (
      <div className="snk-settings-head">
        <div className="snk-scrolly-page-head-actions">
          <Link className="snk-scrolly-page-head-back" href={paths.snkHome()}><Icon.Caret direction="left" />Back</Link>
        </div>
        <h1 className="snk-scrolly-page-head-h1">How to Play</h1>
      </div>
    );

    return (
      <ScrollyPage className="snk-instr" head={head}>
        <div className="snk-instr-body">

          <div className="snk-instr-items">
            <div className="snk-instr-item swipe">
              <Icon.GestureSwipeRight className="snk-instr-item-icon" />
              <div className="snk-instr-item-text">Swipe to change direction.</div>
            </div>

            <div className="snk-instr-item double-tap">
              <Icon.GestureDoubleTap className="snk-instr-item-icon" />
              <div className="snk-instr-item-text">Double tap to pause.</div>
            </div>

            <div className="snk-instr-item eat">
              <div className="snk-instr-grid">
                <div className="snk-instr-grid-row">
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                </div>
                <div className="snk-instr-grid-row">
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell dot"></div>
                  <div className="snk-instr-grid-cell dot"></div>
                  <div className="snk-instr-grid-cell dot"></div>
                  <div className="snk-instr-grid-cell dot"></div>
                  <div className="snk-instr-grid-cell dot head"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell food"></div>
                  <div className="snk-instr-grid-cell"></div>
                </div>
                <div className="snk-instr-grid-row">
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                </div>
              </div>
              <div className="snk-instr-item-text">1 dot = 1 point</div>
            </div>

            <div className="snk-instr-item swipe2">
              <Icon.GestureSwipe2 className="snk-instr-item-icon" />
              <div className="snk-instr-item-text">Set up to two directions in a single swipe to make tight turns.</div>
            </div>

            <div className="snk-instr-item eat-special">
              <div className="snk-instr-grid">
                <div className="snk-instr-grid-row">
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                </div>
                <div className="snk-instr-grid-row">
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell dot"></div>
                  <div className="snk-instr-grid-cell dot"></div>
                  <div className="snk-instr-grid-cell dot"></div>
                  <div className="snk-instr-grid-cell dot"></div>
                  <div className="snk-instr-grid-cell dot head"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell special"></div>
                  <div className="snk-instr-grid-cell"></div>
                </div>
                <div className="snk-instr-grid-row">
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                  <div className="snk-instr-grid-cell"></div>
                </div>
              </div>
              <div className="snk-instr-item-text">Silver dots give you five points and temporary speed and invincibility.</div>
            </div>
          </div>

        </div>
      </ScrollyPage>
    );
  }

}

const stateToProps = (state) => ({
  insets: DeviceDuck.Slx.safeAreaInsets(state),
  orientation: DeviceDuck.Slx.orientation(state),
});

export default connect(stateToProps)(PageInstructions);
