import _ from 'lodash';
import { Router } from 'pouter';

import {
  Pages,
}                                    from 'app/constants';
// import paths                         from 'app/paths';
// import AuthSlx                       from 'app/selectors/auth';

const createRouter = (store) => {
  const handlerContext = { store };
  const router = new Router();
  router.setContext(handlerContext);

  /*
   *  handler wrappers
   */

  // const requireUser = (handler, {useSignup=false} = {}) => (done, location, ...args) => {
  //   const currentUser = AuthSlx.currentUser(store.getState());
  //   if (currentUser && currentUser.emailVerified) return handler(done, location, ...args);
  //   const loginPathFn = useSignup ? paths.signup : paths.login;
  //   const redirect = currentUser
  //     ? paths.verifyEmailPage
  //     : loginPathFn(location.url);
  //   return done({redirect});
  // };

  /*
   *  helpers
   */

  const makeHandler = ({page, loadAx, ignorePrerendered=true, ...opts}) => {
    let handler = (done, location, context, preRendered) => {
      if (preRendered && ignorePrerendered) return;
      const fetchProm = store.dispatch(loadAx(location));
      const waitProm = __IS_SERVER__ ? fetchProm : Promise.resolve();
      const track = location.params;
      waitProm
        .then(() => done({page, track}))
        .catch((error) => done({error}));
    };
    return handler;
  };

  // const checkPostLoginUrl = (location) => {
  //   if (__IS_SERVER__) return;
  //   const { postLoginUrl } = location.query;
  //   if (postLoginUrl) {
  //     store.dispatch(SessionStorageAx.setPostLoginUrl(postLoginUrl));
  //   }
  // };

  /*
   *  Main Routes
   */

  router.use('/', (done, location, _context, preRendered) => {
    if (preRendered) return;
    return done({page: Pages.TYBRO_HOME});
  });

  router.use('/browser-not-supported', (done, _location, _context, preRendered) => {
    if (preRendered) return;
    done({page: Pages.BROWSER_NOT_SUPPORTED});
  });

  router.use('/privacy', (done, location, _context, preRendered) => {
    return done({redirect: '/privacy-policy.html'});
  });
  router.use('/privacy-policy', (done, location, _context, preRendered) => {
    return done({redirect: '/privacy-policy.html'});
  });

//   router.use('/login', (done, location, context, preRendered) => {
//     if (preRendered) return;
//     return done({page: Pages.LOGIN});
//   });
// 
//   router.use('/signup', (done, location, context, preRendered) => {
//     if (preRendered) return;
//     return done({page: Pages.SIGN_UP});
//   });

  // router.use('/login', (done, location, _context, preRendered) => {
  //   checkPostLoginUrl(location);
  //   if (preRendered) return;
  //   const currentUser = AuthSlx.currentUser(store.getState());
  //   return currentUser
  //     ? currentUser.emailVerified
  //       ? done({redirect: '/'})
  //       : done({redirect: paths.verifyEmailPage})
  //     : done({page: Pages.LOGIN});
  // });

  return router;
};

export default createRouter;
