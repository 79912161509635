import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';

import MainApi      from 'app/apis/main';
import Checkbox     from 'app/components/common/checkbox';
import Icon         from 'app/components/common/icon';
import Modal        from 'app/components/common/modal';
import Duck         from 'app/dotsnake/ducks/game';
import AcctDuck     from 'app/ducks/account';
import AuthDuck     from 'app/ducks/auth';
import CitiesDuck   from 'app/ducks/cities';
import helpers      from 'app/helpers/city';
import history      from 'app/history';
import paths        from 'app/paths';

const {Ax, Slx} = Duck;

class ModalCity extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refInput = React.createRef();

    this.state = {
      searchStr: null,
      searchPending: false,
      cities: null,
      selectedCity: null,
      updateRecents: false,
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeSearchStr = this.onChangeSearchStr.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
    this.onChangeUpdateRecents = this.onChangeUpdateRecents.bind(this);
    this.searchNow = this.search.bind(this);
    this.search = _.debounce(this.searchNow, 1000);
  }

  componentDidMount() {
    this.searchNow('');
    this.setState({updateRecents: this.props.defaultUpdateRecents});
  }

  componentWillUnmount() {
    this.props.clear();
  }

  search(searchStr) {
    console.log(searchStr);
    this.searchStr = searchStr;
    this.setState({searchPending: true, cities: null});
    const filterIpCountry = !(searchStr || '').trim();
    MainApi.citiesSearch({search: searchStr, filterIpCountry}).then(this.onSearchResults.bind(this, searchStr));
  }

  onSearchResults(searchStr, {cities}) {
    if (this.searchStr !== searchStr) return;
    this.setState({searchPending: false, cities});
  }

  onClickCity(city) {
    this.setState({selectedCity: city});
  }

  onClickSave(event) {
    event?.preventDefault && event.preventDefault();
    const {updateRecents} = this.state;
    this.props.save(this.state.selectedCity.id, updateRecents).then(() => {
      this.onClose();
    });
  }

  onChangeSearchStr(event) {
    const searchStr = event.target.value;
    this.setState({searchStr});
    this.search(searchStr);
  }

  onChangeUpdateRecents(event) {
    const updateRecents = event.target.checked;
    this.setState({updateRecents});
  }

  onClose() {
    this.props.onClose();
  }

  onClickBack() {
    this.setState({selectedCity: null});
  }

  onSubmitForm(event) {
    event.preventDefault();
    this.refInput.current.blur();
  }

  renderSearch() {
    const {searchStr, searchPending, cities} = this.state;
    return (<>
      <p className="snk-modal-p">Your selected city will show up on your new scores and will set the leaderboard you see.</p>

      <form action="/" onSubmit={this.onSubmitForm}>
        <input className="snk-input" type="text" value={searchStr || ''} onChange={this.onChangeSearchStr} enterKeyHint="search" ref={this.refInput} placeholder="Type city name..." />
      </form>

      {searchPending && <Icon.Loading className="snk-modal-city-list-loading" />}
      {!!(cities && !cities.length) && (
        <div className="snk-modal-city-none">No matching cities found.</div>
      )}
      {cities && (
        <div className="snk-modal-city-list">
          {cities.map((city) => {
            return (
              <button key={city.id} className="snk-modal-city-list-city" onClick={this.onClickCity.bind(this, city)}>
                {helpers.getFullName(city)}
                <Icon.Caret direction="right" />
              </button>
            );
          })}
        </div>
      )}
    </>);
  }

  renderConfirm() {
    const {selectedCity, updateRecents} = this.state;
    const {pending, failed} = this.props;
    return (<>
      <div className="snk-modal-city-selected">
        {selectedCity.name}
        {selectedCity.regionName && <><br />{selectedCity.regionName}</>}
        {selectedCity.countryName && <><br />{selectedCity.countryName}</>}
      </div>
      <div className="snk-modal-city-recents">
        <Checkbox className="snk-checkbox" id="apply-to-recents" checked={updateRecents} onChange={this.onChangeUpdateRecents} />
        <label htmlFor="apply-to-recents">Apply to recent scores</label>
      </div>
      <div className="snk-modal-actions">
        <button className="snk-btn mint" onClick={this.onClickSave} disabled={pending}>{pending ? 'Saving...' : 'Save'}</button>
      </div>
      {failed && (
        <p className="snk-modal-p">Oops! Something went wrong while saving. Please try again.</p>
      )}
    </>);
  }

  render() {
    const {currentUser} = this.props;
    const {selectedCity} = this.state;
    // if (!currentUser) return null;
    const content = selectedCity
      ? this.renderConfirm()
      : this.renderSearch();

    return (
      <Modal bgClose={false} className="snk-modal-city snk-modal" onClose={this.onClose} onBack={selectedCity ? this.onClickBack : null}>
        <h1 className="snk-modal-title">City</h1>
        {content}
      </Modal>
    );
  }

}

ModalCity.propTypes = {
  onClose: PropTypes.func.isRequired,
  defaultUpdateRecents: PropTypes.bool,
};

ModalCity.defaultProps = {
  defaultUpdateRecents: true,
};

const stateToProps = (state) => ({
  currentUser: AuthDuck.Slx.currentUser(state),
  pending: CitiesDuck.Slx.snkSetCityPending(state),
  failed: CitiesDuck.Slx.snkSetCityFailed(state),
});

const dispatchToProps = (dispatch) => ({
  save: (cityId, updateRecents) => dispatch(CitiesDuck.Ax.snkSetCity(cityId, updateRecents)),
  clear: () => dispatch(CitiesDuck.Ax.snkClearSetCity()),
});

export default connect(stateToProps, dispatchToProps)(ModalCity);
