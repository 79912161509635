import React from 'react';

import CurrentPage          from 'app/components/layout/current-page';
// import PageTitle            from 'app/components/layout/page-title';
import SizeMonitor          from 'app/components/layout/size-monitor';

const isProd = process.env.TYBRO_ENV === 'production';

class App extends React.PureComponent {

  constructor(props) {
    super(props);
    this.on3FingerTap = this.on3FingerTap.bind(this);
  }

  on3FingerTap() {
    if (isProd) return;
    window.location.href = '/home';
  }

  componentDidMount() {
    window.addEventListener('tybro3Tap', this.on3FingerTap);
  }

  componentWillUnmount() {
    window.removeEventListener('tybro3Tap', this.on3FingerTap);
  }

  render() {
    return (
      <>
        {/* <PageTitle /> */}
        <CurrentPage />
        <SizeMonitor />
      </>
    );
  }

}

export default App;
