import _ from 'lodash';

const colors = ['green', 'blue', 'purple', 'yellow'];
const getColor = (except) => _.sample(_.without(colors, except));

class Game {
  constructor({rowCount, colCount, countdown=0, dispatch, skipInit=false}) {
    this.dispatch = dispatch;

    this.dispatch('reset');

    this.countdownInterval = null;
    this.tickTimeout = null;
    this.bodyPath = [];
    this.objects = {};
    this.dirQueue = [];

    this.color = getColor();
    this.direction = 'down';
    this.countdown = countdown;
    this.status = 'pending';
    this.score = 0;
    this.size = {rowCount, colCount};
    this.tickSpeed = 150;

    this.specialTicksUntilAdd = this.getSpecialTicksUntil();
    this.specialTicksUntilRemove = 0;
    this.specialTicksUntilEnd = 0;
    this.specialCell = null;
    this.specialOn = false;
    this.specialIsEnding = false;

    if (!skipInit) {
      this.initializeBody();
      this.addFood();
    }
  }

  serialize() {
    if (!['active', 'paused'].includes(this.status)) return null;
    return _.pick(this, [
      'color', 'direction', 'rowCount', 'colCount', 'bodyPath', 'objects', 'score',
      'specialTicksUntilAdd', 'specialTicksUntilRemove', 'specialTicksUntilEnd',
      'specialCell', 'specialOn', 'specialIsEnding',
    ]);
  }

  hydrate(state) {
    this.color = state.color;
    this.direction = state.direction;
    this.score = state.score;
    this.countdown = 3;
    this.size = _.pick(state, ['rowCount', 'colCount']);
    this.bodyPath = state.bodyPath;

    this.specialTicksUntilAdd = state.specialTicksUntilAdd;
    this.specialTicksUntilRemove = state.specialTicksUntilRemove;
    this.specialTicksUntilEnd = state.specialTicksUntilEnd;
    this.specialCell = state.specialCell;
    this.specialOn = state.specialOn;
    this.specialIsEnding = state.specialIsEnding;

    Object.entries(state.objects).forEach(([key, objs]) => {
      const [row, col] = key.split('x');
      objs.forEach((obj) => {
        this.addObject(row, col, obj);
      });
    });
  }

  set size({rowCount, colCount}) {
    this._rowCount = rowCount;
    this._colCount = colCount;
    this.dispatch('setSize', {rowCount, colCount});
  }
  get rowCount() { return this._rowCount; }
  get colCount() { return this._colCount; }

  set countdown(countdown) {
    this._countdown = countdown;
    this.dispatch('setCountdown', {countdown});
  }
  get countdown() { return this._countdown; }

  set status(status) {
    this._status = status;
    this.dispatch('setStatus', {status});
  }
  get status() { return this._status; }

  set specialOn(specialOn) {
    this._specialOn = specialOn;
    this.dispatch('setSpecialOn', {specialOn});
  }
  get specialOn() { return this._specialOn; }

  set specialIsEnding(specialIsEnding) {
    if (specialIsEnding === this._specialIsEnding) return;
    this._specialIsEnding = specialIsEnding;
    this.dispatch('setSpecialIsEnding', {specialIsEnding});
  }
  get specialIsEnding() { return this._specialIsEnding; }

  set score(score) {
    this._score = score;
    this.dispatch('setScore', {score});
  }
  get score() { return this._score; }

  set color(color) {
    this._color = color;
    this.dispatch('setColor', {color});
  }
  get color() { return this._color; }

  get headCell() {
    return _.last(this.bodyPath);
  }
  get tailCell() {
    return _.first(this.bodyPath);
  }
  get nextCell() {
    const cell = {...this.headCell};
    if (this.direction === 'right') cell.col += 1;
    if (this.direction === 'left')  cell.col -= 1;
    if (this.direction === 'down')  cell.row += 1;
    if (this.direction === 'up')    cell.row -= 1;
    if (cell.row >= this.rowCount) {
      if (!this.specialOn) return null;
      cell.row = 0;
    }
    if (cell.col >= this.colCount) {
      if (!this.specialOn) return null;
      cell.col = 0;
    }
    if (cell.row < 0) {
      if (!this.specialOn) return null;
      cell.row = this.rowCount - 1;
    }
    if (cell.col < 0) {
      if (!this.specialOn) return null;
      cell.col = this.colCount - 1;
    }
    return cell;
  }
  get emptyCell() {
    let cell = null;
    while (!cell || this.objectAtCell(cell.row, cell.col)) {
      cell = {row: _.random(this.rowCount - 1), col: _.random(this.colCount - 1)};
    }
    return cell;
  }

  get lastDir() {
    return _.last(this.dirQueue) || this.direction;
  }

  getSpecialTicksUntil() {
    return Math.round((_.random(5, 15) * 1000) / this.tickSpeed);
  }

  initializeBody() {
    const col = 1;
    _.times(5).forEach((i) => {
      const row = i+1;
      this.addBodyObject(row, col);
    });
  }

  addDirection(direction) {
    if (this.status !== 'active') return;
    const lastAxis = ['up', 'down'].includes(this.lastDir) ? 'ver' : 'hor';
    const newAxis  = ['up', 'down'].includes(direction)    ? 'ver' : 'hor';
    if (lastAxis === newAxis) return;
    this.dirQueue.push(direction);
  }

  addFood() {
    const cell = this.emptyCell;
    const obj = {type: 'food', color: getColor(this.color)};
    this.addObject(cell.row, cell.col, obj);
  }

  applyDirection() {
    const newDir = this.dirQueue.shift();
    if (newDir) this.direction = newDir;
  }

  addBodyObject(row, col) {
    const obj = {type: 'body', color: this.color};
    this.bodyPath.push({row, col});
    this.addObject(row, col, obj);
  }

  addObject(row, col, obj) {
    const key = `${row}x${col}`;
    const cellObjects = this.objects[key] || [];
    this.objects[key] = [...cellObjects, obj];
    if (obj.type === 'body') {
      const neck = this.bodyPath[this.bodyPath.length - 2];
      if (neck) this.dispatch('setCell', {row: neck.row, col: neck.col, obj: this.objectAtCell(neck.row, neck.col)});
    }
    this.dispatch('setCell', {row, col, obj: this.objectAtCell(row, col)});
  }

  removeObject(row, col, type) {
    const key = `${row}x${col}`;
    const cellObjects = this.objects[key] || [];
    let hasRemoved = false;
    this.objects[key] = cellObjects.filter((obj) => {
      if (obj.type !== type) return true;
      if (hasRemoved) return true;
      if (obj.type === type) {
        hasRemoved = true;
        return false;
      }
      return true;
    });
    if (this.objects[key].length === 0) {
      delete this.objects[key];
    }
    this.dispatch('setCell', {row, col, obj: this.objectAtCell(row, col)});
  }

  advanceTail() {
    const cell = this.bodyPath.shift();
    this.removeObject(cell.row, cell.col, 'body');
  }

  objectAtCell(row, col) {
    const key = `${row}x${col}`;
    const objects = this.objects[key];
    if (!objects || !objects.length) return null;
    const obj = _.last(objects);
    if (!obj) return null;
    if (obj.type === 'body') {
      const headCell = _.last(this.bodyPath);
      obj.isHead = (headCell.row === +row) && (headCell.col === +col);
    }
    return obj;
  }

  countdownTick() {
    this.countdown = this.countdown - 1;
    if (this.countdown <= 0) {
      clearInterval(this.countdownInterval);
      this.play();
    }
  }

  die() {
    this.status = 'over';
    clearTimeout(this.tickTimeout);
  }

  addSpecial() {
    this.specialCell = this.emptyCell;
    this.specialTicksUntilRemove = Math.round(5000 / this.tickSpeed);
    const obj = {type: 'special'};
    this.addObject(this.specialCell.row, this.specialCell.col, obj);
  }

  removeSpecial() {
    if (!this.specialCell) return;
    this.removeObject(this.specialCell.row, this.specialCell.col, 'special');
    this.specialTicksUntilAdd = this.getSpecialTicksUntil();
  }

  startSpecial() {
    this.specialIsEnding = false;
    this.tickSpeed = 120;
    this.specialOn = true;
    this.specialCell = null;
    this.specialTicksUntilEnd = Math.round(15000 / this.tickSpeed);
  }

  endSpecial() {
    this.tickSpeed = 150;
    this.specialOn = false;
    this.specialTicksUntilAdd = this.getSpecialTicksUntil();
  }

  tick() {
    if (this.status !== 'active') return;
    (() => {
      if (this.specialTicksUntilAdd) {
        this.specialTicksUntilAdd -= 1;
        if (!this.specialTicksUntilAdd) this.addSpecial();
      }
      if (this.specialTicksUntilRemove) {
        this.specialTicksUntilRemove -= 1;
        if (!this.specialTicksUntilRemove) this.removeSpecial();
      }
      if (this.specialTicksUntilEnd) {
        this.specialTicksUntilEnd -= 1;
        if (this.specialTicksUntilEnd < (2000 / this.tickSpeed)) {
          this.specialIsEnding = true;
        }
        if (!this.specialTicksUntilEnd) this.endSpecial();
      }

      this.applyDirection();
      const next = this.nextCell;
      if (!next) {
        this.die();
        return;
      }
      let object = this.objectAtCell(next.row, next.col);
      if (object?.type === 'food') {
        this.score = this.score + 1;
        this.color = object.color;
        this.removeObject(next.row, next.col, 'food');
        this.addBodyObject(next.row, next.col, {type: 'body'});
        this.addFood();
        return;
      }
      if (object?.type === 'special') {
        this.score = this.score + 5;
        this.removeObject(next.row, next.col, 'special');
        this.addBodyObject(next.row, next.col, {type: 'body'});
        this.startSpecial();
        return;
      }
      this.advanceTail();
      object = this.objectAtCell(next.row, next.col);
      if (object?.type === 'body' && !this.specialOn) {
        this.die();
        return;
      }
      this.addBodyObject(next.row, next.col);
    })();
    this.tickTimeout = setTimeout(this.tick.bind(this), this.tickSpeed);
  }

  play() {
    if (this.status === 'over') return;
    if (this.status === 'active') return;
    if (this.status === 'countdown' && this.countdown) return;
    if (this.countdown) {
      this.status = 'countdown';
      this.countdownInterval = setInterval(this.countdownTick.bind(this), 1000);
      return;
    }
    this.status = 'active';
    this.tickTimeout = setTimeout(this.tick.bind(this), this.tickSpeed);
  }

  pause() {
    if (this.status === 'over') return;
    if (this.status === 'paused') return;
    if (this.status === 'pending') return;
    clearTimeout(this.tickTimeout);
    clearInterval(this.countdownInterval);
    this.status = 'paused';
  }

  kill() {
    clearTimeout(this.tickTimeout);
    clearInterval(this.countdownInterval);
  }

}

export default Game;
