import React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Capacitor } from '@capacitor/core';

import Icon        from 'app/components/common/icon';
import Link        from 'app/components/common/link';
import ScrollyPage  from 'app/dotsnake/components/scrolly-page';
import Score       from 'app/dotsnake/components/leaderboard/score';
import ModalCity   from 'app/dotsnake/components/settings/modal-city';
import Duck        from 'app/dotsnake/ducks/leaderboard';
import AuthDuck    from 'app/ducks/auth';
import CitiesDuck  from 'app/ducks/cities';
import RoutingDuck from 'app/ducks/routing';
import paths       from 'app/paths';

class PageLeaderboard extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showCityModal: false,
    };

    this.onClickCity = this.onClickCity.bind(this);
    this.onCloseCity = this.onCloseCity.bind(this);
  }

  componentDidMount() {
    this.props.search();
    const {filter} = this.props.query || {};
    if (filter) {
      this.onClickFilter(filter);
    }
  }

  onClickCity(event) {
    event.preventDefault();
    this.setState({showCityModal: true});
  }

  onCloseCity() {
    this.props.search();
    this.setState({showCityModal: false});
  }

  onClickFilter(filter) {
    this.props.selectFilter(filter);
  }

  renderFilters() {
    const {filters, filter: activeFilter, city} = this.props;

    return (
      <div className="snk-ldrbrd-filters">
        {filters.map((filter) => {
          const isActive = filter === activeFilter;
          const label = (() => {
            if (filter === 'personal') return 'Personal';
            if (filter === 'city') return city?.name || 'City';
            if (filter === 'region') return city?.regionName || 'Region';
            if (filter === 'country') return city?.countryName || 'Country';
            if (filter === 'global') return 'Global';
          })();
          const className = `snk-ldrbrd-filters-btn ${isActive ? 'active' : ''}`;
          return (
            <button key={filter} className={className} onClick={this.onClickFilter.bind(this, filter)}>
              {label}
            </button>
          );
        })}
      </div>
    );
  }

  renderScores() {
    const {scores, searchPending, searchFailed} = this.props;
    if (searchPending) return <Icon.Loading className="snk-ldrbrd-loading" />;
    if (searchFailed) return <p className="snk-ldrbrd-load-p">Oops! Something went wrong while retrieving scores.</p>;
    if (!scores) return null;
    if (!scores.length) return <p className="snk-ldrbrd-load-p">No scores here yet - go play!</p>;

    return (
      <div className="snk-ldrbrd-con">
        {scores.map((score, i) => {
          return (
            <Score key={i} score={score} rank={i+1} />
          );
        })}
      </div>
    );
  }

  render() {
    const {showCityModal} = this.state;
    const isMobile = Capacitor.getPlatform() === 'ios';

    const head = (
      <div className="snk-ldrbrd-head">
        <div className="snk-ldrbrd-head-padder">
          <div className="snk-scrolly-page-head-actions">
            <Link className="snk-scrolly-page-head-back" href={paths.snkHome()}><Icon.Caret direction="left" />Back</Link>
            <div className="snk-scrolly-page-head-actions-spacer" />
            {isMobile && (
              <Link href="#" onClick={this.onClickCity}>Change City</Link>
            )}
          </div>
          <h1 className="snk-scrolly-page-head-h1">Leaderboards</h1>
        </div>
        {this.renderFilters()}
      </div>
    );

    return (
      <ScrollyPage className="snk-ldrbrd" head={head}>
        {this.renderScores()}
        {showCityModal && (
          <ModalCity onClose={this.onCloseCity} />
        )}
      </ScrollyPage>
    );
  }

}

const stateToProps = (state) => ({
  searchPending: Duck.Slx.searchPending(state),
  searchFailed: Duck.Slx.searchFailed(state),
  scores: Duck.Slx.showScores(state),
  filters: Duck.Slx.filters(state),
  filter: Duck.Slx.filter(state),

  city: CitiesDuck.Slx.snkCity(state),
  query: RoutingDuck.Slx.query(state),
});

const dispatchToProps = (dispatch) => ({
  selectFilter: (filter) => dispatch(Duck.Ax.selectFilter(filter)),
  search: () => dispatch(Duck.Ax.search()),
});

export default connect(stateToProps, dispatchToProps)(PageLeaderboard);
