import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

class StandardInput extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      filled: false,
      fileName: '',
    };

    this.refInput = React.createRef();

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClickFake = this.onClickFake.bind(this);
  }

  onFocus() {
    this.setState({filled: true});
  }

  onBlur() {
    const hasValue = (this.props.type === 'file')
      ? this.refInput.current.files[0]
      : this.refInput.current.value.trim();
    if (!hasValue) this.setState({filled: false});
  }

  onChange(event) {
    const { onChange, type } = this.props;
    if (type === 'file') {
      const file = _.get(this.refInput, 'current.files[0]');
      if (!file) return;
      this.setState({ fileName: file.name });
      onChange && onChange(event, file);
    } else {
      onChange && onChange(event, event.target.value);
    }
  }

  onClickFake() {
    this.refInput.current.click();
  }

  get value() {
    return this.refInput.current.value;
  }

  set value(newVal) {
    this.refInput.current.value = newVal;
  }

  get element() {
    return this.refInput.current;
  }

  render() {
    const { filled } = this.state;
    const { type, name, label, validations, validationMessage, icon, prefix, prefixWidth, rightIcon, className, disabled, ...inputProps } = this.props;
    const isFile = type === 'file';
    const isTextarea = type === 'textarea';
    const vMsg = validationMessage || _.get(validations, `${name}[0]`);
    const filledClass = (filled || inputProps.value || inputProps.defaultValue) ? 'filled' : '';
    const invalidClass = vMsg ? 'invalid' : '';
    const iconClass = icon ? 'has-icon' : 'no-icon';
    const disabledClass = disabled ? 'disabled' : '';

    const actualProps = {
      ref: this.refInput,
      type,
      name,
      disabled,
      placeholder: label,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      ...inputProps,
      onChange: this.onChange,
    };

    if (isFile) actualProps.value = undefined;

    const inputCss = {};
    // '15' is the horizontal position of the prefix, in 'px':
    if (prefix) inputCss.paddingLeft = prefixWidth + 15;

    return (
      <div className={`standard-input ${filledClass} ${className} ${invalidClass} ${iconClass} ${disabledClass}`}>
        {!!prefix && <div className="prefix">{prefix}</div>}
        {isTextarea 
          ? <textarea {...actualProps} style={inputCss} /> 
          : <input {...actualProps} style={inputCss} />}
        {isFile &&
          <input
            className={`${filledClass} fake-file`}
            type="text"
            placeholder={label}
            value={this.state.fileName || inputProps.value || ''}
            onClick={this.onClickFake}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            readOnly
          />
        }
        {icon}
        <div className="right-icon">
          {rightIcon}
        </div>
        {!!vMsg &&
          <div className="validation-message">{vMsg}</div>
        }
      </div>
    );
  }

}

StandardInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  prefix: PropTypes.string,
  prefixWidth: PropTypes.number,
  rightIcon: PropTypes.node,
  validations: PropTypes.object,
  validationMessage: PropTypes.string,
};

StandardInput.defaultProps = {
  type: 'text',
  validations: {},
  className: '',
  /** Text to be displayed within the input box, to the left of the actual input
   *  area, where the icon would appear. Unlike the placeholder, this text will
   *  always be visible. This prop is not compatible with `icon`. */
  prefix: null,
  /** The pixel distance by which the actual input area should be shifted, if
   *  `prefix` is set, to make room for that text. */
  prefixWidth: 25,
};

export default StandardInput;
