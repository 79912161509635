import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';

// import Icon         from 'app/components/common/icon';
import Modal        from 'app/components/common/modal';
import {
  DeviceTypes,
}                   from 'app/constants';
import Avatar       from 'app/dotsnake/components/avatar';
import Duck         from 'app/dotsnake/ducks/game';
import SnakeManager from 'app/dotsnake/game/manager';
import AcctDuck     from 'app/ducks/account';
import AuthDuck     from 'app/ducks/auth';
import history      from 'app/history';
import paths        from 'app/paths';

const {Ax, Slx} = Duck;

class ModalAvatar extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      face: null,
      color: null,
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBackColor = this.onBackColor.bind(this);
    this.onBackFace = this.onBackFace.bind(this);
  }

  componentWillUnmount() {
    this.props.clear();
  }

  onClickSave(event) {
    event?.preventDefault && event.preventDefault();
    const {face, color} = this.state;
    const prefs = {snkAvatarIcon: face, snkAvatarColor: color};
    console.log(prefs);
    this.props.setPrefs(prefs).then(() => {
      this.onClose();
    });
  }

  onClickFace(face) {
    this.setState({face});
  }

  onClickColor(color) {
    this.setState({color});
  }

  onClose() {
    this.props.onClose();
  }

  onBackColor() {
    this.setState({color: null});
  }

  onBackFace() {
    this.setState({color: null, face: null});
  }

  renderFaces() {
    return (
      <div>
        <h1 className="snk-modal-title">Smiley</h1>
        <p className="snk-modal-p">Pick an expression:</p>
        <div className="snk-modal-avatar-faces-con">
          {Avatar.iconNames.map((iconName) => {
            return (
              <button key={iconName} className="snk-modal-avatar-face-btn" onClick={this.onClickFace.bind(this, iconName)}>
                <Avatar iconName={iconName} size={30} />
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  renderColors() {
    const {face} = this.state;
    return (
      <div>
        <h1 className="snk-modal-title">Smiley</h1>
        <p className="snk-modal-p">Pick a color:</p>
        <div className="snk-modal-avatar-faces-con">
          {Avatar.colors.map((color) => {
            return (
              <button key={color} className="snk-modal-avatar-face-btn" onClick={this.onClickColor.bind(this, color)}>
                <Avatar iconName={face} color={color} size={30} />
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  renderSave() {
    const {pending, failed} = this.props;
    const {face, color} = this.state;

    return (
      <div>
        <h1 className="snk-modal-title">Smiley</h1>
        <p className="snk-modal-p">You look nice today.</p>
        <Avatar iconName={face} color={color} size={60} className="snk-modal-avatar-final" />
        <div className="snk-modal-actions">
          <button className="snk-btn mint" onClick={this.onClickSave} disabled={pending}>{pending ? 'Saving...' : 'Save'}</button>
        </div>
        {failed && (
          <p className="snk-modal-p">Oops! Something went wrong while saving. Please try again.</p>
        )}
      </div>
    );
  }

  render() {
    const {currentUser} = this.props;
    const {face, color} = this.state;
    const content = (() => {
      if (!face) return this.renderFaces();
      if (!color) return this.renderColors();
      return this.renderSave();
    })()
    const backFn = (() => {
      if (color) return this.onBackColor;
      if (face) return this.onBackFace;
      return null;
    })();
    // if (!currentUser) return null;

    return (
      <Modal bgClose={false} className="snk-modal-avatar snk-modal" onClose={this.onClose} onBack={backFn}>
        {content}
      </Modal>
    );
  }

}

ModalAvatar.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  currentUser: AuthDuck.Slx.currentUser(state),
  pending: AcctDuck.Slx.setPrefsPending(state),
  failed: AcctDuck.Slx.setPrefsFailed(state),
  success: AcctDuck.Slx.setPrefsSuccess(state),
});

const dispatchToProps = (dispatch) => ({
  setPrefs: (prefs) => dispatch(AcctDuck.Ax.setPrefs(prefs)),
  clear: () => dispatch(AcctDuck.Ax.clearSetPrefs()),
});

export default connect(stateToProps, dispatchToProps)(ModalAvatar);
