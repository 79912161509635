import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';

import Modal        from 'app/components/common/modal';
import {
  DeviceTypes,
}                   from 'app/constants';
import Duck         from 'app/dotsnake/ducks/game';
import SnakeManager from 'app/dotsnake/game/manager';
import AuthDuck     from 'app/ducks/auth';
import history      from 'app/history';
import paths        from 'app/paths';

const {Ax, Slx} = Duck;

class ModalDeleteAccount extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentWillUnmount() {
    this.props.clear();
  }

  onClickDelete(event) {
    event?.preventDefault && event.preventDefault();
    this.props.deleteAccount().then(() => {
      this.onClose();
    });
  }

  onClose() {
    this.props.onClose();
  }

  render() {
    const {pending} = this.props;

    return (
      <Modal bgClose={false} className="snk-modal-login snk-modal" onClose={this.onClose}>
        <h1 className="snk-modal-title">Delete Account</h1>
        <p className="snk-modal-p">Are you sure?</p>
        <p className="snk-modal-p">All of your scores and any information associated with your account will be deleted.</p>
        <div className="snk-modal-actions">
          <button className="snk-btn mint" onClick={this.onClickDelete} disabled={pending}>{pending ? 'Deleting...' : 'Delete Account'}</button>
        </div>
      </Modal>
    );
  }

}

ModalDeleteAccount.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  pending: AuthDuck.Slx.deleteAccountPending(state),
  // failed: AuthDuck.Slx.logoutFailed(state),
  // success: AuthDuck.Slx.logoutSuccess(state),
});

const dispatchToProps = (dispatch) => ({
  deleteAccount: () => dispatch(AuthDuck.Ax.deleteAccount()),
  clear: () => dispatch(AuthDuck.Ax.clearDeleteAccount()),
});

export default connect(stateToProps, dispatchToProps)(ModalDeleteAccount);
