import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';

import Modal        from 'app/components/common/modal';
import {
  DeviceTypes,
}                   from 'app/constants';
import Duck         from 'app/dotsnake/ducks/game';
import SnakeManager from 'app/dotsnake/game/manager';
import AcctDuck     from 'app/ducks/account';
import AuthDuck     from 'app/ducks/auth';
import history      from 'app/history';
import paths        from 'app/paths';

const {Ax, Slx} = Duck;

class ModalDisplayName extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refInput = React.createRef();

    this.state = {
      name: null,
    };

    this.onClickSave = this.onClickSave.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    // this.props.clear();
    const name = this.props.currentUser?.displayName;
    if (name) {
      this.setState({name}, () => {
        // this.refInput.current.select();
      });
    }
  }

  componentWillUnmount() {
    this.props.clear();
  }

  onClickSave(event) {
    event?.preventDefault && event.preventDefault();
    this.refInput.current.blur();
    const {name} = this.state;
    console.log(name);
    this.props.setDisplayName(name).then(() => {
      this.onClose();
    });
  }

  onChangeName(event) {
    const name = (event.target.value || '').substring(0, 20);
    this.setState({name});
  }

  onClose() {
    this.props.onClose();
  }

  renderMain() {
    const {pending, failed, currentUser} = this.props;
    const {name} = this.state;
    if (!currentUser) return null;
    const charLimitText = (() => {
      if (!name) return '20 characters max';
      return `${name.length}/20 characters`;
    })();

    return (<>
      <h1 className="snk-modal-title">Display Name</h1>
      <p className="snk-modal-p">Enter a name for the leaderboards.</p>
      <form onSubmit={this.onClickSave} action="/">
        <input className="snk-input" type="text" value={name || ''} onChange={this.onChangeName} enterKeyHint="go" ref={this.refInput} defaultValue={currentUser?.displayName} placeholder={currentUser?.defaultDisplayName || 'Display Name'} />
        <p className="snk-modal-p snk-modal-display-name-chars">{charLimitText}</p>
        {failed && (
          <p className="snk-modal-p error">Oops! Something went wrong. Please try again.</p>
        )}
        <div className="snk-modal-actions">
          <button className="snk-btn mint" onClick={this.onClickSave} disabled={pending}>{pending ? 'Saving...' : 'Save'}</button>
        </div>
      </form>
    </>);
  }

  render() {
    const content = this.renderMain();

    return (
      <Modal bgClose={false} className="snk-modal-display-name snk-modal" onClose={this.onClose}>
        {content}
      </Modal>
    );
  }

}

ModalDisplayName.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  currentUser: AuthDuck.Slx.currentUser(state),
  pending: AcctDuck.Slx.setDisplayNamePending(state),
  failed: AcctDuck.Slx.setDisplayNameFailed(state),
  success: AcctDuck.Slx.setDisplayNameSuccess(state),
});

const dispatchToProps = (dispatch) => ({
  setDisplayName: (displayName) => dispatch(AcctDuck.Ax.setDisplayName(displayName)),
  clear: () => dispatch(AcctDuck.Ax.clearSetDisplayName()),
});

export default connect(stateToProps, dispatchToProps)(ModalDisplayName);
