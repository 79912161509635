'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _pathParser = require('path-parser');

var _pathParser2 = _interopRequireDefault(_pathParser);

var _utils = require('./utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Router = function () {
  function Router() {
    _classCallCheck(this, Router);

    this._routes = [];
    this._context = {};
    this._latestRouteFinishCb = null;
  }

  /*
   *  PRIVATE HELPERS
   */

  _createClass(Router, [{
    key: '_match',
    value: function _match(path) {
      var matchPredicate = function matchPredicate(route) {
        return route.pattern.match(path, true);
      };
      return this._routes.filter(matchPredicate)[0];
    }
  }, {
    key: '_buildLocation',
    value: function _buildLocation(url, pattern) {
      var _parseUrl = (0, _utils.parseUrl)(url),
          path = _parseUrl.path,
          query = _parseUrl.query,
          queryString = _parseUrl.queryString;

      var params = pattern ? pattern.match(path, true) : {};
      return {
        url: url,
        path: path,
        params: params,
        query: query,
        queryString: queryString
      };
    }

    /*
     *  PRIVATE EVENTS
     */

  }, {
    key: '_handleDone',
    value: function _handleDone(routeFinishCb) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      // ignore if not the latest
      var isLatest = routeFinishCb === this._latestRouteFinishCb;
      if (!isLatest) return;
      //
      if (data.error) return routeFinishCb(null, null, data.error);
      if (data.redirect) return routeFinishCb(null, data.redirect, null);
      routeFinishCb(data, null, null);
    }

    /*
     *  PUBLIC API
     */

  }, {
    key: 'setContext',
    value: function setContext(context) {
      this._context = context;
    }
  }, {
    key: 'use',
    value: function use(pattern, handler) {
      this._routes.push({
        pattern: new _pathParser2.default(pattern),
        handler: handler
      });
    }
  }, {
    key: 'startRouting',
    value: function startRouting(history, routeFinishCb) {
      var _this = this;

      var preRouted = true; // convenience on the client to know this route was already handled by server
      history.listen(function (location) {
        var url = '' + location.pathname + location.search;
        _this.route(url, routeFinishCb, preRouted);
        preRouted = false;
      });
      // immediately invoke if on client - was done by history in previous versions
      if (typeof window !== 'undefined') {
        var url = '' + window.location.pathname + window.location.search;
        this.route(url, routeFinishCb, preRouted);
        preRouted = false;
      }
    }
  }, {
    key: 'route',
    value: function route(url) {
      var routeFinishCb = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
      var preRouted = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      // find corresponding route
      var path = (0, _utils.parseUrl)(url).path;
      var route = this._match(path);
      var location = this._buildLocation(url, route && route.pattern);
      // first arg to finishCallback will always be location, regardless of outcome
      routeFinishCb = routeFinishCb.bind(this, location);
      // update reference to latestRouteFinishCb for later
      this._latestRouteFinishCb = routeFinishCb;
      // when no route found, invoke callback without any args (no args indicates not found)
      if (!route) return routeFinishCb(null, null, null);
      // invoke the route
      var done = this._handleDone.bind(this, routeFinishCb);
      var context = this._context;
      route.handler(done, location, context, preRouted);
    }
  }]);

  return Router;
}();

exports.default = Router;
;