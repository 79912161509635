import React from 'react';
import {connect} from 'react-redux';
import { Capacitor } from '@capacitor/core';

import Modal       from 'app/components/common/modal';
import Avatar       from 'app/dotsnake/components/avatar';

import Link       from 'app/components/common/link';
import Personal   from 'app/dotsnake/components/home/personal';
import LayoutMain from 'app/dotsnake/components/layout-main';
import LivesDuck  from 'app/dotsnake/ducks/lives';
import AuthDuck   from 'app/ducks/auth';
import history    from 'app/history';
import paths      from 'app/paths';

const isProd = process.env.TYBRO_ENV === 'production';

  // '#CC666E',
  // '#CE826B',
  // '#CC9466',
  // '#E5C07B',
  // '#CFB76B',
  // '#BCC247',
  // '#98C379',
  // '#78C281',
  // '#77C298',
  // '#76C2BF',
  // '#6B9BCE',
  // '#5F63B9',
  // '#706BCF',
  // '#8B5FBA',
  // '#A17DBB',
  // '#BA7DB3',
  // '#C275A9',
  // '#C27480',

// SmileyAngel
// SmileyBlessed
// SmileyBright
// SmileyCheeky
// SmileyCheerful
// SmileyConcerned
// SmileyCowboy
// SmileyCrazyTongue
// SmileyCrazy
// SmileyCryingRainbow
// SmileyDisappointed
// SmileyDizzy
// SmileyDrop
// SmileyExplosion
// SmileyEyesOnly
// SmileyGlasses
// SmileyGrin
// SmileyHappy1
// SmileyHappy
// SmileyHypnotized
// SmileyInLove
// SmileyKiss1
// SmileyKiss2
// SmileyKissHeart
// SmileyKiss
// SmileyLolSide
// SmileyLolSideways
// SmileyLol
// SmileyLookOneEye
// SmileyNasty
// SmileyOneEyeSmile1
// SmileyOneEyeSmile
// SmileyOops
// SmileyPetrified
// SmileyPrank
// SmileyRich
// SmileyNerd
// SmileySad
// SmileyShineBigEyes
// SmileyShine
// SmileySleepy
// SmileySmile1
// SmileySmile2
// SmileySmileUpsideDown
// SmileySmile
// SmileySmirkGlasses1
// SmileySmirk
// SmileyThrilled
// SmileyTongue1
// SmileyTongue3
// SmileyTongueSticking
// SmileyTongue
// SmileyTrouble
// SmileyVeryHappy
// SmileyWink
// SmileyYawn

class ModalFoo extends React.PureComponent {
  render() {
    return (
      <Modal className="snk-modal snk-foo">
        <div className="snk-foo-body">
        <Avatar size={80} className="snk-foo-avatar" color="#8B5FBA" iconName="SmileyCheerful" />
        <div className="snk-foo-name">TidyChupacabra</div>
        <div className="snk-foo-city">Bunbury, Austrailia</div>
        </div>
      </Modal>
    );
  }
}

class PageHome extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickPlay         = this.onClickPlay.bind(this);
    this.onClickInstructions = this.onClickInstructions.bind(this);
    this.onClickLeaderboards = this.onClickLeaderboards.bind(this);
    this.onClickSettings     = this.onClickSettings.bind(this);
  }

  componentDidMount() {
    this.props.refresh();
  }

  onClickPlay() {
    history.push(paths.snkPlay());
  }

  onClickInstructions() {
    history.push(paths.snkInstructions());
  }

  onClickLeaderboards() {
    history.push(paths.snkScores());
  }

  onClickSettings() {
    history.push(paths.snkSettings());
  }

  render() {
    const isMobile = Capacitor.getPlatform() === 'ios';
    const {canPlay} = this.props;
    return (
      <LayoutMain className="snk-home">
        <div className="snk-home-top">
          {isMobile && <Personal />}
        </div>
        <div className="snk-home-main">
          <div className="snk-home-main-con">
            <h1 className="snk-home-main-h1">Dot Snake</h1>
            {/* <p>No ads. No psychological tricks. Tell your friends.</p> */}
            <p>No ads. No pay-to-win. Just fun.</p>
            <div className="snk-home-main-btns">
              <button className="snk-btn mint" onClick={this.onClickLeaderboards}>Leaderboards</button>
              {(isMobile || !isProd) && (
                <button className="snk-btn mint" onClick={this.onClickSettings}>Settings</button>
              )}
              <button className="snk-btn mint" onClick={this.onClickInstructions}>How to Play</button>
              <button className="snk-btn mint bold" disabled={!canPlay} onClick={this.onClickPlay}>Play Now</button>
            </div>
          </div>
        </div>
        {/* <div className="snk-home-bottom"> */}
        {/* </div> */}
        {/* <ModalFoo /> */}
      </LayoutMain>
    );
  }

}

const stateToProps = (state) => ({
  canPlay: LivesDuck.Slx.canPlay(state),
});

const dispatchToProps = (dispatch) => ({
  refresh: () => dispatch(AuthDuck.Ax.dotsnakeRefresh()),
});

export default connect(stateToProps, dispatchToProps)(PageHome);
