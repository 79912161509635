import React from 'react';
import moment from 'moment';

import MainApi from 'app/apis/main';
import Icon    from 'app/components/common/icon';

const Logo = () => (
  <svg viewBox="0 0 60 51">
    <polygon points="28.9536133 19.3427734 28.9536133 27.4360352 0.813964844 41.6801758 0.813964844 34.1347656 22.0307617 23.3769531 0.813964844 12.644043 0.813964844 5.19824219" />
    <polygon points="33.9091797 43 59.4091797 43 59.4091797 50.6450195 33.9091797 50.6450195" />
  </svg>
);

class PageHome extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      contactEmail: '',
      contactSubject: '',
      contactMessage: '',
      contactPending: false,
      contactSubmitted: false,
    };

    this.refConApps = React.createRef();
    this.refConContact = React.createRef();

    this.onSubmitContact = this.onSubmitContact.bind(this);
    this.onChangeContactEmail = this.onChangeContactEmail.bind(this);
    this.onChangeContactSubject = this.onChangeContactSubject.bind(this);
    this.onChangeContactMessage = this.onChangeContactMessage.bind(this);
    this.onClickApps = this.onClickApps.bind(this);
    this.onClickContact = this.onClickContact.bind(this);
  }

  onSubmitContact(event) {
    event.preventDefault();
    this.setState({contactPending: true});
    MainApi.publicContact(this.contactBody).then(() => {
      this.setState({contactPending: false, contactSubmitted: true});
    });
  }

  onChangeContactEmail(event) {
    const value = event.target.value || '';
    this.setState({contactEmail: value});
  }
  onChangeContactSubject(event) {
    const value = event.target.value || '';
    this.setState({contactSubject: value});
  }
  onChangeContactMessage(event) {
    const value = event.target.value || '';
    this.setState({contactMessage: value});
  }

  onClickApps() {
    const el = this.refConApps.current;
    el.scrollIntoView({behavior: 'smooth'});
  }

  onClickContact() {
    const el = this.refConContact.current;
    el.scrollIntoView({behavior: 'smooth'});
  }

  get contactBody() {
    const email = (this.state.contactEmail || '').trim();
    const subject = (this.state.contactSubject || '').trim();
    const message = (this.state.contactMessage || '').trim();
    return {email, subject, message};
  }

  get canSubmitContact() {
    const body = this.contactBody;
    if (!body.email) return false;
    if (!body.email.split('@')[0]) return false;
    if (!body.email.split('@')[1]) return false;
    if (!body.subject) return false;
    if (!body.message) return false;
    return true;
  }

  render() {
    return (
      <div className="tybro">

        <div className="tybro-main">
          <div className="tybro-main-widther">
            <div className="tybro-main-name">
              <div className="tybro-main-name-logo-con">
                <Logo />
              </div>
              <h1 className="tybro-main-name-h1">Tybro Apps</h1>
            </div>
            <div className="tybro-main-content-con">
              <div className="tybro-main-content">
                <p className="tybro-main-content-p">We’re a team of engineers and designers who are passionate about building web and mobile applications with excellent user experiences.</p>
                <div className="tybro-main-content-actions">
                  <button onClick={this.onClickApps} className="tybro-btn">Apps <Icon.Caret direction="right" /></button>
                  <button onClick={this.onClickContact} className="tybro-btn">Contact Us <Icon.Caret direction="right" /></button>
                </div>
              </div>
              <div className="tybro-main-content-con-spacer" />
            </div>
            <img className="tybro-main-ill" src="/images/dev-illustration.svg" />
          </div>
        </div>

        <div className="tybro-snk" ref={this.refConApps}>
          <div className="tybro-snk-widther">
            <div className="tybro-snk-header">
              <h1 className="tybro-snk-h1">Dot Snake</h1>
              <a href="https://dotsnake.app" className="tybro-btn">Learn More <Icon.Caret direction="right" /></a>
            </div>
            <div className="tybro-snk-imgs-con">
              <div className="tybro-snk-imgs">
                <img src="/images/dotsnake/app-store-ss-1.png" />
                <img src="/images/dotsnake/app-store-ss-2.png" />
                <img src="/images/dotsnake/app-store-ss-3.png" />
                <img src="/images/dotsnake/app-store-ss-4.png" />
                <img src="/images/dotsnake/app-store-ss-5.png" />
              </div>
            </div>
          </div>
        </div>

        <div className="tybro-contact" ref={this.refConContact}>
          <div className="tybro-contact-widther">
            <form className="tybro-contact-form" method="POST" onSubmit={this.onSubmitContact}>
              <h1 className="tybro-contact-form-h1">Contact Us</h1>
              {this.state.contactSubmitted ? (<>
                <p className="tybro-contact-form-thankyou">Message received! We’ll get back to you shortly.</p>
              </>) : (<>
                <label htmlFor="contact-email">
                  Your Email
                  <input className="tybro-contact-form-input" id="contact-email" type="email" placeholder="applover@example.com" value={this.state.contactEmail} onChange={this.onChangeContactEmail} />
                </label>
                <label htmlFor="contact-subject">
                  Subject
                  <input className="tybro-contact-form-input" id="contact-subject" type="text" placeholder="Greetings" value={this.state.contactSubject} onChange={this.onChangeContactSubject} />
                </label>
                <label htmlFor="contact-message">
                  Message
                  <textarea rows="3" className="tybro-contact-form-input" id="contact-message" placeholder="I’d like to talk about..." value={this.state.contactMessage} onChange={this.onChangeContactMessage} />
                </label>
                <input id="contact-submit" type="submit" value={this.state.contactPending ? 'Submitting...' : 'Submit'} disabled={!this.canSubmitContact || this.state.contactPending} />
              </>)}
            </form>
          </div>
        </div>

        <div className="tybro-footer">
          <div className="tybro-footer-widther">
            <span>Copyright © {moment().format('YYYY')} Tybro Apps, LLC</span>
            <a href="/privacy-policy.html">Privacy Policy</a>
          </div>
        </div>

      </div>
    );
  }

}

export default PageHome;
