import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import numeral from 'numeral';

import Avatar      from 'app/dotsnake/components/avatar';
import cityHelpers from 'app/helpers/city';

class Score extends React.PureComponent {

  render() {
    const {score, rank, safeEdges} = this.props;
    if (!score) return null;
    const {score: scoreNumber, scoredAt, city, user} = score;
    const sageEdgesClass = safeEdges ? 'safe-edges' : '';

    return (
      <div className={`snk-score ${sageEdgesClass}`}>
        {!!rank && (
          <div className="snk-score-rank">#{rank}</div>
        )}
        <Avatar size={30} color={user?.avatarColor} iconName={user?.avatarIcon} className="snk-score-avatar" />
        <div className="snk-score-text">
          <div className="snk-score-text-line1">
            <div className="snk-score-name">{user?.displayName}</div>
            <div className="snk-score-score">{numeral(scoreNumber).format('0,0')}</div>
          </div>
          <div className="snk-score-text-line2">
            <div className="snk-score-city">{city ? cityHelpers.getName(city) : ''}</div>
            <div className="snk-score-date">{moment(scoredAt).format('MMM D, YYYY')}</div>
          </div>
        </div>
      </div>
    );
  }

}

Score.propTypes = {
  score: PropTypes.shape({
    score: PropTypes.number.isRequired,
    scoredAt: PropTypes.string.isRequired,
    city: PropTypes.object,
    user: PropTypes.shape({
      avatarColor: PropTypes.string,
      avatarIcon: PropTypes.string,
      displayName: PropTypes.string.isRequired,
    }),
  }),
  rank: PropTypes.number,
  safeEdges: PropTypes.bool,
};

Score.defaultProps = {
  safeEdges: true,
};

export default Score;
