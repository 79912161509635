import _ from 'lodash';

import {
  Apps,
}                      from 'app/constants';
import routerBabyByte  from 'app/routers/babybyte';
import routerDotSnake  from 'app/routers/dotsnake';
import routerTybroApps from 'app/routers/tybro-apps';
import routerTyler     from 'app/routers/tyler';
import storage         from 'app/storage';

const tybroEnv = process.env.TYBRO_ENV || 'production';
const isDev = tybroEnv === 'development';

const dotsnake = {
  key: Apps.SNAKE,
  domain: isDev
    ? 'tybro0103-snk.ngrok.io'
    // ? 'dotsnake.localhost'
    : 'dotsnake.app',
  appScheme: 'dotsnake',
  baseUrl: isDev
    ? `https://tybro0103-snk.ngrok.io`
    // ? `http://dotsnake.localhost:1115`
    : 'https://dotsnake.app',
  createRouter: routerDotSnake,
  storage: storage.capStorage,
};

const babybyte = {
  key: Apps.BB,
  domain: isDev
    ? 'babybyte.localhost'
    : 'babybyte.app',
  baseUrl: isDev
    ? `http://babybyte.localhost:1115`
    : 'https://babybyte.app',
  createRouter: routerBabyByte,
  storage: storage.lsStorage,
};

const tybroApps = {
  key: Apps.TYBRO_APPS,
  domain: isDev
    ? 'tybroapps.localhost'
    : 'tybroapps.com',
  baseUrl: isDev
    ? `http://tybroapps.localhost:1115`
    : 'https://tybroapps.com',
  createRouter: routerTybroApps,
  storage: storage.lsStorage,
};

const tyler = {
  key: Apps.TYLER,
  domain: isDev
    ? 'tyler.localhost'
    : 'tyler.tybro.io',
  baseUrl: isDev
    ? `http://tyler.localhost:1115`
    : 'https://tyler.tybro.io',
  createRouter: routerTyler,
  storage: storage.lsStorage,
};

const apps = [
  dotsnake,
  babybyte,
  tybroApps,
  tyler,
];

const byDomain = apps.reduce((acc, app) => {
  return {...acc, [app.domain]: app};
}, {});

const byKey = apps.reduce((acc, app) => {
  return {...acc, [app.key]: app};
}, {});

// only for client use
const getApp = () => {
  if (__IS_SERVER__) return null;
  return byKey[_.get(window, '__TYBRO.app')];
};

export default {
  dotsnake,
  tyler,
  babybyte,
  tybroApps,
  byDomain,
  byKey,
  all: apps,
  getApp,
};
