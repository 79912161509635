import _ from 'lodash';
import { createSelector } from 'reselect';

import Apps         from 'app/apps';
import mainApi      from 'app/apis/main';
import utils        from 'app/helpers/utils';
import history      from 'app/history';
import paths        from 'app/paths';
import reducerUtils from 'app/reducers/utils';
import EntitiesSlx  from 'app/selectors/entities';


/*
 *  Actions
 */

const Types = {
  SNK_GET_CITY: 'ACCOUNT_SNK_GET_CITY',
  SNK_CACHE_CITY: 'ACCOUNT_SNK_CACHE_CITY',
  SNK_SET_CITY: 'ACCOUNT_SNK_SET_CITY',
  SNK_CLEAR_SET_CITY: 'ACCOUNT_SNK_CLEAR_SET_CITY',
};

const Ax = {

  snkGetCity: () => (dispatch, getState) => {
    const promise = mainApi.citiesSnkGet().then(({city}) => {
      dispatch(Ax.snkCacheCity(city));
    });
    return dispatch({type: Types.SNK_GET_CITY, promise, _entities: ['city']});
  },

  snkSetCity: (cityId, updateRecents) => (dispatch, getState) => {
    const promise = mainApi.citiesSnkSet(cityId, updateRecents).then(({city}) => {
      dispatch(Ax.snkCacheCity(city));
    });
    return dispatch({type: Types.SNK_SET_CITY, promise, cityId, _entities: ['city']});
  },

  snkClearSetCity: () => {
    return {type: Types.SNK_CLEAR_SET_CITY};
  },

  snkCacheCity: (city) => {
    const app = Apps.getApp();
    if (app?.storage) {
      if (city) {
        app.storage.setObj('city', city);
      } else {
        app.storage.clear('city');
      }
    }
    return {type: Types.SNK_CACHE_CITY, city};
  },

};



/*
 *  Reducer
 */

const initialState = {
  snkGetCityPending: false,
  snkCity: null,
  snkSetCityPending: false,
  snkSetCityFailed: false,
};

const reducer = reducerUtils.createReducer(initialState, {

  [`${Types.SNK_GET_CITY}_PENDING`]: (state, action) => {
    return {...state,
      snkGetCityPending: true,
    };
  },
  [`${Types.SNK_GET_CITY}_RESOLVED`]: (state, action) => {
    return {...state,
      snkGetCityPending: false,
      // snkCity: action.result?.city || null,
    };
  },
  [`${Types.SNK_GET_CITY}_REJECTED`]: (state, action) => {
    return {...state,
      snkGetCityPending: false,
    };
  },

  [`${Types.SNK_SET_CITY}_PENDING`]: (state, action) => {
    return {...state,
      snkSetCityPending: true,
      snkSetCityFailed: false,
    };
  },
  [`${Types.SNK_SET_CITY}_RESOLVED`]: (state, action) => {
    return {...state,
      snkSetCityPending: false,
      snkSetCityFailed: false,
      // snkCity: action.result?.city || null,
    };
  },
  [`${Types.SNK_SET_CITY}_REJECTED`]: (state, action) => {
    return {...state,
      snkSetCityPending: false,
      snkSetCityFailed: true,
    };
  },
  [Types.SNK_CLEAR_SET_CITY]: (state, action) => {
    return {...state,
      snkSetCityPending: false,
      snkSetCityFailed: false,
    };
  },

  [Types.SNK_CACHE_CITY]: (state, {city}) => {
    return {...state,
      snkCity: city,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selSnkGetCityPending = state => state.cities.snkGetCityPending;
  const selSnkCity           = state => state.cities.snkCity;
  const selSnkSetCityPending = state => state.cities.snkSetCityPending;
  const selSnkSetCityFailed  = state => state.cities.snkSetCityFailed;

  return {
    snkGetCityPending: selSnkGetCityPending,
    snkCity: selSnkCity,
    snkSetCityPending: selSnkSetCityPending,
    snkSetCityFailed: selSnkSetCityFailed,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
