
const entitiesMiddleware = (store) => (next) => (action) => {
  const {_entities: entityNames, result} = action;
  const checkEntities = !!(entityNames && result);
  if (!checkEntities) return next(action);

  entityNames.forEach((entityName) => {
    const entities = result[entityName];
    if (!entities) return;
    next({type: `ENTITY_SET_${entityName.toUpperCase()}`, entities});
  });

  return next(action);
};

export default entitiesMiddleware;
