import _ from 'lodash';
import { createSelector } from 'reselect';

const selUsers = state => state.entities.users;

const selUserById = (state, id) => state.entities.users[id];

export default {
  users: selUsers,

  userById: selUserById,
};
