import PropTypes from 'prop-types';
import React from 'react';

import Link       from 'app/components/common/link';
import MainLayout from 'app/components/layout/main-layout';
import paths      from 'app/paths';

class PageHome extends React.PureComponent {

  render() {
    return (
      <MainLayout>
        <div className="widther">
          <h1>Home</h1>
          <Link href={paths.login()}>Login</Link>
          <Link href={paths.signup()}>Sign Up</Link>
        </div>
      </MainLayout>
    );
  }

}

PageHome.propTypes = {
};

PageHome.defaultProps = {
};

export default PageHome;
