import axios from 'axios';

import {
  DeviceTypes,
} from 'app/constants';

const client = axios.create({
  baseURL: '/api',
});

const Endpoints =  {

  /*
   *  Auth
   */

  authLoginsCreate: ({email, deviceType}) => {
    const body = {email, deviceType};
    return client.post('/auth/logins', body).then(res => res.data);
  },

  authSessionsLogout: () => {
    return client.delete('/auth/sessions/current').then(res => res.data);
  },

  authSessionsDeviceLogin: ({} = {}) => {
    const body = {deviceType: DeviceTypes.IOS};
    return client.post('/auth/sessions/device', body).then(res => res.data);
  },

  authSessionsEvRedeem: (evId) => {
    return client.patch(`/auth/email-verifications/${evId}/redeem`).then(res => res.data);
  },

  authSessionsExtend: () => {
    return client.put('/auth/sessions/current/extend').then(res => res.data);
  },

  authWhoami: () => {
    return client.get('/auth/whoami').then(res => res.data);
  },

  authDeleteAccount: () => {
    return client.delete('/auth/account').then(res => res.data);
  },

  /*
   *  Users
   */

  usersSetDisplayName: (displayName) => {
    const body = {displayName};
    return client.patch('/users/display-name', body).then(res => res.data);
  },

  usersSetPrefs: (prefs={}) => {
    const body = {prefs};
    return client.patch('/users/prefs', body).then(res => res.data);
  },

  /*
   *  SNK SCORES
   */

  snkScoresCreate: (score) => {
    const body = {score};
    return client.post('/snk-scores', body).then(res => res.data);
  },

  snkScoresSearch: (params={}) => {
    return client.get('/snk-scores', {params}).then(res => res.data);
  },

  /*
   *  Cities
   */

  citiesSearch: ({search, filterIpCountry=false} = {}) => {
    const params = {search, filterIpCountry};
    return client.get('/cities', {params}).then(res => res.data);
  },

  citiesSnkGet: () => {
    return client.get('/cities/snk').then(res => res.data);
  },

  citiesSnkSet: (cityId, updateRecents=false) => {
    const body = {cityId, updateRecents};
    return client.put('/cities/snk', body).then(res => res.data);
  },

  /*
   *  Public
   */

  publicContact: ({email, subject, message}) => {
    const body = {email, subject, message};
    return client.post('/public/contact', body).then(res => res.data);
  },

};

export {
  client,
};

export default Endpoints;
