import _ from 'lodash';
import { createSelector } from 'reselect';

import reducerUtils from 'app/reducers/utils';

/*
 *  Actions
 */

const Types = {
  SET_SAFE_AREA: 'DEVICE_SET_SAFE_AREA',
  SET_ORIENTATION: 'DEVICE_SET_ORIENTATION',
};

const Ax = {

  setSafeArea: (insets) => {
    return {type: Types.SET_SAFE_AREA, insets};
  },

  setOrientation: (orientation) => {
    return {type: Types.SET_ORIENTATION, orientation};
  },

};



/*
 *  Reducer
 */

const initialState = {
  safeAreaInsets: {top: 0, right: 0, bottom: 0, left: 0},
  orientation: 'landscape-primary',
};

const reducer = reducerUtils.createReducer(initialState, {

  [Types.SET_SAFE_AREA]: (state, action) => {
    return {...state,
      safeAreaInsets: {...action.insets},
    };
  },

  [Types.SET_ORIENTATION]: (state, action) => {
    return {...state,
      orientation: action.orientation,
    };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selSafeAreaInsets = (state) => state.device.safeAreaInsets;
  const selOrientation    = (state) => state.device.orientation;

  return {
    safeAreaInsets: selSafeAreaInsets,
    orientation: selOrientation,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
