import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

// import SafeArea    from 'app/components/common/safe-area';
import ScrollyPage from 'app/dotsnake/components/scrolly-page';
import AuthDuck    from 'app/ducks/auth';
import RoutingDuck from 'app/ducks/routing';
import history     from 'app/history';
import paths       from 'app/paths';

class PageEmailVerification extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      evId: null,
    };

    this.onClickDone = this.onClickDone.bind(this);
  }

  componentDidMount() {
    this.props.clearEvRedeem();
    this.verify();
  }

  componentWillUnmount() {
    this.props.clearEvRedeem();
  }

  componentDidUpdate(prevProps) {
    this.verify();
  }

  onClickDone() {
    history.push(paths.snkHome());
  }

  verify() {
    const {evId} = this.props;
    if (this.state.evId === evId) return;
    this.setState({evId}, () => {
      this.props.evRedeem(evId);
    });
  }

  renderError() {
    const {failType} = this.props;
    const msg = (() => {
      if (failType === 'expired') return 'The link you followed has expired. You may need to try again.';
      if (failType === 'already-redeemed') return 'The link you followed has already been used. You may need to try again.';
      return 'Something went wrong while verifying the link. Please check your internet connection and try again.';
    })();
    return (<>
      <h1 className="snk-ev-h1">Oops!</h1>
      <p className="snk-ev-p">{msg}</p>
      <button className="snk-btn mint" onClick={this.onClickDone}>Continue</button>
    </>);
  }

  renderSuccess() {
    const {currentUser} = this.props;
    const name = currentUser?.displayName || currentUser?.defaultDisplayName;
    const title = name ? `Welcome, ${name}!` : 'Welcome!';
    return (<>
      <h1 className="snk-ev-h1">{title}</h1>
      <p className="snk-ev-p">You are now signed in.</p>
      <button className="snk-btn mint" onClick={this.onClickDone}>Continue</button>
    </>);
  }

  renderPending() {
    return (<>
      <h1 className="snk-ev-h1">Verifying</h1>
      <p className="snk-ev-p">Just a sec...</p>
    </>);
  }

  render() {
    const {success, failType} = this.props;
    const mainContent = (() => {
      if (success) return this.renderSuccess();
      if (failType) return this.renderError();
      return this.renderPending();
    })();
    return (
      <ScrollyPage className="snk-ev">
        <div className="snk-ev-body">
          <div className="snk-ev-body-con">
            {mainContent}
          </div>
        </div>
      </ScrollyPage>
    );
  }

}

const stateToProps = (state) => ({
  evId: RoutingDuck.Slx.params(state).id,
  pending: AuthDuck.Slx.evRedeemPending(state),
  failType: AuthDuck.Slx.evRedeemFailType(state),
  success: AuthDuck.Slx.evRedeemSuccess(state),
  currentUser: AuthDuck.Slx.currentUser(state),
});

const dispatchToProps = (dispatch) => ({
  evRedeem: (evId) => dispatch(AuthDuck.Ax.evRedeem(evId)),
  clearEvRedeem: () => dispatch(AuthDuck.Ax.clearEvRedeem()),
});

export default connect(stateToProps, dispatchToProps)(PageEmailVerification);
