import _ from 'lodash';
import { createSelector } from 'reselect';

import {
  Pages,
  // NavItems,
}                   from 'app/constants';
import utils        from 'app/helpers/utils';
import history      from 'app/history';
import reducerUtils from 'app/reducers/utils';



/*
 *  Actions
 */

const Types = {
  SET_LOCATION: 'ROUTING_SET_LOCATION',
  SET_PAGE: 'ROUTING_SET_PAGE',
  SET_APP: 'ROUTING_SET_APP',
  SET_HAS_CHANGES: 'ROUTING_SET_HAS_CHANGES',
  CONFIRM_NAV_TO: 'ROUTING_CONFIRM_NAV_TO',
  CANCEL_NAV: 'ROUTING_CANCEL_NAV',
};

const Ax = {

  setLocation: (location) => {
    return { type: Types.SET_LOCATION, location };
  },

  setPage: (page) => {
    return { type: Types.SET_PAGE, page };
  },

  hasChanges: (hasChanges=true) => {
    return { type: Types.SET_HAS_CHANGES, hasChanges };
  },

  navToQuery: (attrs) => (dispatch, getState) => {
    const newPath = Slx.currentPathWithNewParams(getState(), attrs);
    return dispatch(Ax.navTo(newPath));
  },

  navTo: (path, {skipConfirm=false} = {}) => (dispatch, getState) => {
    const hasChanges = Slx.hasChanges(getState());
    if (!hasChanges || skipConfirm) {
      history.push(path);
    } else {
      dispatch(Ax.confirmNavTo(path));
    }
  },

  confirmNavTo: (path) => {
    return { type: Types.CONFIRM_NAV_TO, path };
  },

  cancelNav: () => {
    return { type: Types.CANCEL_NAV };
  },

  setApp: (app) => {
    return {type: Types.SET_APP, app};
  },

};



/*
 *  Reducer
 */

const initialState = {
  page: null,
  location: {
    url: '/',
    path: '/',
    queryString: '',
    query: {},
    params: {},
  },
  hasChanges: false, // when true, a confirmation modal will be shown upon navigation
  confirmNavTo: null,
  app: null,
};

const reducer = reducerUtils.createReducer(initialState, {

  [Types.SET_LOCATION]: (state, { location }) => {
    return {...state,
      location,
      hasChanges: false,
      confirmNavTo: null,
    };
  },

  [Types.SET_PAGE]: (state, { page }) => {
    return { ...state, page };
  },

  [Types.SET_APP]: (state, { app }) => {
    return { ...state, app };
  },

  [Types.SET_HAS_CHANGES]: (state, { hasChanges }) => {
    return { ...state, hasChanges };
  },
  [Types.CONFIRM_NAV_TO]: (state, { path }) => {
    return { ...state, confirmNavTo: path };
  },
  [Types.CANCEL_NAV]: (state, action) => {
    return { ...state, confirmNavTo: null };
  },

});



/*
 *  Selectors
 */

const Slx = (() => {

  const selPage         = state => state.routing.page;
  const selApp          = state => state.routing.app;
  const selHasChanges   = state => state.routing.hasChanges;
  const selConfirmNavTo = state => state.routing.confirmNavTo;
  const selLocation     = state => state.routing.location;
  const selParams       = state => state.routing.location.params;
  const selQuery        = state => state.routing.location.query;
  const selPath         = state => state.routing.location.path;
  const selUrl          = state => state.routing.location.url;

  // const selActiveNavItem = createSelector(
  //   [selPage],
  //   (page) => {
  //     return ({
  //       [Pages.GROUPS]: NavItems.GROUPS,
  //       [Pages.EVENTS]: NavItems.EVENTS,
  //     })[page];
  //   }
  // );

  const selCurrentPathWithNewParams = (state, newParams = {}) => {
    const {query: oldParams, path} = state.routing.location;
    const newQuery = {...oldParams, ...newParams};
    const queryString = utils.buildQueryString(newQuery);
    return `${path}${queryString}`;
  };

  return {
    page: selPage,
    app: selApp,
    location: selLocation,
    params: selParams,
    query: selQuery,
    path: selPath,
    url: selUrl,
    // activeNavItem: selActiveNavItem,
    currentPathWithNewParams: selCurrentPathWithNewParams,
    hasChanges: selHasChanges,
    confirmNavTo: selConfirmNavTo,
  };

})();



export {Types, Ax, reducer, Slx};
export default {Types, Ax, reducer, Slx};
