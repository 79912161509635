import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import MainApi      from 'app/apis/main';
import Icon         from 'app/components/common/icon';
import Link         from 'app/components/common/link';
import SafeArea     from 'app/components/common/safe-area';
import Avatar       from 'app/dotsnake/components/avatar';
import ScrollyPage  from 'app/dotsnake/components/scrolly-page';
import ModalAvatar  from 'app/dotsnake/components/settings/modal-avatar';
import ModalCity    from 'app/dotsnake/components/settings/modal-city';
import ModalDelete  from 'app/dotsnake/components/settings/modal-delete-account';
import ModalName    from 'app/dotsnake/components/settings/modal-display-name';
import ModalLogin   from 'app/dotsnake/components/settings/modal-login';
import ModalLogout  from 'app/dotsnake/components/settings/modal-logout';
import ModalRestore from 'app/dotsnake/components/settings/modal-restore-purchases';
import LivesDuck    from 'app/dotsnake/ducks/lives';
import ScoresDuck   from 'app/dotsnake/ducks/scores';
import SnakeManager from 'app/dotsnake/game/manager';
import AuthDuck     from 'app/ducks/auth';
import CitiesDuck   from 'app/ducks/cities';
import cityHelpers  from 'app/helpers/city';
import paths        from 'app/paths';

class PageSettings extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      showLoginModal: false,
      showNameModal: false,
      showAvatarModal: false,
      showCityModal: false,
      showLogoutModal: false,
      showRestoreModal: false,
      showDeleteModal: false,

      ilDisplayPrice: null,
    };

    this.onClickDeviceLogin = this.onClickDeviceLogin.bind(this);
    this.onClickWhoami = this.onClickWhoami.bind(this);
    this.onClickLogin = this.onClickLogin.bind(this);
    this.onCloseLogin = this.onCloseLogin.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
    this.onCloseLogout = this.onCloseLogout.bind(this);
    this.onClickExtend = this.onClickExtend.bind(this);
    this.onClickDisplayName = this.onClickDisplayName.bind(this);
    this.onCloseDisplayName = this.onCloseDisplayName.bind(this);
    this.onClickAvatar = this.onClickAvatar.bind(this);
    this.onCloseAvatar = this.onCloseAvatar.bind(this);
    this.onClickCity = this.onClickCity.bind(this);
    this.onCloseCity = this.onCloseCity.bind(this);
    this.onClickPurchaseInfiniteLives = this.onClickPurchaseInfiniteLives.bind(this);
    this.onClickRestore = this.onClickRestore.bind(this);
    this.onCloseRestore = this.onCloseRestore.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.onCloseDelete = this.onCloseDelete.bind(this);
    this.onClickDecrement = this.onClickDecrement.bind(this);
  }

  componentDidMount() {
    SnakeManager.getInfiniteLivesPrice().then((displayPrice) => {
      this.setState({ilDisplayPrice: displayPrice});
    });
  }

  onClickPurchaseInfiniteLives() {
    SnakeManager.purchaseInfiniteLives();
  }

  onClickRestore() {
    this.setState({showRestoreModal: true});
  }
  onCloseRestore() {
    this.setState({showRestoreModal: false});
  }

  onClickDelete() {
    this.setState({showDeleteModal: true});
  }
  onCloseDelete() {
    this.setState({showDeleteModal: false});
  }

  onClickDecrement() {
    this.props.dispatch(LivesDuck.Ax.decrement());
  }

  onClickDeviceLogin() {
    this.props.deviceLogin();
  }

  onClickWhoami() {
    this.props.whoami();
  }

  onClickLogin() {
    this.setState({showLoginModal: true});
  }
  onCloseLogin() {
    this.setState({showLoginModal: false});
  }

  onClickLogout() {
    this.setState({showLogoutModal: true});
  }
  onCloseLogout() {
    this.setState({showLogoutModal: false});
  }

  onClickExtend() {
    this.props.extendSession();
  }

  onClickDisplayName() {
    this.setState({showNameModal: true});
  }
  onCloseDisplayName() {
    this.setState({showNameModal: false});
  }

  onClickAvatar() {
    this.setState({showAvatarModal: true});
  }
  onCloseAvatar() {
    this.setState({showAvatarModal: false});
  }

  onClickCity() {
    this.setState({showCityModal: true});
  }
  onCloseCity() {
    this.setState({showCityModal: false});
  }

  renderSectionProfile() {
    const {currentUser, city} = this.props;
    const displayName = currentUser?.displayName || currentUser?.defaultDisplayName || null;
    if (!currentUser) return null;

    return (
      <div className="snk-settings-section">
        <h2 className="snk-settings-section-title">Profile</h2>
        <div className="snk-settings-section-btn-group">
          <button className="snk-settings-section-btn" onClick={this.onClickDisplayName}>
            <div className="snk-settings-section-btn-label">Display Name</div>
            <div className="snk-settings-section-btn-val">{displayName}</div>
            <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
          </button>
          <button className="snk-settings-section-btn" onClick={this.onClickAvatar}>
            <div className="snk-settings-section-btn-label">Smiley</div>
            <div className="snk-settings-section-btn-val">
              <Avatar size={30} iconName={_.get(currentUser, 'prefs.snkAvatarIcon')} color={_.get(currentUser, 'prefs.snkAvatarColor')} />
            </div>
            <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
          </button>
          <button className="snk-settings-section-btn" onClick={this.onClickCity}>
            <div className="snk-settings-section-btn-label">City</div>
            <div className="snk-settings-section-btn-val">{cityHelpers.getName(city)}</div>
            <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
          </button>
        </div>
      </div>
    );    
  }

  renderSectionAccount() {
    const {currentUser} = this.props;
    const fullySignedIn = !!currentUser?.emailVerified;
    const partiallySignIn = !!(currentUser && !fullySignedIn);

    return (
      <div className="snk-settings-section">
        <h2 className="snk-settings-section-title">Account</h2>
        <div className="snk-settings-section-btn-group">
          {currentUser && (
            <div className="snk-settings-section-btn">
              <div className="snk-settings-section-btn-label">User</div>
              <div className="snk-settings-section-btn-val">{currentUser.emailVerified ? currentUser.email : 'unverified'}</div>
            </div>
          )}
          {fullySignedIn ? (<>
            <button className="snk-settings-section-btn" onClick={this.onClickLogout}>
              <div className="snk-settings-section-btn-label">Sign Out</div>
              <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
            </button>
            <button className="snk-settings-section-btn" onClick={this.onClickDelete}>
              <div className="snk-settings-section-btn-label">Delete Account</div>
              <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
            </button>
          </>) : (
            <button className="snk-settings-section-btn" onClick={this.onClickLogin}>
              <div className="snk-settings-section-btn-label">Sign In</div>
              <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
            </button>
          )}
        </div>
        {partiallySignIn && (
          <p className="snk-settings-section-p">We've created a default, anonymous account for you so your scores can be saved. If you want to ensure you can access your scores on new and other devices, verify your account by signing in with an email address.</p>
        )}
        {!currentUser && (
          <p className="snk-settings-section-p">You are currently not signed in and your scores can not be saved.</p>
        )}
      </div>
    );
  }

  renderSectionDebug() {
    const {pr, currentUser, city} = this.props;
    return (
      <div className="snk-settings-section">
        <h2 className="snk-settings-section-title">Debug</h2>
        <div className="snk-settings-section-btn-group">
          <button className="snk-settings-section-btn" onClick={this.onClickDeviceLogin}>
            <div className="snk-settings-section-btn-label">Device Login</div>
            <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
          </button>
          <button className="snk-settings-section-btn" onClick={this.onClickWhoami}>
            <div className="snk-settings-section-btn-label">Who Am I?</div>
            <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
          </button>
          <button className="snk-settings-section-btn" onClick={this.onClickLogin}>
            <div className="snk-settings-section-btn-label">Email Login</div>
            <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
          </button>
          <button className="snk-settings-section-btn" onClick={this.onClickExtend}>
            <div className="snk-settings-section-btn-label">Extend</div>
            <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
          </button>
          <button className="snk-settings-section-btn" onClick={this.onClickLogout}>
            <div className="snk-settings-section-btn-label">Logout</div>
            <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
          </button>
        </div>
        <div className="snk-settings-section-btn-group">
          <pre>{JSON.stringify({pr, currentUser, city}, null, 4)}</pre>
        </div>
      </div>
    );
  }

  renderSectionLives() {
    const {livesCount, hasInfiniteLives, purchasePending} = this.props;
    const {ilDisplayPrice} = this.state;
    return (
      <div className="snk-settings-section">
        <h2 className="snk-settings-section-title">Lives</h2>
        <div className="snk-settings-section-btn-group">
          <div className="snk-settings-section-btn">
            <div className="snk-settings-section-btn-label">Lives Left</div>
            <div className="snk-settings-section-btn-val">{hasInfiniteLives ? 'Infinite' : livesCount}</div>
          </div>
          {!hasInfiniteLives && (<>
            <button className="snk-settings-section-btn" onClick={this.onClickPurchaseInfiniteLives} disabled={purchasePending}>
              <div className="snk-settings-section-btn-label">{purchasePending ? 'Purchasing...' : 'Purchase Infinite Lives'}</div>
              <div className="snk-settings-section-btn-val">{ilDisplayPrice || ''}</div>
              <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
            </button>
          </>)}
          <button className="snk-settings-section-btn" onClick={this.onClickRestore}>
            <div className="snk-settings-section-btn-label">Restore Purchases</div>
            <Icon.Caret direction="right" className="snk-settings-section-btn-caret" />
          </button>
          {/* <button className="snk-settings-section-btn" onClick={this.onClickDecrement}> */}
          {/*   <div className="snk-settings-section-btn-label">Decrement</div> */}
          {/*   <Icon.Caret direction="right" className="snk-settings-section-btn-caret" /> */}
          {/* </button> */}
        </div>
      </div>
    );
  }

  render() {
    const {showLoginModal, showNameModal, showAvatarModal, showCityModal, showLogoutModal, showRestoreModal, showDeleteModal} = this.state;

    const head = (
      <div className="snk-settings-head">
        <div className="snk-scrolly-page-head-actions">
          <Link className="snk-scrolly-page-head-back" href={paths.snkHome()}><Icon.Caret direction="left" />Back</Link>
        </div>
        <h1 className="snk-scrolly-page-head-h1">Settings</h1>
      </div>
    );

    return (
      <ScrollyPage className="snk-settings" head={head}>
        <div className="snk-settings-body">
          {this.renderSectionProfile()}
          {this.renderSectionLives()}
          {this.renderSectionAccount()}
          {/* {this.renderSectionDebug()} */}

          {/* <div className="snk-settings-btns"> */}
          {/*   <button className="snk-btn yellow">Yellow</button> */}
          {/*   <button className="snk-btn green">Green</button> */}
          {/*   <button className="snk-btn blue">Blue</button> */}
          {/*   <button className="snk-btn purple">Purple</button> */}
          {/* </div> */}

          {showLoginModal && (
            <ModalLogin onClose={this.onCloseLogin} />
          )}
          {showLogoutModal && (
            <ModalLogout onClose={this.onCloseLogout} />
          )}
          {showNameModal && (
            <ModalName onClose={this.onCloseDisplayName} />
          )}
          {showAvatarModal && (
            <ModalAvatar onClose={this.onCloseAvatar} />
          )}
          {showCityModal && (
            <ModalCity onClose={this.onCloseCity} />
          )}
          {showRestoreModal && (
            <ModalRestore onClose={this.onCloseRestore} />
          )}
          {showDeleteModal && (
            <ModalDelete onClose={this.onCloseDelete} />
          )}
        </div>
      </ScrollyPage>
    );
  }

}

const stateToProps = (state) => ({
  // allAuth: AuthDuck.Slx.all(state),
  currentUser: AuthDuck.Slx.currentUser(state),
  city: CitiesDuck.Slx.snkCity(state),
  pr: ScoresDuck.Slx.pr(state),

  livesCount: LivesDuck.Slx.count(state),
  hasInfiniteLives: LivesDuck.Slx.hasInfinite(state),
  purchasePending: LivesDuck.Slx.purchasePending(state),
});

const dispatchToProps = (dispatch) => ({
  deviceLogin: () => dispatch(AuthDuck.Ax.deviceLogin()),
  whoami: () => dispatch(AuthDuck.Ax.whoami()),
  // logout: () => dispatch(AuthDuck.Ax.logout()),
  extendSession: () => dispatch(AuthDuck.Ax.extendSession()),
  dispatch,
});

export default connect(stateToProps, dispatchToProps)(PageSettings);
