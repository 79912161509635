import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Link          from 'app/components/common/link';
import Duck          from 'app/dotsnake/ducks/game';
import ScoresDuck    from 'app/dotsnake/ducks/scores';
import SnakeManager  from 'app/dotsnake/game/manager';
import paths         from 'app/paths';

const {Ax, Slx} = Duck;

class Status extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      pr: null,
    };

    // this.onClickPause = this.onClickPause.bind(this);
  }

  // onClickPause() {
  //   SnakeManager.pause();
  // }

  get pr() {
    if (_.isFinite(this.state.pr)) return this.state.pr;
    return this.props.pr;
  }

  get isNewPr() {
    const {score} = this.props;
    return !!(this.pr && score > this.pr);
  }

  componentDidUpdate(prevProps) {
    const didEnd = prevProps.status === 'active' && this.props.status === 'over';
    if (didEnd) {
      this.setState({pr: prevProps.pr});
      return;
    }
    if (this.props.status !== 'over' && _.isFinite(this.state.pr)) {
      this.setState({pr: null});
    }
  }

  renderPr() {
    const {score} = this.props;
    if (!this.pr) return null;
    if (this.isNewPr) return null;
    const percentage = Math.floor(Math.min((score || 0) / this.pr, 1) * 100);

    return (<>
      <div className="snk-game-status-item bar">
        <div className="snk-game-status-item-val">
          <div className="snk-game-status-pr-bar">
            <div className="snk-game-status-pr-bar-progress" data-percentage={`${percentage}%`} />
          </div>
        </div>
        <div className="snk-game-status-item-label">&nbsp;</div>
      </div>
      <div className="snk-game-status-item">
        <div className="snk-game-status-item-val">{this.pr}</div>
        <div className="snk-game-status-item-label">Best</div>
      </div>
    </>);
  }

  renderCountdown() {
    const {countdown} = this.props;
    if (!countdown) return null;

    return (
      <div className="snk-game-status-item">
        <div className="snk-game-status-item-val">{countdown}</div>
        <div className="snk-game-status-item-label">Countdown</div>
      </div>
    );
  }

  renderScore() {
    const {score} = this.props;
    const label = this.isNewPr ? 'New Best' : 'Score';
    const newPrClass = this.isNewPr ? 'strong' : '';

    return (
      <div className="snk-game-status-item">
        <div className="snk-game-status-item-val">{score || 0}</div>
        <div className={`snk-game-status-item-label ${newPrClass}`}>{label}</div>
      </div>
    );
  }

  render() {
    const {score, countdown} = this.props;
    return (
      <div className="snk-game-status">
        {this.renderCountdown()}
        {this.renderScore()}
        {this.renderPr()}
        {/* <button className="snk-btn" onClick={this.onClickPause}>Pause</button> */}
      </div>
    );
  }
}

const stateToProps = (state, ownProps) => ({
  score: Slx.score(state),
  status: Slx.status(state),
  countdown: Slx.countdown(state),

  pr: ScoresDuck.Slx.pr(state),
});

export default connect(stateToProps)(Status);
