import { Capacitor, registerPlugin } from '@capacitor/core';

import Duck      from 'app/dotsnake/ducks/game';
import LivesDuck from 'app/dotsnake/ducks/lives';

const {Ax, Slx} = Duck;
const isIos = Capacitor.getPlatform() === 'ios';

const obj = (() => {
  if (!isIos) return null;

  const Plugin = registerPlugin('Main');
  let store = null;

  Plugin.removeAllListeners().then(() => {
    Plugin.addListener('setCountdown', ({countdown}) => {
      store.dispatch(Ax.setCountdown(countdown));
    });
    Plugin.addListener('setScore', ({score}) => {
      store.dispatch(Ax.setScore(score));
    });
    Plugin.addListener('setColor', ({color}) => {
      store.dispatch(Ax.setColor(color));
    });
    Plugin.addListener('setStatus', ({status}) => {
      if (status === 'over') {
        store.dispatch(Ax.getResult(getResult));
        store.dispatch(LivesDuck.Ax.decrement());
      }
      store.dispatch(Ax.setStatus(status));
    });
    Plugin.addListener('setSpecialOn', ({specialOn}) => {
      store.dispatch(Ax.setSpecialOn(specialOn));
    });
    Plugin.addListener('setSpecialIsEnding', ({specialIsEnding}) => {
      store.dispatch(Ax.setSpecialIsEnding(specialIsEnding));
    });
    Plugin.addListener('setSize', ({rowCount, colCount}) => {
      store.dispatch(Ax.setSize({rowCount, colCount}));
    });
    Plugin.addListener('setCell', ({cell, obj}) => {
      store.dispatch(Ax.setCell(cell.row, cell.col, obj));
    });
    Plugin.addListener('reset', () => {
      store.dispatch(Ax.reset());
    });
    Plugin.addListener('setHasInfiniteLives', ({hasInfiniteLives}) => {
      store.dispatch(LivesDuck.Ax.setHasInfinite(hasInfiniteLives));
    });
    Plugin.addListener('setPurchasePending', ({purchasePending}) => {
      store.dispatch(LivesDuck.Ax.setPurchasePending(purchasePending));
    });
  });

  const newGame = ({useSaved=false} = {}) => {
    Plugin.newGame({useSaved});
  };
  const play = () => Plugin.play();
  const pause = () => Plugin.pause();
  const addDirection = (direction) => Plugin.addDirection({direction});

  const setStore = (_store) => {
    store = _store;
  };

  const getDeviceId = async () => {
    const {deviceId} = await Plugin.getDeviceId();
    return deviceId;
  };

  const getResult = async (userId) => {
    const result = await Plugin.getResult({userId});
    return result;
  };

  const getHasInfiniteLives = async () => {
    const {hasInfiniteLives} = await Plugin.getHasInfiniteLives();
    return hasInfiniteLives;
  };

  const getInfiniteLivesPrice = async () => {
    const {displayPrice} = await Plugin.getInfiniteLivesPrice();
    return displayPrice;
  };

  const purchaseInfiniteLives = async () => {
    const result = await Plugin.purchaseInfiniteLives();
    return result;
  };

  const restorePurchases = async () => {
    const result = await Plugin.restorePurchases();
    return result;
  };

  const keychainSet = async (key, val) => {
    await Plugin.keychainSet({key, val});
  };

  const keychainGet = async (key) => {
    const {val} = await Plugin.keychainGet({key});
    return val;
  };

  return {
    newGame,
    play,
    pause,
    addDirection,
    setStore,
    getDeviceId,
    getResult,

    getHasInfiniteLives,
    getInfiniteLivesPrice,
    purchaseInfiniteLives,
    restorePurchases,

    keychainSet,
    keychainGet,
  };

})();

export default obj;
