import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';

import Modal        from 'app/components/common/modal';
import {
  DeviceTypes,
}                   from 'app/constants';
import Duck         from 'app/dotsnake/ducks/game';
import SnakeManager from 'app/dotsnake/game/manager';
import AuthDuck     from 'app/ducks/auth';
import history      from 'app/history';
import paths        from 'app/paths';

const {Ax, Slx} = Duck;

const schemeApple = 'message://';
const schemeGoogle = 'googlegmail://';
const schemeMicrosoft = 'ms-outlook://';

class ModalLogin extends React.PureComponent {

  constructor(props) {
    super(props);

    this.refInput = React.createRef();

    this.state = {
      email: null,
    };

    this.onClickLogin = this.onClickLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.refInput.current && this.refInput.current.focus();
    }, 50);
  }

  componentWillUnmount() {
    this.props.clear();
  }

  onClickLogin(event) {
    event?.preventDefault && event.preventDefault();
    this.refInput.current.blur();
    const {email} = this.state;
    console.log(email);
    this.props.login(email);
  }

  onChangeEmail(event) {
    const email = event.target.value;
    this.setState({email});
  }

  onClose() {
    this.props.onClose();
  }

  renderSuccess() {
    const {email} = this.state;

    return (<>
      <h1 className="snk-modal-title">Link Sent</h1>
      <p className="snk-modal-p">Click the link we just sent you at {email} and you’ll be signed in instantly.</p>
      <div className="snk-modal-actions">
        <a className="snk-btn mint" href={schemeMicrosoft}>Open Outlook</a>
        <a className="snk-btn mint" href={schemeGoogle}>Open Gmail</a>
        <a className="snk-btn mint" href={schemeApple}>Open Apple Mail</a>
      </div>
    </>);
  }

  renderMain() {
    const {pending, failed} = this.props;
    const {email} = this.state;
    return (<>
      <h1 className="snk-modal-title">Sign In</h1>
      <p className="snk-modal-p">Enter your email and we’ll send you a one-time link that will sign you in instantly.</p>
      <p className="snk-modal-p">We promise not to spam.</p>
      <form onSubmit={this.onClickLogin} action="/">
        <input className="snk-input" type="email" value={email || ''} onChange={this.onChangeEmail} enterKeyHint="send" ref={this.refInput} placeholder="happysnake@example.com" />
        {failed && (
          <p className="snk-modal-p error">Oops! Something went wrong. Please double check your email address and try again.</p>
        )}
        <div className="snk-modal-actions">
          <button className="snk-btn mint" onClick={this.onClickLogin} disabled={pending}>{pending ? 'Signing In...' : 'Sign In'}</button>
        </div>
      </form>
      <p className="snk-modal-p snk-modal-login-pp">By signing in you are consenting to our <a href="https://tybroapps.com/privacy-policy.html">Privacy Policy</a>.</p>
    </>);
  }

  render() {
    const {pending, failed, success} = this.props;
    const content = success ? this.renderSuccess() : this.renderMain();

    return (
      <Modal bgClose={false} className="snk-modal-login snk-modal" onClose={this.onClose}>
        {content}
      </Modal>
    );
  }

}

ModalLogin.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  pending: AuthDuck.Slx.loginPending(state),
  failed: AuthDuck.Slx.loginFailed(state),
  success: AuthDuck.Slx.loginSuccess(state),
  validations: AuthDuck.Slx.loginValidations(state),
});

const dispatchToProps = (dispatch) => ({
  login: (email) => dispatch(AuthDuck.Ax.login({email, deviceType: DeviceTypes.IOS})),
  clear: () => dispatch(AuthDuck.Ax.clearLogin()),
});

export default connect(stateToProps, dispatchToProps)(ModalLogin);
