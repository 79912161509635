import React from 'react';

class SizeMonitor extends React.PureComponent {

  constructor(props) {
    super(props);
    this.onChangeMql = this.onChangeMql.bind(this);
  }

  get mqls() {
    return [this.mqlLarge, this.mqlMedium, this.mqlSmall];
  }

  get size() {
    if (this.mqlSmall.matches) return 's';
    if (this.mqlMedium.matches) return 'm';
    if (this.mqlLarge.matches) return 'l';
    return 'xl';
  }

  onChangeMql(event) {
    const newClass = `size-${this.size}`;
    if (this.currentClass) {
      this.elBody.classList.replace(this.currentClass, newClass);
    } else {
      this.elBody.classList.add(newClass);
    }
    this.currentClass = newClass;
  }

  componentDidMount() {
    this.elBody = document.querySelector('body');
    this.mqlLarge  = window.matchMedia('(max-width: 1199px)');
    this.mqlMedium = window.matchMedia('(max-width: 959px)');
    this.mqlSmall  = window.matchMedia('(max-width: 749px)');
    this.mqls.forEach((mql) => {
      mql.addEventListener('change', this.onChangeMql);
    });
    this.onChangeMql();
  }

  componentWillUnmount() {
    this.mqls.forEach((mql) => {
      mql.removeEventListener('change', this.onChangeMql);
    });
  }

  render() {
    return null;
  }

}

export default SizeMonitor;
