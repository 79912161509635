import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Board        from 'app/dotsnake/components/play/board';
import ModalOver    from 'app/dotsnake/components/play/modal-over';
import ModalPaused  from 'app/dotsnake/components/play/modal-paused';
import Status       from 'app/dotsnake/components/play/status';
import Duck         from 'app/dotsnake/ducks/game';
import SnakeManager from 'app/dotsnake/game/manager';
import history      from 'app/history';
import paths        from 'app/paths';

const {Ax, Slx} = Duck;

class Game extends React.PureComponent {

  constructor(props) {
    super(props);

    this.mqlOrientation = null;

    this.onWindowClick          = this.onWindowClick.bind(this);
    this.onDoubleTap            = this.onDoubleTap.bind(this);
    this.onKeyDown              = this.onKeyDown.bind(this);
    this.onSwipe                = this.onSwipe.bind(this);
    this.onChangeOrientationMql = this.onChangeOrientationMql.bind(this);
  }

  componentDidMount() {
    SnakeManager.newGame({useSaved: true});
    document.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('tybroSwipe', this.onSwipe);
    window.addEventListener('tybroDoubleTap', this.onDoubleTap);
    window.addEventListener('click', this.onWindowClick);
    this.mqlOrientation = window.matchMedia('(orientation: portrait)');
    this.mqlOrientation.addEventListener('change', this.onChangeOrientationMql);
    SnakeManager.play();
  }

  componentWillUnmount() {
    window.removeEventListener('tybroSwipe', this.onSwipe);
    document.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('click', this.onWindowClick);
    window.removeEventListener('tybroDoubleTap', this.onDoubleTap);
    this.mqlOrientation.removeEventListener('change', this.onChangeOrientationMql);
    SnakeManager.pause();
  }

  onWindowClick(event) {
    if (event.detail === 2) this.onDoubleTap();
  }

  onDoubleTap() {
    SnakeManager.pause();
  }

  onChangeOrientationMql() {
    SnakeManager.pause();
  }

  onKeyDown(event) {
    const isLandscape = !!window.matchMedia('(orientation: landscape)').matches;
    const direction = ({
      'false-37': 'left',
      'false-38': 'up',
      'false-39': 'right',
      'false-40': 'down',
      'true-37': 'down',
      'true-38': 'left',
      'true-39': 'up',
      'true-40': 'right',
    })[`${isLandscape}-${event.keyCode}`];
    if (!direction) return;
    event.preventDefault();
    SnakeManager.addDirection(direction);
  }

  onSwipe({direction: swipeDir}) {
    const isLandscape = !!window.matchMedia('(orientation: landscape)').matches;
    const direction = ({
      'false-left':  'left',
      'false-up':    'up',
      'false-right': 'right',
      'false-down':  'down',
      'true-left':   'down',
      'true-up':     'left',
      'true-right':  'up',
      'true-down':   'right',
    })[`${isLandscape}-${swipeDir}`];
    SnakeManager.addDirection(direction);
  }

  render() {
    return (
      <div className={`snk-game`}>
        <div className="snk-game-head">
          <Status />
        </div>
        <div className="snk-game-body">
          <div className="snk-game-board-con3">
            <div className="snk-game-board-con2">
              <div className="snk-game-board-con">
                <Board />
              </div>
            </div>
          </div>
        </div>
        <ModalPaused />
        <ModalOver />
      </div>
    );
  }

}

const stateToProps = (state, ownProps) => ({
});

export default connect(stateToProps)(Game);
