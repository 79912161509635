import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import moment from 'moment';

import Modal        from 'app/components/common/modal';
import Score        from 'app/dotsnake/components/leaderboard/score';
import Duck         from 'app/dotsnake/ducks/game';
import LivesDuck    from 'app/dotsnake/ducks/lives';
import SnakeManager from 'app/dotsnake/game/manager';
import AuthDuck     from 'app/ducks/auth';
import CitiesDuck   from 'app/ducks/cities';
import history      from 'app/history';
import paths        from 'app/paths';
const {Ax, Slx} = Duck;

class ModalOver extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickNewGame = this.onClickNewGame.bind(this);
    this.onClickMainMenu = this.onClickMainMenu.bind(this);
  }

  onClickNewGame() {
    SnakeManager.newGame();
    SnakeManager.play();
  }

  onClickMainMenu() {
    history.push(paths.snkHome());
  }

  renderScore() {
    const {currentUser, city, score} = this.props;
    if (!currentUser) return null;
    const obj = {score, city, scoredAt: moment().format()};
    if (currentUser) {
      obj.user = {
        displayName: currentUser.displayName || currentUser.defaultDisplayName,
        avatarColor: currentUser.prefs?.snkAvatarColor,
        avatarIcon: currentUser.prefs?.snkAvatarIcon,
      };
    }
    return (
      <Score score={obj} safeEdges={false} />
    );
  }

  render() {
    const {isOver, score, canPlay, hasInfiniteLives, livesCount} = this.props;
    if (!isOver) return null;

    return (
      <Modal className="snk-over snk-modal">
        {this.renderScore()}
        <h1 className="snk-modal-title">Game Over</h1>
        <p className="snk-modal-p">Final Score: {score}</p>
        <div className="snk-modal-actions">
          <button className="snk-btn mint" onClick={this.onClickMainMenu}>Main Menu</button>
          <button className="snk-btn mint" disabled={!canPlay} onClick={this.onClickNewGame}>New Game</button>
          {!hasInfiniteLives && (
            <div className={`snk-modal-actions-subtext ${canPlay ? '' : 'red'}`}>Lives Left: {livesCount}</div>
          )}
        </div>
      </Modal>
    );
  }

}

const stateToProps = (state) => ({
  isOver: Slx.status(state) === 'over',
  score: Slx.score(state),
  currentUser: AuthDuck.Slx.currentUser(state),
  city: CitiesDuck.Slx.snkCity(state),
  canPlay: LivesDuck.Slx.canPlay(state),
  hasInfiniteLives: LivesDuck.Slx.hasInfinite(state),
  livesCount: LivesDuck.Slx.count(state),
});

export default connect(stateToProps)(ModalOver);
