import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Icon from 'app/components/common/icon';

const hues = _.times(24).map(i => ((i * 15)));
// const colors = hues.map(hue => `hsl(${hue}, 38%, 61%)`);
// colors.push('#575757');
const colors = [
  // '#CC666E',
  // '#CE826B',
  // '#E5C07B',
  // '#98C379',
  // '#76C197',
  // '#6B9BCE',
  // '#5F63B9',
  // '#A17DBB',
  // '#C275A9',
  

  '#CC666E',
  '#CE826B',
  '#CC9466',
  '#E5C07B',
  '#CFB76B',
  '#BCC247',
  '#98C379',
  '#78C281',
  '#77C298',
  '#76C2BF',
  '#6B9BCE',
  '#5F63B9',
  '#706BCF',
  '#8B5FBA',
  '#A17DBB',
  '#BA7DB3',
  '#C275A9',
  '#C27480',

  // '#B83D47',
  // '#BB5B3E',
  // '#D9A440',
  // '#76AF4E',
  // '#4DAC76',
  // '#3E7ABB',
  // '#44499C',
  // '#8356A3',
  // '#AD4C8E',

  // '#CC7266',
  // '#CF966B',
  // '#E6D67C',
  // '#88C278',
  // '#76C2A6',
  // '#6B87CF',
  // '#6D5FBA',
  // '#AD7DBA',
  // '#C2749A',

  // '#CC9466',
  // '#CFB76B',
  // '#D2E67C',
  // '#78C281',
  // '#76C2BF',
  // '#706BCF',
  // '#8B5FBA',
  // '#BA7DB3',
  // '#C27480',

  '#575757',
];
const iconNames = Object.keys(Icon).filter(iconName => iconName.startsWith('Smiley'));

class Avatar extends React.PureComponent {

  render() {
    const {className, iconName, color, size} = this.props;
    const IconComp = Icon[iconName] || Icon.SmileyHappy;
    const style = {backgroundColor: color};
    if (size) {
      style.width = size;
      style.height = size;
    }

    return (
      <div className={`snk-avatar ${className}`} style={style}>
        <IconComp className="snk-avatar-icon" />
      </div>
    );
  }

}

Avatar.propTypes = {
  iconName: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
};

Avatar.defaultProps = {
  iconName: 'SmileySmile2',
  color: '#575757',
  className: '',
  size: 24,
};

Avatar.colors = colors;
Avatar.iconNames = iconNames;

export default Avatar;
