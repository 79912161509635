import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import Apps        from 'app/apps';
import SafeArea    from 'app/components/common/safe-area';
import MainLayout  from 'app/components/layout/main-layout';
import SnkLayout   from 'app/dotsnake/components/layout-main';
import RoutingDuck from 'app/ducks/routing';
import paths       from 'app/paths';
import history     from 'app/history';

const statusTitleMap = {
  400: 'Bad Request',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Internal Server Error',
};

const titleForStatus = (status) => {
  return statusTitleMap[status] || 'Something went wrong.';
};

class PageError extends React.PureComponent {

  constructor(props) {
    super(props);

    this.onClickHomeDotsnake = this.onClickHomeDotsnake.bind(this);
  }

  onClickHomeDotsnake() {
    history.push(paths.snkHome());
  }

  renderDotsnake() {
    return (
      <SnkLayout>
        <div className="snk-error-page">
          <div className="snk-error-page-con">
            <h1 className="snk-error-page-h1">Oops</h1>
            <p className="snk-error-page-p">It looks like you may have opened a broken link.</p>
            <button className="snk-btn mint" onClick={this.onClickHomeDotsnake}>Main Menu</button>
          </div>
        </div>
      </SnkLayout>
    );
  }

  render() {
    const title = this.props.title || titleForStatus(this.props.statusCode);
    const {message, app} = this.props;
    if (app === Apps.dotsnake) return this.renderDotsnake();
    return (
      <div className="widther">
        <h1>{title}</h1>
        {message && <p>{message}</p>}
      </div>
    );
  }

}

PageError.propTypes = {
  statusCode: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.string,
};

PageError.defaultProps = {
  statusCode: 404,
};

const stateToProps = (state) => ({
  app: Apps[RoutingDuck.Slx.app(state)],
});

const ConnectedPageError = connect(stateToProps)(PageError);

[400, 403, 404, 500].forEach((statusCode) => {
  ConnectedPageError[statusCode] = () => <ConnectedPageError statusCode={statusCode} />;
});

export default ConnectedPageError;
