import React from 'react';
import _ from 'lodash';

import config from 'app/config';

const isUrl = (str) => {
  if (!str) return false;
  const lowerVal = (str || '').toLowerCase();
  const startsWith = (lowerVal.startsWith('http://') || lowerVal.startsWith('https://'));
  if (!startsWith) return false;
  return lowerVal.replace('http://', '').replace('https://', '').length > 0;
};

const isEmail = (str) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(str);
};

/** Parses an e-mail address string and returns an object that gives the `user`
 *  (also known as the 'local part') and the `domain` found within the address.
 *  All parts are lower-cased. Returns 'null' if the string is not a valid
 *  address. */
const parseEmail = (str) => {
  str = str.trim().toLowerCase();
  if (!isEmail(str)) return null;

  const parts = str.split('@');
  return {
    user: parts[0],
    domain: parts[1],
  };
}

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/;
const isUuid = (something) => {
  if (typeof something !== 'string') return false;
  return !!UUID_REGEX.test(something);
};

const getBrowser = () => {
  const { userAgent = '' } = window.navigator || {};
  if (userAgent.includes('Firefox')) return 'Firefox';
  if (userAgent.includes('SamsungBrowser')) return 'Samsung';
  if (userAgent.includes('Opera') || userAgent.includes('OPR')) return 'Opera';
  if (userAgent.includes('Trident')) return 'MSIE';
  if (userAgent.includes('Edge')) return 'Edge';
  if (userAgent.includes('Chrome')) return 'Chrome';
  if (userAgent.includes('Safari')) return 'Safari';
  return 'unknown';
};

const classNames = (obj) => {
  return Object.entries(obj)
    .filter(([key, val]) => !!val)
    .map(([key, val]) => key)
    .join(' ');
};

const buildQueryString = (queryObject) => {
  const qs = _.map(queryObject, (val, key) => {
    if (val === '' || val == null) return null;
    return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
  }).filter(p => p).join('&');
  return qs ? `?${qs}` : '';
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals#tagged_templates
const makeTemplate = (strings, ...keys) => {
  return (...values) => {
    let dict = values[values.length - 1] || {};
    let result = [strings[0]];
    keys.forEach((key, i) => {
      let value = Number.isInteger(key) ? values[key] : dict[key];
      if (value == null || typeof value === 'object') {
        value = key;
      }
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };
};

const renderTextBlob = (text, className='') => {
  return (text || '').split('\n').map((line, i) => {
    return <p key={i} className={className}>{line}</p>;
  });
};

const nameFromEmail = (email) => {
  return _.capitalize(email.split('@')[0]);
};

const series = async (items, fn) => {
  return items.reduce((promise, item, i) => {
    return promise.then(() => {
      return fn(item, i);
    });
  }, Promise.resolve());
};

export default {
  isUrl,
  isEmail,
  parseEmail,
  isUuid,
  getBrowser,
  classNames,
  buildQueryString,
  makeTemplate,
  renderTextBlob,
  nameFromEmail,
  series,
};
