import _ from 'lodash';
import { Router } from 'pouter';

import {
  Pages,
} from 'app/constants';

const createRouter = (store) => {
  const router = new Router();
  router.setContext({store});

  router.use('/', (done, location, _context, preRendered) => {
    return done({page: Pages.SNK_LANDING});
  });

  router.use('/home', (done, location, _context, preRendered) => {
    return done({page: Pages.SNK_HOME});
  });

  router.use('/play', (done, location, context, preRendered) => {
    return done({page: Pages.SNK_PLAY});
  });

  router.use('/scores', (done, location, _context, preRendered) => {
    return done({page: Pages.SNK_SCORES});
  });

  router.use('/settings', (done, location, _context, preRendered) => {
    return done({page: Pages.SNK_SETTINGS});
  });

  router.use('/instructions', (done, location, _context, preRendered) => {
    return done({page: Pages.SNK_INSTRUCTIONS});
  });

  router.use('/browser-not-supported', (done, _location, _context, preRendered) => {
    done({page: Pages.BROWSER_NOT_SUPPORTED});
  });

  router.use('/email-verifications/:id', (done, location, context, preRendered) => {
    done({page: Pages.SNK_EMAIL_VERIFICATION});
  });

  return router;
};

export default createRouter;
