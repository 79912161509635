import EventEmitter from 'events';

const emitter = new EventEmitter();

const eventNames = [
  'currentUserChange',
  'currentUserUpdate',
];

eventNames.forEach((eventName) => {
  emitter[eventName] = {
    emit: (...args) => {
      emitter.emit(eventName, ...args);
    },
    on: (cb) => {
      emitter.on(eventName, cb);
    },
  };
});

/*
 *  USAGE:
 *    emitter.EVENT_NAME.on((args) => {});
 *    emitter.EVENT_NAME.emit(args);
 */

export default emitter;
